import { take, put, all, select } from 'redux-saga/effects';

import {
    JOIN_MEETING,
    JOIN_MEETING_STANDALONE,
    LEAVE_MEETING,
    ALC_JOIN_MEETING_SUCCESS,
    ALC_ERROR,
    WEBRTC_JOIN_SUCCESS,
    ALC_CLIENT_INFO_UPDATE,
    WEBRTC_START_LOCAL_MEDIA_SUCCESS,
    WEBRTC_START_LOCAL_MEDIA_FAILURE,
    WEBRTC_JOIN_FAILURE,
    WEBRTC_INIT_SUCCESS,
    ALC_LEAVE_MEETING_SUCCESS,
    ALC_KICKED,
    WEBRTC_RECONNECTING_FAILURE,
    ALC_MEETING_COMPLETED,
    START_RECORDING,
    WEBRTC_START_RECORDING_SUCCESS,
    WEBRTC_START_RECORDING_FAILURE,
    STOP_RECORDING,
    ALC_START_RECORDING_SUCCESS,
    START_RECORDING_SUCCESS,
    START_RECORDING_FAILURE,
} from './actionTypes';
import {
    alcClose,
    alcJoinMeeting,
    alcJoinMeetingStandalone,
    alcLeaveMeeting,
    webRtcClose,
    webRtcJoin,
    webRtcLeave,
    joinMeetingSuccess,
    joinMeetingFailure,
    webRtcStartLocalMedia,
    showSharedApplications,
    webRtcStopLocalMedia,
    startRecording as startRecordingActionCreator,
    leaveMeeting as leaveMeetingActionCreator,
    alcStartRecording,
    webRtcStartRecording,
    alcStopRecording,
    webRtcStopRecording,
    showRecordingConfirmationDialog,
    startRecordingSuccess,
    startRecordingFailure,
} from './actions';
import { logout } from '../auth/actions';
import { FORCE_LOGOUT, LOGOUT } from '../auth/actionTypes';
import { MANAGER_IFRAME_READY } from '../meetingsManager/actionTypes';
import {
    showSettings,
    webRtcChangeCommunicationMode,
} from '../deviceSettings/actions';
import { showMessage } from '../base/messages/actions';
import {
    managerIFrameSendCheckCompleted,
    openEditMeeting,
} from '../meetingsManager/actions';
import { push } from 'connected-react-router';
import {
    log,
    routeWithServiceId,
    webRtcErrorCodeToErrorId,
} from '../base/util/helpers';
import Routes from '../../constants/routes';
import { alcErrorCodeToErrorId } from '../base/util/helpers';
import {
    COMMUNICATION_MODES,
    MEETING_TYPES,
    WEBRTC_MODES,
} from '../../constants/constants';
import { WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS } from '../deviceSettings/actionTypes';

const getIsAdviser = (state) => {
    if (state.meetings.clientInfo) {
        return state.meetings.clientInfo.isAdviser;
    } else return false;
};
const getNeedsAutoRecord = (state) => {
    const autoRecordingEnabled =
        state.auth.publicServiceInfo.meetingsSettings &&
        state.auth.publicServiceInfo.meetingsSettings.enableAutoRecording;
    return (
        autoRecordingEnabled &&
        state.auth.user &&
        state.auth.user.roles.includes('autorecord')
    );
};
const getServiceId = (state) => state.auth.service;
const getAlcMeetingJoined = (state) => state.meetings.alcMeetingJoined;
const getMeetingInfo = (state) => state.meetings.meetingInfo;
// TODO implement selector?
const getMeetingsManagerUrl = (state) => {
    let meetingsManagerUrl = null;
    if (
        state.auth.publicServiceInfo &&
        state.auth.publicServiceInfo.meetingsSettings
    ) {
        meetingsManagerUrl =
            state.auth.publicServiceInfo.meetingsSettings.meetingsManagerUrl;
    }
    return meetingsManagerUrl;
};
const getWebRtcMeetingJoined = (state) => state.meetings.webRtcMeetingJoined;
const getAlcInitialized = (state) => state.meetings.alcInitialized;
const getWebRtcInitialized = (state) => state.meetings.webRtcInitialized;
const getCommunicationMode = (state) => state.deviceSettings.communicationMode;
const getClientState = (state) => state.meetings.clientInfo.state.name;
const getLastMeetingId = (state) => state.meetings.clientInfo.state.value;
const getWebRtcMode = (state) => state.auth.publicServiceInfo.webRtcMode;

function* joinMeeting() {
    while (true) {
        const { meetingId } = yield take(JOIN_MEETING);
        log.debug('saga join meeting ' + meetingId);
        const isAdviser = yield select(getIsAdviser);
        // if not adviser when trying to join meeting, wait until server updates isAdviser
        if (!isAdviser) {
            while (true) {
                const { clientInfo } = yield take(ALC_CLIENT_INFO_UPDATE);
                if (clientInfo.isAdviser) {
                    break;
                }
            }
        }
        const webRtcInitialized = yield select(getWebRtcInitialized);
        // wait until webRtc layer is initialized
        if (!webRtcInitialized) {
            yield take(WEBRTC_INIT_SUCCESS);
        }
        // try to start local media first
        yield put(webRtcStartLocalMedia());
        const startLocalMediaAction = yield take([
            WEBRTC_START_LOCAL_MEDIA_SUCCESS,
            WEBRTC_START_LOCAL_MEDIA_FAILURE,
        ]);
        if (startLocalMediaAction.type === WEBRTC_START_LOCAL_MEDIA_SUCCESS) {
            // if local media could be started, join alc meeting
            yield put(
                alcJoinMeeting(
                    meetingId,
                    startLocalMediaAction.communicationMode
                )
            );
            while (true) {
                const alcJoinAction = yield take([
                    ALC_JOIN_MEETING_SUCCESS,
                    ALC_ERROR,
                ]);
                if (alcJoinAction.type === ALC_JOIN_MEETING_SUCCESS) {
                    // set communication mode to none when joining phoneConsulting
                    if (
                        alcJoinAction.meetingInfo.type ===
                        MEETING_TYPES.PHONE_CONSULTING
                    ) {
                        yield put(
                            webRtcChangeCommunicationMode(
                                COMMUNICATION_MODES.NONE
                            )
                        );
                        yield take(WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS);
                    }
                    // if alc meeting is joined, join webRtc meeting
                    yield put(
                        webRtcJoin(
                            alcJoinAction.joinOptions,
                            alcJoinAction.meetingInfo
                        )
                    );
                    const webRtcJoinAction = yield take([
                        WEBRTC_JOIN_SUCCESS,
                        WEBRTC_JOIN_FAILURE,
                    ]);
                    if (webRtcJoinAction.type === WEBRTC_JOIN_SUCCESS) {
                        const needsAutoRecord = yield select(
                            getNeedsAutoRecord
                        );
                        const meetingInfo = yield select(getMeetingInfo);
                        if (
                            needsAutoRecord &&
                            (!meetingInfo.isRecording ||
                                alcJoinAction.joinOptions.mode !==
                                    WEBRTC_MODES.LIVESWITCH_SFU)
                        ) {
                            log.debug(
                                'autorecord role detected and recording is not running yet, starting recording'
                            );
                            yield put(startRecordingActionCreator());
                            const startRecordingAction = yield take([
                                START_RECORDING_SUCCESS,
                                START_RECORDING_FAILURE,
                            ]);
                            if (
                                startRecordingAction.type ===
                                START_RECORDING_FAILURE
                            ) {
                                yield put(joinMeetingFailure());
                                yield put(
                                    leaveMeetingActionCreator(Routes.MEETINGS)
                                );
                                yield put(
                                    showMessage({
                                        contentId: 'autoRecordingError',
                                        type: 'error',
                                    })
                                );
                                break;
                            }
                        }

                        yield put(joinMeetingSuccess());
                    } else {
                        yield put(joinMeetingFailure());
                        yield put(alcLeaveMeeting());
                        let errorCode;
                        if (webRtcJoinAction.error) {
                            errorCode = webRtcJoinAction.error.errorCode;
                        }
                        const serviceId = yield select(getServiceId);
                        yield put(
                            push(routeWithServiceId(Routes.MEETINGS, serviceId))
                        );
                        yield put(
                            showMessage({
                                contentId: webRtcErrorCodeToErrorId(errorCode),
                                type: 'error',
                                errorNo: errorCode,
                            })
                        );
                    }
                    break;
                } else {
                    if (alcJoinAction.context === 'joinMeeting') {
                        yield put(joinMeetingFailure());
                        yield put(webRtcStopLocalMedia());
                        const serviceId = yield select(getServiceId);
                        yield put(
                            push(routeWithServiceId(Routes.MEETINGS, serviceId))
                        );
                        yield put(
                            showMessage({
                                contentId: alcErrorCodeToErrorId(
                                    alcJoinAction.error.errorNo
                                ),
                                type: 'error',
                                errorNo: alcJoinAction.error.errorNo,
                            })
                        );
                        break;
                    }
                }
            }
        } else {
            // if local media couldn't be started
            const serviceId = yield select(getServiceId);
            yield put(push(routeWithServiceId(Routes.MEETINGS, serviceId)));
            const communicationMode = yield select(getCommunicationMode);
            yield put(showSettings(communicationMode));
        }
    }
}

function* joinMeetingStandalone() {
    while (true) {
        const { meetingId } = yield take(JOIN_MEETING_STANDALONE);
        const isAdviser = yield select(getIsAdviser);
        // if not adviser when trying to join meeting, wait until server updates isAdviser
        if (!isAdviser) {
            while (true) {
                const { clientInfo } = yield take(ALC_CLIENT_INFO_UPDATE);
                if (clientInfo.isAdviser) {
                    break;
                }
            }
        }
        yield put(alcJoinMeetingStandalone(meetingId));
        while (true) {
            const action = yield take([ALC_JOIN_MEETING_SUCCESS, ALC_ERROR]);
            if (action.type === ALC_JOIN_MEETING_SUCCESS) {
                yield put(joinMeetingSuccess());
                yield put(showSharedApplications());
                break;
            } else {
                if (action.context === 'joinMeeting') {
                    yield put(joinMeetingFailure());
                    yield put(
                        showMessage({
                            contentId: alcErrorCodeToErrorId(
                                action.error.errorNo
                            ),
                            type: 'error',
                            errorNo: action.error.errorNo,
                        })
                    );
                    // TODO: handle error in standalone mode, logout for now
                    yield put(logout());
                    break;
                }
            }
        }
    }
}

function* leaveMeeting() {
    while (true) {
        const leaveAction = yield take([
            LEAVE_MEETING,
            ALC_KICKED,
            ALC_MEETING_COMPLETED,
            WEBRTC_RECONNECTING_FAILURE,
        ]);
        // leave webRtc meeting if joined
        const webRtcMeetingJoined = yield select(getWebRtcMeetingJoined);
        if (webRtcMeetingJoined) {
            yield put(webRtcLeave());
        }
        // leave alc meeting if joined
        const alcMeetingJoined = yield select(getAlcMeetingJoined);
        let meetingInfo;
        if (alcMeetingJoined) {
            meetingInfo = yield select(getMeetingInfo);
            yield put(alcLeaveMeeting());
            yield take(ALC_LEAVE_MEETING_SUCCESS);
        }
        const serviceId = yield select(getServiceId);
        const meetingsManagerUrl = yield select(getMeetingsManagerUrl);
        // redirect to meetings manager page
        if (leaveAction.redirectTo) {
            yield put(
                push(routeWithServiceId(leaveAction.redirectTo, serviceId))
            );
            if (meetingInfo && meetingsManagerUrl) {
                // if meeting manager is set, send checkCompleted message
                yield take(MANAGER_IFRAME_READY);
                const clientState = yield select(getClientState);
                if (clientState !== 'leftMeeting') {
                    yield take(ALC_LEAVE_MEETING_SUCCESS);
                }
                yield put(managerIFrameSendCheckCompleted(meetingInfo));
            }
        }
        if (leaveAction.type === ALC_KICKED) {
            yield put(push(routeWithServiceId(Routes.DASHBOARD, serviceId)));
            yield put(
                showMessage({
                    contentId: 'msgKick',
                    type: 'info',
                })
            );
        } else if (leaveAction.type === ALC_MEETING_COMPLETED) {
            if (leaveAction.initiatedBySelf && meetingsManagerUrl) {
                // if initiated completeMeeting and meeting manager is set, send openEditMeeting message
                const meetingId = yield select(getLastMeetingId);
                yield put(openEditMeeting(meetingId, leaveAction.meetingType));
            } else {
                yield put(
                    push(routeWithServiceId(Routes.DASHBOARD, serviceId))
                );
            }
            yield put(
                showMessage({
                    contentId: 'msgMeetingCompleted',
                    type: 'info',
                })
            );
        } else if (leaveAction.type === WEBRTC_RECONNECTING_FAILURE) {
            yield put(push(routeWithServiceId(Routes.DASHBOARD, serviceId)));
            yield put(
                showMessage({
                    contentId: 'alcErrorMeetingConnectionLost',
                    type: 'warn',
                })
            );
        }
    }
}

function* leaveMeetingOnLogout() {
    while (true) {
        yield take([LOGOUT, FORCE_LOGOUT]);
        const webRtcMeetingJoined = yield select(getWebRtcMeetingJoined);
        if (webRtcMeetingJoined) {
            yield put(webRtcLeave());
        }
        const alcMeetingJoined = yield select(getAlcMeetingJoined);
        if (alcMeetingJoined) {
            yield put(alcLeaveMeeting());
        }
        const webRtcInitialized = yield select(getWebRtcInitialized);
        if (webRtcInitialized) {
            yield put(webRtcClose());
        }
        const alcInitialized = yield select(getAlcInitialized);
        if (alcInitialized) {
            yield put(alcClose());
        }
    }
}

function* startRecording() {
    while (true) {
        yield take(START_RECORDING);
        yield put(alcStartRecording());
        while (true) {
            const alcRecordingAction = yield take([
                ALC_START_RECORDING_SUCCESS,
                ALC_ERROR,
            ]);
            if (alcRecordingAction.type === ALC_START_RECORDING_SUCCESS) {
                const webRtcMode = yield select(getWebRtcMode);
                if (
                    webRtcMode === WEBRTC_MODES.LIVESWITCH_P2P ||
                    webRtcMode === WEBRTC_MODES.ADIA_P2P
                ) {
                    yield put(webRtcStartRecording());
                    const startRecordingAction = yield take([
                        WEBRTC_START_RECORDING_SUCCESS,
                        WEBRTC_START_RECORDING_FAILURE,
                    ]);
                    if (
                        startRecordingAction.type ===
                        WEBRTC_START_RECORDING_FAILURE
                    ) {
                        log.error(startRecordingAction.error);
                        yield put(
                            showMessage({
                                contentId: webRtcErrorCodeToErrorId(
                                    startRecordingAction.error.errorCode
                                ),
                                type: 'error',
                                errorNo: startRecordingAction.error.errorCode,
                            })
                        );
                        yield put(alcStopRecording());
                        yield put(startRecordingFailure());
                        break;
                    }
                }
                yield put(startRecordingSuccess());
                break;
            } else if (
                alcRecordingAction.type === ALC_ERROR &&
                alcRecordingAction.context === 'startRecording'
            ) {
                if (alcRecordingAction.error.errorNo === 40105) {
                    yield put(showRecordingConfirmationDialog());
                }
                yield put(startRecordingFailure());
                break;
            }
        }
    }
}

function* stopRecording() {
    while (true) {
        yield take(STOP_RECORDING);
        const webRtcMode = yield select(getWebRtcMode);
        if (webRtcMode === WEBRTC_MODES.LIVESWITCH_SFU) {
            yield put(alcStopRecording());
        } else if (
            webRtcMode === WEBRTC_MODES.LIVESWITCH_P2P ||
            webRtcMode === WEBRTC_MODES.ADIA_P2P
        ) {
            yield put(webRtcStopRecording());
            yield put(alcStopRecording());
        }
    }
}

export function* meetingsSagas() {
    yield all([
        joinMeeting(),
        joinMeetingStandalone(),
        leaveMeeting(),
        leaveMeetingOnLogout(),
        startRecording(),
        stopRecording(),
    ]);
}
