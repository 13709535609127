import loginLogo from '../assets/images/adiaLive.svg';
import mainLogo from '../assets/images/adiaLive_white.svg';
import favicon from '../assets/favicons/favicon_adiacom.ico';

export const defaultConfig = {
    loginLogo,
    mainLogo,
    favicon,
    documentTitle: 'adiaLive Adviser',
    showBreadcrumb: true,
    agendaDisabled: false,
    minutesDisabled: false,
    editAccountDisabled: false,
    completeMeetingDialogOnLeave: false,
};
