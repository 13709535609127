import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import classNames from 'classnames';
import throttle from 'lodash/throttle';

import MeetingMainVideo from './MeetingMainVideo';
import MeetingVideoList from './MeetingVideoList';
import MeetingTopbar from './MeetingTopbar';
import MeetingToolbar from './MeetingToolbar';
import SharedApplicationsPanel from './SharedApplicationsPanel';
import ChatDocumentsPanel from './ChatDocumentsPanel';
import MeetingHeader from './RecordingIndicator';
import ParticipantActionsDialog from './ParticipantActionsDialog';
import RecordingConfirmationDialog from './RecordingConfirmationDialog';
import { LoadingScreen } from '../base/loadingScreen/LoadingScreen';

import {
    joinMeeting,
    leaveMeeting,
    showControls,
    hideControls,
    hideCompleteMeetingDialog,
    hideLeaveMeetingDialog,
    alcCompleteMeeting,
} from './actions';
import { MEETING_TYPES, RESPONSIVE_MODES } from '../../constants/constants';
import { getTranslatedString } from '../base/i18n/translations';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import TranslatedString from '../base/i18n/TranslatedString';
import Routes from '../../constants/routes';

export class Meeting extends Component {
    constructor(props) {
        super(props);
        this._showToolbarTimeout = null;
        this.handleMouseEvent = this.handleMouseEvent.bind(this);
        this.handleMouseEventThrottled = throttle(this.handleMouseEvent, 100);
        this.handleCompleteMeeting = this.handleCompleteMeeting.bind(this);
    }

    componentDidMount() {
        if (!this.props.alcMeetingJoined) {
            const meetingId = this.props.match.params.id;
            this.props.joinMeeting(meetingId);
        }

        window.addEventListener('mousemove', this.handleMouseEventThrottled);
        window.addEventListener('touchmove', this.handleMouseEventThrottled);
        window.addEventListener('touchstart', this.handleMouseEventThrottled);
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.handleMouseEventThrottled);
        window.removeEventListener('touchmove', this.handleMouseEventThrottled);
        window.removeEventListener(
            'touchstart',
            this.handleMouseEventThrottled
        );

        if (this.props.alcMeetingJoined) {
            this.props.leaveMeeting();
        }
    }

    handleMouseEvent() {
        if (!this.props.controlsVisible) {
            this.props.showControls();
        }
        if (this._showToolbarTimeout) {
            clearTimeout(this._showToolbarTimeout);
            this._showToolbarTimeout = null;
        }
        // TODO: remove check for webinar when handled better
        if (this.props.meetingType !== MEETING_TYPES.WEBINAR) {
            this._showToolbarTimeout = setTimeout(() => {
                if (!this.props.menuVisible && !this.props.infoPanelVisible) {
                    this.props.hideControls();
                    this._showToolbarTimeout = null;
                } else {
                    this.handleMouseEvent();
                }
            }, 5000);
        }
    }

    handleCompleteMeeting() {
        this.props.hideCompleteMeetingDialog();
        this.props.hideLeaveMeetingDialog();
        this.props.alcCompleteMeeting();
    }

    render() {
        if (this.props.alcMeetingJoined && this.props.webRtcMeetingJoined) {
            return (
                <div className="meeting-wrapper">
                    <div
                        className={classNames('meeting', {
                            'videolist-active':
                                this.props.responsiveMode >
                                RESPONSIVE_MODES.SMALL,
                            'responsive-mode-small':
                                this.props.responsiveMode ===
                                RESPONSIVE_MODES.SMALL,
                            'responsive-mode-medium':
                                this.props.responsiveMode ===
                                RESPONSIVE_MODES.MEDIUM,
                            'responsive-mode-full':
                                this.props.responsiveMode ===
                                RESPONSIVE_MODES.FULL,
                        })}
                    >
                        <MeetingMainVideo />
                        <MeetingHeader />
                        <MeetingTopbar />
                        <MeetingToolbar />
                        {this.props.responsiveMode > RESPONSIVE_MODES.SMALL && (
                            <MeetingVideoList />
                        )}
                        {this.props.sharedApplicationsUrl && (
                            <React.Fragment>
                                <SharedApplicationsPanel />
                                <ChatDocumentsPanel />
                            </React.Fragment>
                        )}
                        <ParticipantActionsDialog />
                        <RecordingConfirmationDialog />
                        {this.renderCompleteMeetingDialog()}
                        {this.renderLeaveMeetingDialog()}
                        <Prompt
                            message={getTranslatedString(
                                this.props.language,
                                'msgReallyLeaveMeeting'
                            )}
                        />
                    </div>
                </div>
            );
        } else {
            return <LoadingScreen />;
        }
    }

    renderCompleteMeetingDialog() {
        let completeMeetingDialog = null;
        if (this.props.completeMeetingDialogVisible) {
            const footer = (
                <div>
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'cancel'
                        )}
                        className="p-button-secondary"
                        icon="pi pi-times"
                        onClick={this.props.hideCompleteMeetingDialog}
                    />
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'confirm'
                        )}
                        className="p-button"
                        icon="pi pi-check"
                        onClick={this.handleCompleteMeeting}
                    />
                </div>
            );

            completeMeetingDialog = (
                <Dialog
                    header={<TranslatedString id={'completeMeeting'} />}
                    footer={footer}
                    visible={this.props.completeMeetingDialogVisible}
                    onHide={this.props.hideCompleteMeetingDialog}
                    baseZIndex={999999}
                    focusOnShow={false}
                >
                    <TranslatedString id="completeMeetingConfirmation" />
                </Dialog>
            );
        }

        return completeMeetingDialog;
    }

    renderLeaveMeetingDialog() {
        // only used to prompt user to complete meeting on leaving (if possible)
        // could be extended to general leave confirmation
        let leaveMeetingDialog = null;
        if (this.props.leaveMeetingDialogVisible) {
            const footer = (
                <div>
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'leaveMeeting'
                        )}
                        className="p-button-secondary"
                        icon="pi pi-md-exit-to-app"
                        onClick={() => {
                            if (
                                this.props.meetingType === MEETING_TYPES.WEBINAR
                            ) {
                                this.props.leaveMeeting(Routes.WEBINARS);
                            } else if (
                                this.props.meetingType ===
                                MEETING_TYPES.PHONE_CONSULTING
                            ) {
                                this.props.leaveMeeting(
                                    Routes.PHONE_CONSULTING
                                );
                            } else {
                                this.props.leaveMeeting(Routes.MEETINGS);
                            }
                        }}
                    />
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'completeMeeting'
                        )}
                        className="p-button"
                        icon="pi pi-check"
                        onClick={this.handleCompleteMeeting}
                    />
                </div>
            );

            leaveMeetingDialog = (
                <Dialog
                    header={<TranslatedString id={'completeMeeting'} />}
                    footer={footer}
                    visible={this.props.leaveMeetingDialogVisible}
                    onHide={this.props.hideLeaveMeetingDialog}
                    baseZIndex={999999}
                    focusOnShow={false}
                >
                    <TranslatedString id="completeMeetingConfirmation" />
                </Dialog>
            );
        }

        return leaveMeetingDialog;
    }
}

const mapStateToProps = (state) => {
    return {
        alcMeetingJoined: state.meetings.alcMeetingJoined,
        webRtcMeetingJoined: state.meetings.webRtcMeetingJoined,
        controlsVisible: state.meetings.controlsVisible,
        sharedApplicationsUrl:
            state.auth.publicServiceInfo.meetingsSettings.sharedApplicationsUrl,
        responsiveMode: state.base.common.responsiveMode,
        menuVisible: state.meetings.menuVisible,
        infoPanelVisible: state.meetings.infoPanelVisible,
        completeMeetingDialogVisible:
            state.meetings.completeMeetingDialogVisible,
        leaveMeetingDialogVisible: state.meetings.leaveMeetingDialogVisible,
        language: state.base.i18n.language,
        meetingType:
            state.meetings.meetingInfo && state.meetings.meetingInfo.type,
    };
};

const mapDispatchToProps = {
    joinMeeting,
    leaveMeeting,
    showControls,
    hideControls,
    hideCompleteMeetingDialog,
    hideLeaveMeetingDialog,
    alcCompleteMeeting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Meeting);
