import React, { Component } from 'react';
import classNames from 'classnames';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';

import { statsMap } from '../base/util/webRtcStats';
import TranslatedString from '../base/i18n/TranslatedString';
import { CONNECTION_QUALITY_THRESHOLDS } from '../../constants/constants';

export default class ConnectionIndicator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stats: undefined,
        };

        this.updateStats = this.updateStats.bind(this);

        this.updateInterval = null;
    }

    componentDidMount() {
        this.updateStats();
        this.updateInterval = setInterval(this.updateStats, 1000);
    }

    componentWillUnmount() {
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
            this.updateInterval = null;
        }
    }

    updateStats() {
        let stats = statsMap.get(this.props.userInfo.id);
        if (stats) {
            this.setState({ stats });
        }
    }

    renderStats() {
        let bitrate, packetLoss, resolution, framerate;

        if (this.state.stats) {
            const dArrow = (
                <i
                    className="pi-md-arrow-downward"
                    style={{ fontSize: '12px' }}
                />
            );
            const uArrow = (
                <i
                    className="pi-md-arrow-upward"
                    style={{ fontSize: '12px' }}
                />
            );
            let bitrateDown,
                bitrateUp,
                packetLossDown,
                packetLossUp,
                resolutionDown,
                resolutionUp,
                framerateDown,
                framerateUp;
            if (
                this.state.stats.bitrate &&
                (typeof this.state.stats.bitrate.download === 'number' ||
                    typeof this.state.stats.bitrate.upload === 'number')
            ) {
                if (typeof this.state.stats.bitrate.download === 'number') {
                    bitrateDown = (
                        <span>
                            {dArrow}
                            {this.state.stats.bitrate.download + ' KBit/s '}
                        </span>
                    );
                }
                if (typeof this.state.stats.bitrate.upload === 'number') {
                    bitrateUp = (
                        <span>
                            {uArrow}
                            {this.state.stats.bitrate.upload + ' KBit/s'}
                        </span>
                    );
                }
                bitrate = (
                    <span>
                        {bitrateDown}
                        {bitrateUp}
                    </span>
                );
            }
            if (
                this.state.stats.packetLoss &&
                (typeof this.state.stats.packetLoss.download === 'number' ||
                    typeof this.state.stats.packetLoss.upload === 'number')
            ) {
                if (typeof this.state.stats.packetLoss.download === 'number') {
                    packetLossDown = (
                        <span>
                            {dArrow}
                            {this.state.stats.packetLoss.download + '% '}
                        </span>
                    );
                }
                if (typeof this.state.stats.bitrate.upload === 'number') {
                    packetLossUp = (
                        <span>
                            {uArrow}
                            {this.state.stats.packetLoss.upload + '%'}
                        </span>
                    );
                }
                packetLoss = (
                    <span>
                        {packetLossDown}
                        {packetLossUp}
                    </span>
                );
            }
            if (
                this.state.stats.resolution &&
                (this.state.stats.resolution.download ||
                    this.state.stats.resolution.upload)
            ) {
                if (this.state.stats.resolution.download) {
                    resolutionDown = (
                        <span>
                            {dArrow}
                            {this.state.stats.resolution.download + ' '}
                        </span>
                    );
                }
                if (this.state.stats.resolution.upload) {
                    resolutionUp = (
                        <span>
                            {uArrow}
                            {this.state.stats.resolution.upload}
                        </span>
                    );
                }
                resolution = (
                    <span>
                        {resolutionDown}
                        {resolutionUp}
                    </span>
                );
            }
            if (
                this.state.stats.frameRate &&
                (this.state.stats.frameRate.download ||
                    this.state.stats.frameRate.upload)
            ) {
                if (this.state.stats.frameRate.download) {
                    framerateDown = (
                        <span>
                            {dArrow}
                            {this.state.stats.frameRate.download + ' '}
                        </span>
                    );
                }
                if (this.state.stats.frameRate.upload) {
                    framerateUp = (
                        <span>
                            {uArrow}
                            {this.state.stats.frameRate.upload}
                        </span>
                    );
                }
                framerate = (
                    <span>
                        {framerateDown}
                        {framerateUp}
                    </span>
                );
            }
        }

        let browser, device, os;
        if (this.props.userInfo.metadata) {
            if (
                this.props.userInfo.metadata.browser &&
                this.props.userInfo.metadata.browser.name
            ) {
                browser =
                    this.props.userInfo.metadata.browser.name +
                    ' ' +
                    (this.props.userInfo.metadata.browser.version
                        ? this.props.userInfo.metadata.browser.version
                        : '');
            }
            if (
                this.props.userInfo.metadata.device &&
                this.props.userInfo.metadata.device.vendor
            ) {
                device =
                    this.props.userInfo.metadata.device.vendor +
                    ' ' +
                    (this.props.userInfo.metadata.device.model
                        ? this.props.userInfo.metadata.device.model
                        : '');
            }
            if (
                this.props.userInfo.metadata.os &&
                this.props.userInfo.metadata.os.name
            ) {
                os =
                    this.props.userInfo.metadata.os.name +
                    ' ' +
                    (this.props.userInfo.metadata.os.version
                        ? this.props.userInfo.metadata.os.version
                        : '');
            }
        }

        return (
            <table className="stats-table">
                <tbody>
                    {bitrate && (
                        <tr>
                            <td>
                                <TranslatedString id="bitrate" />:{' '}
                            </td>
                            <td>{bitrate}</td>
                        </tr>
                    )}
                    {packetLoss && (
                        <tr>
                            <td>
                                <TranslatedString id="packetLoss" />:{' '}
                            </td>
                            <td>{packetLoss}</td>
                        </tr>
                    )}
                    {resolution && (
                        <tr>
                            <td>
                                <TranslatedString id="resolution" />:{' '}
                            </td>
                            <td>{resolution}</td>
                        </tr>
                    )}
                    {framerate && (
                        <tr>
                            <td>
                                <TranslatedString id="framerate" />:{' '}
                            </td>
                            <td>{framerate}</td>
                        </tr>
                    )}
                    {browser && (
                        <tr>
                            <td>Browser: </td>
                            <td>{browser}</td>
                        </tr>
                    )}
                    {device && (
                        <tr>
                            <td>Device: </td>
                            <td>{device}</td>
                        </tr>
                    )}
                    {os && (
                        <tr>
                            <td>OS: </td>
                            <td>{os}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        if (this.state.stats) {
            let connectionQuality = this.state.stats.connectionQuality;

            // workaround for wrong connection quality if video is muted/inactive,
            // TODO: remove check and prop videoActive when webrtc-layer handles this
            if (!this.props.videoActive) {
                connectionQuality = 100;
            }

            const className = classNames(
                'connection-indicator',
                {
                    'show-always':
                        connectionQuality < CONNECTION_QUALITY_THRESHOLDS.GOOD,
                },
                {
                    'connection-good':
                        connectionQuality >= CONNECTION_QUALITY_THRESHOLDS.GOOD,
                },
                {
                    'connection-medium':
                        connectionQuality <
                            CONNECTION_QUALITY_THRESHOLDS.GOOD &&
                        connectionQuality >=
                            CONNECTION_QUALITY_THRESHOLDS.MEDIUM,
                },
                {
                    'connection-bad':
                        connectionQuality <
                        CONNECTION_QUALITY_THRESHOLDS.MEDIUM,
                }
            );

            return (
                <div className={className}>
                    <Tippy
                        placement={
                            this.props.isSelf ? 'left-end' : 'left-start'
                        }
                        arrow={false}
                        content={this.renderStats()}
                    >
                        <i className="icon-gsm-bars" />
                    </Tippy>
                </div>
            );
        } else return null;
    }
}
