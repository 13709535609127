export const ALC_INIT = 'ALC_INIT';
export const ALC_JOIN_MEETING = 'ALC_JOIN_MEETING';
export const ALC_JOIN_MEETING_STANDALONE = 'ALC_JOIN_MEETING_STANDALONE';
export const ALC_LEAVE_MEETING = 'ALC_LEAVE_MEETING';
export const ALC_LEAVE_MEETING_SUCCESS = 'ALC_LEAVE_MEETING_SUCCESS';
export const ALC_KICKED = 'ALC_KICKED';
export const ALC_INITIALIZED = 'ALC_INITIALIZED';
export const ALC_RECONNECTING = 'ALC_RECONNECTING';
export const ALC_SESSION_RESTORED = 'ALC_SESSION_RESTORED';
export const ALC_JOIN_MEETING_SUCCESS = 'ALC_JOIN_MEETING_SUCCESS';
export const ALC_MEETING_INFO_UPDATE = 'ALC_MEETING_INFO_UPDATE';
export const ALC_CLIENT_INFO_UPDATE = 'ALC_CLIENT_INFO_UPDATE';
export const ALC_MEETING_PARTICIPANTS_UPDATE =
    'ALC_MEETING_PARTICIPANTS_UPDATE';
export const ALC_ADVISER_AUTH = 'ALC_ADVISER_AUTH';
export const ALC_SET_CURRENT_APP = 'ALC_SET_CURRENT_APP';
export const ALC_SET_EDIT_PERMISSIONS = 'ALC_SET_EDIT_PERMISSIONS';
export const ALC_CHANGE_ACTIVE_USER = 'ALC_CHANGE_ACTIVE_USER';
export const ALC_MUTE_PARTICIPANT = 'ALC_MUTE_PARTICIPANT';
export const ALC_KICK_PARTICIPANT = 'ALC_KICK_PARTICIPANT';
export const ALC_COMPLETE_MEETING = 'ALC_COMPLETE_MEETING';
export const ALC_MEETING_COMPLETED = 'ALC_MEETING_COMPLETED';
export const ALC_CLOSE = 'ALC_CLOSE';
export const ALC_ERROR = 'ALC_ERROR';
export const ALC_UPDATE_SERVERTIME_DIFF = 'ALC_UPDATE_SERVERTIME_DIFF';
export const ALC_SET_SPEAKER_PERMISSION = 'ALC_SET_SPEAKER_PERMISSION';

export const WEBRTC_INIT = 'WEBRTC_INIT';
export const WEBRTC_INIT_SUCCESS = 'WEBRTC_INIT_SUCCESS';
export const WEBRTC_INIT_FAILURE = 'WEBRTC_INIT_FAILURE';
export const WEBRTC_START_LOCAL_MEDIA = 'WEBRTC_START_LOCAL_MEDIA';
export const WEBRTC_START_LOCAL_MEDIA_SUCCESS =
    'WEBRTC_START_LOCAL_MEDIA_SUCCESS';
export const WEBRTC_START_LOCAL_MEDIA_FAILURE =
    'WEBRTC_START_LOCAL_MEDIA_FAILURE';
export const WEBRTC_STOP_LOCAL_MEDIA = 'WEBRTC_STOP_LOCAL_MEDIA';
export const WEBRTC_JOIN = 'WEBRTC_JOIN';
export const WEBRTC_JOIN_SUCCESS = 'WEBRTC_JOIN_SUCCESS';
export const WEBRTC_JOIN_FAILURE = 'WEBRTC_JOIN_FAILURE';
export const WEBRTC_LEAVE = 'WEBRTC_LEAVE';
export const WEBRTC_USERJOINED = 'WEBRTC_USERJOINED';
export const WEBRTC_USERLEFT = 'WEBRTC_USERLEFT';
export const WEBRTC_SPEAKER_CHANGED = 'WEBRTC_SPEAKER_CHANGED';
export const WEBRTC_START_SCREENSHARE = 'WEBRTC_START_SCREENSHARE';
export const WEBRTC_START_SCREENSHARE_SUCCESS =
    'WEBRTC_START_SCREENSHARE_SUCCESS';
export const WEBRTC_STOP_SCREENSHARE = 'WEBRTC_STOP_SCREENSHARE';
export const WEBRTC_STOP_SCREENSHARE_SUCCESS =
    'WEBRTC_STOP_SCREENSHARE_SUCCESS';
export const WEBRTC_CLOSE = 'WEBRTC_CLOSE';
export const WEBRTC_RECONNECTING = 'WEBRTC_RECONNECTING';
export const WEBRTC_RECONNECTING_SUCCESS = 'WEBRTC_RECONNECTING_SUCCESS';
export const WEBRTC_RECONNECTING_FAILURE = 'WEBRTC_RECONNECTING_FAILURE';

export const JOIN_MEETING = 'JOIN_MEETING';
export const JOIN_MEETING_STANDALONE = 'JOIN_MEETING_STANDALONE';
export const JOIN_MEETING_SUCCESS = 'JOIN_MEETING_SUCCESS';
export const JOIN_MEETING_FAILURE = 'JOIN_MEETING_FAILURE';
export const LEAVE_MEETING = 'LEAVE_MEETING';

export const SA_SHOW = 'SA_SHOW';
export const SA_SWITCH_OWN = 'SA_SWITCH_OWN';
export const SA_HIDE = 'SA_HIDE';
export const SA_IFRAME_NEW_REF = 'SA_IFRAME_NEW_REF';
export const SA_IFRAME_LOAD = 'SA_IFRAME_LOAD';
export const SA_IFRAME_INIT_CONFIRMED = 'SA_IFRAME_INIT_CONFIRMED';
export const SA_IFRAME_READY = 'SA_IFRAME_READY';

export const DOCUMENTS_SHOW = 'DOCUMENTS_SHOW';
export const DOCUMENTS_HIDE = 'DOCUMENTS_HIDE';
export const DOCUMENTS_NEW_UPLOAD = 'DOCUMENTS_NEW_UPLOAD';

export const CHAT_SHOW = 'CHAT_SHOW';
export const CHAT_HIDE = 'CHAT_HIDE';
export const CHAT_DOCUMENTS_IFRAME_NEW_REF = 'CHAT_DOCUMENTS_IFRAME_NEW_REF';
export const CHAT_DOCUMENTS_IFRAME_LOAD = 'CHAT_DOCUMENTS_IFRAME_LOAD';
export const CHAT_DOCUMENTS_IFRAME_INIT_CONFIRMED =
    'CHAT_DOCUMENTS_IFRAME_INIT_CONFIRMED';
export const CHAT_DOCUMENTS_IFRAME_READY = 'CHAT_DOCUMENTS_IFRAME_READY';
export const CHAT_NEW_MESSAGE = 'CHAT_NEW_MESSAGE';

export const CONTROLS_SHOW = 'CONTROLS_SHOW';
export const CONTROLS_HIDE = 'CONTROLS_HIDE';

export const MENU_SHOW = 'MENU_SHOW';
export const MENU_HIDE = 'MENU_HIDE';

export const INFO_PANEL_SHOW = 'INFO_PANEL_SHOW';
export const INFO_PANEL_HIDE = 'INFO_PANEL_HIDE';

export const PARTICIPANT_ACTIONS_SHOW = 'PARTICIPANT_ACTIONS_SHOW';
export const PARTICIPANT_ACTIONS_HIDE = 'PARTICIPANT_ACTIONS_HIDE';

export const COMPLETE_MEETING_DIALOG_SHOW = 'COMPLETE_MEETING_DIALOG_SHOW';
export const COMPLETE_MEETING_DIALOG_HIDE = 'COMPLETE_MEETING_DIALOG_HIDE';

export const LEAVE_MEETING_DIALOG_SHOW = 'LEAVE_MEETING_DIALOG_SHOW';
export const LEAVE_MEETING_DIALOG_HIDE = 'LEAVE_MEETING_DIALOG_HIDE';

export const FULLSCREEN_TOGGLE = 'FULLSCREEN_TOGGLE';
export const FULLSCREEN_CHANGE = 'FULLSCREEN_CHANGE';

export const START_RECORDING = 'START_RECORDING';
export const START_RECORDING_SUCCESS = 'START_RECORDING_SUCCESS';
export const START_RECORDING_FAILURE = 'START_RECORDING_FAILURE';
export const STOP_RECORDING = 'STOP_RECORDING';
export const ALC_START_RECORDING = 'ALC_START_RECORDING';
export const ALC_STOP_RECORDING = 'ALC_STOP_RECORDING';
export const ALC_START_RECORDING_SUCCESS = 'ALC_START_RECORDING_SUCCESS';
export const WEBRTC_START_RECORDING = 'WEBRTC_START_RECORDING';
export const WEBRTC_START_RECORDING_SUCCESS = 'WEBRTC_START_RECORDING_SUCCESS';
export const WEBRTC_START_RECORDING_FAILURE = 'WEBRTC_START_RECORDING_FAILURE';
export const WEBRTC_STOP_RECORDING = 'WEBRTC_STOP_RECORDING';

export const RECORDING_CONFIRMATION_DIALOG_SHOW =
    'RECORDING_CONFIRMATION_DIALOG_SHOW';
export const RECORDING_CONFIRMATION_DIALOG_HIDE =
    'RECORDING_CONFIRMATION_DIALOG_HIDE';
