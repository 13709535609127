import React, { Component } from 'react';

import { audioLevelMap } from '../../features/base/util/webRtcStats';

export default class AudioLevelIndicator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            audioLevel: 0,
        };

        this.updateAudioLevel = this.updateAudioLevel.bind(this);

        this.updateInterval = null;
    }

    componentDidMount() {
        this.updateAudioLevel();
        this.updateInterval = setInterval(this.updateAudioLevel, 200);
    }

    componentWillUnmount() {
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
            this.updateInterval = null;
        }
    }

    updateAudioLevel() {
        const audioLevelBase = audioLevelMap.get(this.props.userId);
        if (audioLevelBase || audioLevelBase === 0) {
            const audioLevel =
                (100 * Math.log(1 + audioLevelBase)) / Math.log(2) / 100;

            // do not update the whole time..
            if (this.state.audioLevel > 0 && audioLevel < 0.03) {
                this.setState({ audioLevel: 0 });
            } else if (audioLevel >= 0.03) {
                this.setState({ audioLevel });
            }
        }
    }

    render() {
        return (
            <div className="audio-level-indicator">
                <div
                    className="audiodot"
                    style={{ opacity: this.state.audioLevel }}
                />
                <div
                    className="audiodot"
                    style={{ opacity: this.state.audioLevel * 1.5 }}
                />
                <div
                    className="audiodot"
                    style={{ opacity: this.state.audioLevel * 3 }}
                />
                <div
                    className="audiodot"
                    style={{ opacity: this.state.audioLevel * 1.5 }}
                />
                <div
                    className="audiodot"
                    style={{ opacity: this.state.audioLevel }}
                />
            </div>
        );
    }
}
