import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { Dialog } from 'primereact/dialog';
import classNames from 'classnames';

import TranslatedString from '../base/i18n/TranslatedString';

import {
    RESOLUTION_LIST,
    COMMUNICATION_MODES,
    RESPONSIVE_MODES,
} from '../../constants/constants';
import DeviceSettingsAudioLevel from './DeviceSettingsAudioLevel';
import {
    hideSettings,
    webRtcChangeCam,
    webRtcChangeCommunicationMode,
    webRtcChangeMic,
    webRtcChangeRes,
    webRtcApplyRecoverySettings,
    webRtcRefreshDeviceLists,
    settingsRecoveryChangeCam,
    settingsRecoveryChangeMic,
    settingsRecoveryChangeRes,
    settingsRecoveryChangeCommunicationMode,
} from './actions';
import { Message } from 'primereact/message';
import { getTranslatedString } from '../base/i18n/translations';

export class DeviceSettings extends Component {
    constructor(props) {
        super(props);
        this.videoContainerRef = React.createRef();
        this.handleCommunicationModeChange = this.handleCommunicationModeChange.bind(
            this
        );
        this.handleCamChange = this.handleCamChange.bind(this);
        this.handleMicChange = this.handleMicChange.bind(this);
        this.handleResChange = this.handleResChange.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    componentDidMount() {
        if (this.props.view) {
            this.videoContainerRef.current.appendChild(this.props.view);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.view !== this.props.view) {
            if (
                prevProps.view &&
                this.videoContainerRef.current.contains(prevProps.view)
            ) {
                this.videoContainerRef.current.removeChild(prevProps.view);
            }
            if (this.props.view) {
                this.videoContainerRef.current.appendChild(this.props.view);
            }
        }
    }

    componentWillUnmount() {
        if (
            this.props.view &&
            this.videoContainerRef.current.contains(this.props.view)
        ) {
            this.videoContainerRef.current.removeChild(this.props.view);
        }
    }

    handleCommunicationModeChange(e) {
        if (e.value) {
            if (!this.props.recoveryMode) {
                this.props.webRtcChangeCommunicationMode(e.value);
            } else {
                this.props.settingsRecoveryChangeCommunicationMode(e.value);
            }
        }
    }

    handleCamChange(e) {
        if (e.value) {
            if (!this.props.recoveryMode) {
                this.props.webRtcChangeCam(e.value);
            } else {
                this.props.settingsRecoveryChangeCam(e.value);
            }
        }
    }

    handleMicChange(e) {
        if (e.value) {
            if (!this.props.recoveryMode) {
                this.props.webRtcChangeMic(e.value);
            } else {
                this.props.settingsRecoveryChangeMic(e.value);
            }
        }
    }

    handleResChange(e) {
        if (e.value) {
            if (!this.props.recoveryMode) {
                this.props.webRtcChangeRes(e.value);
            } else {
                this.props.settingsRecoveryChangeRes(e.value);
            }
        }
    }

    handleRefresh(e) {
        if (this.props.recoveryMode) {
            this.props.webRtcRefreshDeviceLists(
                this.props.recoveryCommunicationMode
            );
        } else {
            this.props.webRtcRefreshDeviceLists(this.props.communicationMode);
        }
    }

    render() {
        const communicationMode = !this.props.recoveryMode
            ? this.props.communicationMode
            : this.props.recoveryCommunicationMode;
        const deviceSettings = !this.props.recoveryMode
            ? this.props.currentSettings
            : this.props.recoverySettings;

        let showCamSettings = false;
        let showMicSettings = false;

        switch (communicationMode) {
            case COMMUNICATION_MODES.BOTH: {
                showCamSettings = showMicSettings = true;
                break;
            }
            case COMMUNICATION_MODES.VIDEO: {
                showCamSettings = true;
                break;
            }
            case COMMUNICATION_MODES.AUDIO: {
                showMicSettings = true;
                break;
            }
            case COMMUNICATION_MODES.NONE:
            default: {
                showCamSettings = showMicSettings = false;
            }
        }

        let camDeviceOptions, micDeviceOptions, resolutionOptions;

        if (showCamSettings && this.props.deviceLists.camDeviceList) {
            camDeviceOptions = this.props.deviceLists.camDeviceList.map(
                (el) => {
                    return { label: el, value: el };
                }
            );
        }
        if (showMicSettings && this.props.deviceLists.micDeviceList) {
            micDeviceOptions = this.props.deviceLists.micDeviceList.map(
                (el) => {
                    return { label: el, value: el };
                }
            );
        }

        if (showCamSettings) {
            resolutionOptions = RESOLUTION_LIST.map((el) => {
                return { label: el, value: el };
            });

            if (
                deviceSettings.res &&
                !RESOLUTION_LIST.includes(deviceSettings.res)
            ) {
                resolutionOptions.unshift({
                    label: deviceSettings.res,
                    value: deviceSettings.res,
                });
            }
        }

        const footer = !this.props.recoveryMode ? (
            <Button
                label={getTranslatedString(this.props.language, 'close')}
                className="p-button-secondary"
                icon="pi pi-times"
                onClick={this.props.hideSettings}
                disabled={this.props.loading}
            />
        ) : (
            <Button
                label={getTranslatedString(this.props.language, 'confirm')}
                className="p-button"
                icon="pi pi-check"
                onClick={this.props.webRtcApplyRecoverySettings}
                disabled={
                    (communicationMode === COMMUNICATION_MODES.AUDIO &&
                        !deviceSettings.mic) ||
                    (communicationMode === COMMUNICATION_MODES.BOTH &&
                        (!deviceSettings.cam ||
                            !deviceSettings.res ||
                            !deviceSettings.mic))
                }
            />
        );

        return (
            <Dialog
                className="settings-panel"
                header={<TranslatedString id={'deviceSettings'} />}
                footer={footer}
                visible={this.props.settingsPanelShown}
                onHide={this.props.hideSettings}
                baseZIndex={999999}
                closable={!this.props.loading && !this.props.recoveryMode}
                focusOnShow={false}
                style={
                    this.props.responsiveMode < RESPONSIVE_MODES.MEDIUM
                        ? { width: '90%' }
                        : null
                }
            >
                {this.props.errorId && (
                    <div className="p-col-12" style={{ marginLeft: '-4px' }}>
                        <Message
                            severity="warn"
                            text={getTranslatedString(
                                this.props.language,
                                this.props.errorId
                            )}
                        />
                    </div>
                )}
                <div className="p-col-12">
                    <SelectButton
                        value={communicationMode}
                        options={[
                            {
                                label: getTranslatedString(
                                    this.props.language,
                                    'comModeNone'
                                ),
                                value: COMMUNICATION_MODES.NONE,
                            },
                            {
                                label: getTranslatedString(
                                    this.props.language,
                                    'comModeAudio'
                                ),
                                value: COMMUNICATION_MODES.AUDIO,
                            },
                            {
                                label: getTranslatedString(
                                    this.props.language,
                                    'comModeBoth'
                                ),
                                value: COMMUNICATION_MODES.BOTH,
                            },
                        ]}
                        onChange={this.handleCommunicationModeChange}
                        disabled={this.props.loading}
                    />
                </div>
                <div className="p-col-12">
                    <div
                        className={classNames('settings-panel-video', {
                            'no-video':
                                !showCamSettings || this.props.recoveryMode,
                        })}
                        ref={this.videoContainerRef}
                    />
                </div>
                <div className="p-grid form-group">
                    {communicationMode !== COMMUNICATION_MODES.NONE && (
                        <div className="p-col-12">
                            <Button
                                label={getTranslatedString(
                                    this.props.language,
                                    'refresh'
                                )}
                                className="p-button-secondary"
                                icon="pi pi-md-refresh"
                                onClick={this.handleRefresh}
                                disabled={this.props.loading}
                            />
                        </div>
                    )}
                    {showCamSettings && (
                        <React.Fragment>
                            <div className="p-col-12">
                                <Dropdown
                                    className="form-input"
                                    value={deviceSettings.cam}
                                    options={camDeviceOptions}
                                    onChange={this.handleCamChange}
                                    disabled={
                                        this.props.loading ||
                                        camDeviceOptions.length === 0
                                    }
                                    scrollHeight={'150px'}
                                />
                                <label className="dropdown-label-float">
                                    <TranslatedString id="cam" />
                                </label>
                            </div>
                            <div className="p-col-12">
                                <Dropdown
                                    className="form-input"
                                    value={deviceSettings.res}
                                    options={resolutionOptions}
                                    onChange={this.handleResChange}
                                    disabled={
                                        this.props.loading ||
                                        camDeviceOptions.length === 0 ||
                                        !deviceSettings.cam
                                    }
                                    scrollHeight={'150px'}
                                />
                                <label className="dropdown-label-float">
                                    <TranslatedString id="resolution" />
                                </label>
                            </div>
                        </React.Fragment>
                    )}
                    {showMicSettings && (
                        <React.Fragment>
                            <div className="p-col-12">
                                <Dropdown
                                    className="form-input"
                                    value={deviceSettings.mic}
                                    options={micDeviceOptions}
                                    onChange={this.handleMicChange}
                                    disabled={
                                        this.props.loading ||
                                        micDeviceOptions.length === 0
                                    }
                                    scrollHeight={'150px'}
                                />
                                <label className="dropdown-label-float">
                                    <TranslatedString id="mic" />
                                </label>
                            </div>
                            <div
                                className="p-col-12"
                                style={{ paddingBottom: '50px' }}
                            >
                                {!this.props.recoveryMode ? (
                                    <DeviceSettingsAudioLevel />
                                ) : (
                                    <div
                                        className="p-col-12"
                                        style={{ paddingBottom: '16px' }}
                                    />
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        deviceLists: state.deviceSettings.deviceLists,
        currentSettings: state.deviceSettings.currentSettings,
        communicationMode: state.deviceSettings.communicationMode,
        view: state.deviceSettings.view,
        settingsPanelShown: state.deviceSettings.settingsPanelShown,
        errorId: state.deviceSettings.errorId,
        loading: state.deviceSettings.loading,
        recoveryMode: state.deviceSettings.recoveryMode,
        recoverySettings: state.deviceSettings.recoverySettings,
        recoveryCommunicationMode:
            state.deviceSettings.recoveryCommunicationMode,
        language: state.base.i18n.language,
        responsiveMode: state.base.common.responsiveMode,
    };
};

const mapDispatchToProps = {
    hideSettings,
    webRtcChangeCam,
    webRtcChangeMic,
    webRtcChangeRes,
    webRtcChangeCommunicationMode,
    webRtcRefreshDeviceLists,
    webRtcApplyRecoverySettings,
    settingsRecoveryChangeCam,
    settingsRecoveryChangeMic,
    settingsRecoveryChangeRes,
    settingsRecoveryChangeCommunicationMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSettings);
