import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';

import {
    requestRecordings,
    downloadRecording,
    deleteRecording,
} from './actions';
import { Message } from 'primereact/message';
import { Dialog } from 'primereact/dialog';

const ROWS = 25;

export class Recordings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recordingToDeleteId: null,
            deleteDialogVisible: false,
        };

        this.handleShowDeleteDialog = this.handleShowDeleteDialog.bind(this);
        this.handleHideDeleteDialog = this.handleHideDeleteDialog.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.props.requestRecordings();
    }

    handleShowDeleteDialog(id) {
        this.setState({
            recordingToDeleteId: id,
            deleteDialogVisible: true,
        });
    }

    handleHideDeleteDialog() {
        this.setState({
            recordingToDeleteId: null,
            deleteDialogVisible: false,
        });
    }

    handleDownload(id) {
        this.props.downloadRecording(id);
        this.props.requestRecordings();
    }

    handleDelete() {
        this.props.deleteRecording(this.state.recordingToDeleteId);
        this.handleHideDeleteDialog();
        this.props.requestRecordings();
    }

    render() {
        if (this.props.errorId) {
            return (
                <div className="layout-content">
                    <div className="p-grid meetings-manager-standard">
                        <div className="p-col-12">
                            <Message
                                severity="warn"
                                text={getTranslatedString(
                                    this.props.language,
                                    this.props.errorId
                                )}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        let deleteRecordingDialog = null;
        if (this.state.deleteDialogVisible) {
            const footer = (
                <div>
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'cancel'
                        )}
                        className="p-button-secondary"
                        icon="pi pi-times"
                        onClick={this.handleHideDeleteDialog}
                    />
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'confirm'
                        )}
                        className="p-button"
                        icon="pi pi-check"
                        onClick={this.handleDelete}
                    />
                </div>
            );

            deleteRecordingDialog = (
                <Dialog
                    header={<TranslatedString id={'deleteRecording'} />}
                    footer={footer}
                    visible={this.state.deleteDialogVisible}
                    onHide={this.handleHideDeleteDialog}
                    baseZIndex={999999}
                    focusOnShow={false}
                >
                    <TranslatedString id="deleteRecordingConfirmation" />
                </Dialog>
            );
        }

        return (
            <React.Fragment>
                <div className="layout-content">
                    <div className="p-grid meetings-manager-standard">
                        <div className="p-col-12">
                            <RecordingsTable
                                isLoading={this.props.isLoading}
                                recordings={this.props.recordings}
                                language={this.props.language}
                                onDownloadClick={this.handleDownload}
                                onDeleteClick={this.handleShowDeleteDialog}
                            />
                        </div>
                    </div>
                </div>
                {deleteRecordingDialog}
            </React.Fragment>
        );
    }
}

/*

 */

class RecordingsTable extends Component {
    rowClassName(rowData) {
        return {
            'row-downloaded': rowData.downloadDate,
            'row-not-downloaded': !rowData.downloadDate,
        };
    }

    render() {
        let newDownloadAvailable = false;
        const twentyFourHoursAgo = Date.now() - 24 * 60 * 60 * 1000;
        const recordingTableEntries = this.props.recordings.map((item, key) => {
            let recordingItem = {};
            recordingItem.title = item.meetingInfo.title;
            recordingItem.downloadDate = item.downloadDate;
            if (item.downloadDate) {
                const timeLeft = Math.ceil(
                    (item.downloadDate - twentyFourHoursAgo) / (60 * 60 * 1000)
                );
                let downloadInfoText = '';
                if (timeLeft <= 1) {
                    downloadInfoText = getTranslatedString(
                        this.props.language,
                        'markedForDelete1Hour'
                    );
                } else {
                    downloadInfoText = getTranslatedString(
                        this.props.language,
                        'markedForDelete'
                    ).replace('{{hours}}', timeLeft);
                }
                recordingItem.downloadInfo = downloadInfoText;
            } else {
                recordingItem.downloadInfo = (
                    <TranslatedString id="needsDownload" />
                );
                newDownloadAvailable = true;
            }

            // convert date object to desired format (DD.MM.YYYY, hh:mm)
            const date = new Date(item.start);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            recordingItem.date =
                (day < 10 ? '0' + day : day) +
                '.' +
                (month < 10 ? '0' + month : month) +
                '.' +
                year +
                ', ' +
                (hours < 10 ? '0' + hours : hours) +
                ':' +
                (minutes < 10 ? '0' + minutes : minutes);

            recordingItem.numberOfParticipants =
                item.meetingInfo.participants.length;

            recordingItem.downloadButton = (
                <React.Fragment>
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'download'
                        )}
                        icon="pi-md-video-library"
                        onClick={() =>
                            this.props.onDownloadClick(item.meetingId)
                        }
                    />
                    <Button
                        icon="pi-md-delete"
                        className="delete-button"
                        onClick={() => this.props.onDeleteClick(item.meetingId)}
                        style={{ borderRadius: '3px' }}
                    />
                </React.Fragment>
            );

            return recordingItem;
        });

        return (
            <Card
                title={getTranslatedString(this.props.language, 'recordings')}
            >
                {recordingTableEntries.length > 0 ? (
                    <React.Fragment>
                        {newDownloadAvailable && (
                            <div className="p-col-12">
                                <Message
                                    severity="info"
                                    text={getTranslatedString(
                                        this.props.language,
                                        'unsavedRecordings'
                                    )}
                                    className="table-message"
                                />
                            </div>
                        )}
                        <DataTable
                            autoLayout={true}
                            responsive={true}
                            value={recordingTableEntries}
                            rows={ROWS}
                            paginator={true}
                            paginatorPosition={'top'}
                            alwaysShowPaginator={false}
                            rowClassName={this.rowClassName}
                        >
                            <Column
                                className="column date-col"
                                field="date"
                                header={
                                    <TranslatedString id={'meetingTableDate'} />
                                }
                            />
                            <Column
                                className="column title-col"
                                field="title"
                                header={
                                    <TranslatedString
                                        id={'meetingTableTitle'}
                                    />
                                }
                            />
                            <Column
                                className="column participants-col"
                                field="numberOfParticipants"
                                header={
                                    <TranslatedString
                                        id={'meetingTableParticipants'}
                                    />
                                }
                            />
                            <Column
                                className="column description-col"
                                field="downloadInfo"
                            />
                            <Column
                                className="column download-button-col"
                                field="downloadButton"
                            />
                        </DataTable>
                        <div className="recording-info-text">
                            <TranslatedString id="recordingsNotice" />
                        </div>
                    </React.Fragment>
                ) : (
                    <span>
                        <TranslatedString id="noRecordings" />
                    </span>
                )}
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.recordings.isLoading,
        recordings: state.recordings.recordingList,
        errorId: state.recordings.errorId,
        language: state.base.i18n.language,
    };
};

const mapDispatchToProps = {
    requestRecordings,
    downloadRecording,
    deleteRecording,
};

export default connect(mapStateToProps, mapDispatchToProps)(Recordings);
