import React from 'react';
import { connect } from 'react-redux';

import { docManagerIFrameLoad, docManagerIFrameNewRef } from './actions';

export class DocumentsManager extends React.Component {
    constructor(props) {
        super(props);
        this.iFrameRef = React.createRef();
    }

    componentDidMount() {
        this.props.docManagerIFrameNewRef(this.iFrameRef);
    }

    componentWillUnmount() {
        this.props.docManagerIFrameNewRef(null);
    }

    render() {
        if (this.props.meetingsManagerUrl) {
            // TODO replace with proper setting once implemented
            let managerUrl = this.props.meetingsManagerUrl.replace(
                '/meetings',
                '/nepatec/business-case-types'
            );

            return (
                <iframe
                    className="meetings-manager-iframe"
                    title="documents manager iframe"
                    src={managerUrl}
                    allowFullScreen
                    ref={this.iFrameRef}
                    onLoad={this.props.docManagerIFrameLoad}
                />
            );
        }
    }
}

const mapStateToProps = (state) => {
    let meetingsManagerUrl = null;
    if (
        state.auth.publicServiceInfo &&
        state.auth.publicServiceInfo.meetingsSettings
    ) {
        meetingsManagerUrl =
            state.auth.publicServiceInfo.meetingsSettings.meetingsManagerUrl;
    }

    return {
        meetingsManagerUrl,
    };
};

const mapDispatchToProps = {
    docManagerIFrameLoad,
    docManagerIFrameNewRef,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsManager);
