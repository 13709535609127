import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import dateFormat from 'dateformat';
import { FullCalendar } from 'primereact/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import deLocale from '@fullcalendar/core/locales/de';
import enGbLocale from '@fullcalendar/core/locales/en-gb';
import frLocale from '@fullcalendar/core/locales/fr';
import itLocale from '@fullcalendar/core/locales/it';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';

import TranslatedString from '../base/i18n/TranslatedString';
import { requestOwnMeetings } from './actions';
import { goToMeeting, openEditMeeting } from '../meetingsManager/actions';
import { getTranslatedString } from '../base/i18n/translations';
import { ContextMenu } from 'primereact/contextmenu';
import { adiaLiveSettings } from '../base/util/adiaLiveSettings';

class Dashboard extends Component {
    componentDidMount() {
        this.props.requestOwnMeetings();
    }

    render() {
        const adviserData = [];
        if (this.props.adviserList) {
            Object.entries(this.props.adviserList).forEach(([key, value]) => {
                if (!value.standalone) {
                    adviserData.push({ name: value.info.name });
                }
            });
        }
        if (adviserData.length === 0) {
            adviserData.push({ name: '' });
        }

        let calendarData = [];
        this.props.ownMeetingsData.forEach(meeting => {
            let meetingObj = {
                id: meeting.id,
                title: meeting.title,
                start: meeting.dateObj,
                end: meeting.endDateObj,
                state: meeting.state,
                meetingType: meeting.type
            };
            if (meeting.state === 'ongoing') {
                meetingObj.textColor = '#ffffff';
                meetingObj.backgroundColor = 'darkgreen';
                meetingObj.borderColor = 'orange';
            }
            calendarData.push(meetingObj);
        });

        let fullcalendarOptions = {
            defaultDate: dateFormat(new Date(), 'yyyy-mm-dd'),
            plugins: [
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin
            ],
            defaultView: 'listWeek',
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            },
            editable: false,
            locales: [deLocale, enGbLocale, frLocale, itLocale],
            locale:
                this.props.language === 'en' ? 'en-gb' : this.props.language,
            eventClick: info => {
                this.currentId = info.event.id;
                this.currentType = info.event.extendedProps.meetingType;
                this.cm.show(info.jsEvent);
            }
        };

        const menuItems = [
            {
                label: getTranslatedString(this.props.language, 'join'),
                icon: 'pi-md-video-library',
                command: () => {
                    this.props.goToMeeting(this.currentId)}
            }
        ];
        if (this.props.meetingsManagerUrl) {
            menuItems.push({
                label: getTranslatedString(this.props.language, 'edit'),
                icon: 'pi-md-edit',
                command: () => this.props.openEditMeeting(this.currentId, this.currentType)
            });
        }

        return (
            <div className="layout-content">
                <ContextMenu model={menuItems} ref={el => (this.cm = el)} />
                <div className="p-grid dashboard">
                    <div className="p-col-12 p-xl-8">
                        <div className="overview-box overview-box-ad">
                            <div className="overview-box-header" />
                            <div className="overview-box-content">
                                <div className="overview-box-icon">
                                    <i className="material-icons">event</i>
                                </div>
                                <div className="overview-box-title">
                                    <TranslatedString id="ownOpenMeetings" />
                                </div>
                                <div className="overview-box-count">
                                    {calendarData.length}
                                </div>
                            </div>
                        </div>
                        <Card>
                            <FullCalendar
                                events={calendarData}
                                options={fullcalendarOptions}
                            />
                        </Card>
                    </div>
                    {!this.props.hideAdviserList && (
                        <div className="p-col-12 p-lg-6 p-xl-3 p-offset-0 p-xl-offset-1">
                            <div className="overview-box overview-box-4">
                                <div className="overview-box-header" />
                                <div className="overview-box-content">
                                    <div className="overview-box-icon">
                                        <i className="material-icons">
                                            supervisor_account
                                        </i>
                                    </div>
                                    <div className="overview-box-title">
                                        <TranslatedString id="adviserOnline" />
                                    </div>
                                    <div className="overview-box-count">
                                        {adviserData.length}
                                    </div>
                                </div>
                            </div>
                            <Card>
                                <DataTable
                                    value={adviserData}
                                    header={null}
                                    rows={15}
                                    paginator={true}
                                    paginatorPosition={'top'}
                                    alwaysShowPaginator={false}
                                >
                                    <Column
                                        field={'name'}
                                        header={null}
                                        headerStyle={{ display: 'none' }}
                                    />
                                </DataTable>
                            </Card>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    let ownMeetingsData = [];

    state.dashboard.ownMeetings.forEach(meeting => {
        if (
            meeting.date &&
            (meeting.state === 'open' || meeting.state === 'ongoing')
        ) {
            ownMeetingsData.push({
                id: meeting._id,
                title: meeting.title,
                date: dateFormat(meeting.date, 'dd.mm.yyyy, HH:MM'),
                dateObj: meeting.date,
                state: meeting.state,
                endDateObj: new Date(
                    meeting.date.getTime() +
                        meeting.durationInMinutes * 60 * 1000
                ),
                durationInMinutes: meeting.durationInMinutes,
                type: meeting.type
            });
        }
    });

    let meetingsManagerUrl = null;
    if (
        state.auth.publicServiceInfo &&
        state.auth.publicServiceInfo.meetingsSettings
    ) {
        meetingsManagerUrl =
            state.auth.publicServiceInfo.meetingsSettings.meetingsManagerUrl;
    }

    let hideAdviserList = true;
    const flags = state.auth.publicServiceInfo
        ? state.auth.publicServiceInfo.flags
        : undefined;
    if (flags) {
        hideAdviserList = adiaLiveSettings.flagsToSettings(flags)
            .hideAdviserList;
    }

    return {
        adviserList: state.dashboard.adviserList,
        ownMeetingsData,
        language: state.base.i18n.language,
        meetingsManagerUrl,
        hideAdviserList
    };
};

const mapDispatchToProps = {
    requestOwnMeetings,
    goToMeeting,
    openEditMeeting
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
