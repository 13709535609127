import React, { Component } from 'react';
import classNames from 'classnames';

import ConnectionIndicator from './ConnectionIndicator';
import AudioLevelIndicator from './AudioLevelIndicator';
import { Button } from 'primereact/button';

export default class VideoListItem extends Component {
    constructor(props) {
        super(props);
        this.videoContainerRef = React.createRef();
        this.handleChangeActiveUser = this.handleChangeActiveUser.bind(this);
        this.handleRevokeClick = this.handleRevokeClick.bind(this);
    }

    handleChangeActiveUser() {
        if (this.props.isCurrentActiveUser) {
            this.props.onChangeActiveUser(null);
        } else {
            this.props.onChangeActiveUser(this.props.userInfo.id);
        }
    }

    componentDidMount() {
        if (this.props.videoView && this.props.videoView.view) {
            this.videoContainerRef.current.appendChild(
                this.props.videoView.view
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.videoView !== this.props.videoView) {
            if (prevProps.videoView && prevProps.videoView.view) {
                if (
                    this.videoContainerRef.current.contains(
                        prevProps.videoView.view
                    )
                ) {
                    this.videoContainerRef.current.removeChild(
                        prevProps.videoView.view
                    );
                }
            }
            if (this.props.videoView && this.props.videoView.view) {
                this.videoContainerRef.current.appendChild(
                    this.props.videoView.view
                );
            }
        }
    }

    componentWillUnmount() {
        if (this.props.videoView && this.props.videoView.view) {
            if (
                this.videoContainerRef.current.contains(
                    this.props.videoView.view
                )
            ) {
                this.videoContainerRef.current.removeChild(
                    this.props.videoView.view
                );
            }
        }
    }

    handleRevokeClick(e) {
        e.stopPropagation();
        this.props.onRevokeSpeakerPermission();
    }

    render() {
        const hasVideo = this.props.videoView && this.props.videoView.view;

        let placeholder = null;
        if (!hasVideo) {
            if (this.props.isMainVideo) {
                if (this.props.isCurrentActiveUser) {
                    placeholder = (
                        <div className="video-list-icon">
                            <i className="icon-open_in_new" />
                        </div>
                    );
                } else if (this.props.isActiveSpeaker) {
                    placeholder = (
                        <div className="video-list-icon">
                            <i className="icon-open_in_new" />
                        </div>
                    );
                } else {
                    placeholder = (
                        <div className="video-list-icon">
                            <i className="icon-open_in_new" />
                        </div>
                    );
                }
            } else {
                let nameParts;
                if (this.props.userInfo.info.name) {
                    nameParts = this.props.userInfo.info.name.split(' ');
                }
                let initials = '';
                if (nameParts) {
                    if (nameParts.length > 1 && nameParts[1] !== '') {
                        initials =
                            nameParts[0].substr(0, 1) +
                            nameParts[1].substr(0, 1);
                    } else {
                        initials = nameParts[0].substr(0, 2);
                    }
                }
                placeholder = (
                    <div className="video-list-icon">
                        <div className="name-initial">{initials}</div>
                    </div>
                );
            }
        }
        return (
            <div
                className={classNames('video-list-box', {
                    'video-box-active': this.props.isCurrentActiveUser,
                })}
                onClick={this.handleChangeActiveUser}
            >
                <div className="participant-name">
                    {this.props.userInfo.info.name}
                </div>
                <ConnectionIndicator
                    userInfo={this.props.userInfo}
                    videoActive={this.props.userInfo.deviceInfo.video}
                    fullscreenActive={this.props.fullscreenActive}
                    isSelf={this.props.isSelf}
                />
                <div className="video-container" ref={this.videoContainerRef}>
                    {placeholder}
                </div>
                <AudioLevelIndicator userId={this.props.userInfo.id} />
                {!this.props.userInfo.deviceInfo.audio && (
                    <div className="muted-icon">
                        <i className="icon-mic-disabled" />
                    </div>
                )}
                {this.props.isRevokeable && (
                    <Button
                        className="p-button-secondary revoke-button"
                        icon="pi pi-times"
                        onClick={this.handleRevokeClick}
                    />
                )}
            </div>
        );
    }
}
