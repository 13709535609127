import React from 'react';
import { connect } from 'react-redux';
import { Panel } from 'primereact/panel';

import { alcSetSpeakerPermission } from './actions';
import { Button } from 'primereact/button';
import TranslatedString from "../base/i18n/TranslatedString";

export function ParticipantList(props) {
    let participantItems = [];
    let raisedHandItems = [];
    props.meetingParticipants.forEach(participant => {
        // all participants
        if (!participant.isAdviser && !participant.standalone) {
            participantItems.push(
                <div className="p-col-12" key={participant.id}>
                    {participant.info.name}
                </div>
            );
        }
        if (participant.requestedSpeakerPermission) {
            raisedHandItems.push(
                <div className="p-col-12" key={participant.id}>
                    <Button
                        className="p-button-secondary"
                        icon="pi pi-md-check"
                        onClick={() =>
                            props.alcSetSpeakerPermission(participant.id, true)
                        }
                        style={{

                        }}
                    />
                    {'  '}
                    {participant.info.name}
                </div>
            );
        }
    });

    return (
        <div className="webinar-participant-list">
            {raisedHandItems.length > 0 && (
                <Panel
                    header={<TranslatedString id={'requestedSpeakerPermission'} />}
                    className={'webinar-requesting-panel'}
                >
                    <div className="p-grid">{raisedHandItems}</div>

                </Panel>
            )}
            {participantItems.length > 0 && (
                <Panel
                    header={<TranslatedString id={'participants'} />}
                    className={'webinar-participant-panel'}
                >
                    <div className="p-grid">{participantItems}</div>
                </Panel>
            )}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        meetingParticipants: state.meetings.meetingParticipants,
        clientInfo: state.meetings.clientInfo
    };
};

const mapDispatchToProps = {
    alcSetSpeakerPermission
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantList);
