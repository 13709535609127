let serviceUrl = '';
if (window.location.href.includes('ovk-meet-adviser.vzch.com')) {
    serviceUrl = 'https://ovk-meet-app01.vzch.com';
} else if (window.location.href.includes('ovk-meet-int-adviser.vzch.com')) {
    serviceUrl = 'https://ovk-meet-int-app01.vzch.com';
}

export const Config = {
    serviceUrl,
    theme: 'vz',
    languageOverrides: {
        de: {
            agenda: 'Besprechungsinhalt',
        },
    },
};
