let serviceUrl = '';
if (window.location.href.includes('host.adialive.ch')) {
    serviceUrl = 'https://al5-app10.adialive.ch';
} else if (window.location.href.includes('host-del.adialive.ch')) {
    serviceUrl = 'https://al5-app10-del.adialive.ch';
} else if (window.location.href.includes('host-uc.adialive.ch')) {
    serviceUrl = 'https://al5-app10-uc.adialive.ch';
}

export const Config = {
    serviceUrl,
};
