import React, { Component } from 'react';
import logo from '../../assets/images/adiaLive_white.svg';

export class AppFooter extends Component {
    render() {
        return (
            <div className="layout-footer clearfix">
                <img alt="logo" src={logo} />
                <span className="footer-text-right">
                    <span className="material-icons">copyright</span>
                    <span>All Rights Reserved</span>
                </span>
            </div>
        );
    }
}
