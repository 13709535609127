import logo from '../assets/images/logo_vema.png';
import favicon from '../assets/favicons/favicon_vema.ico';

export const Config = {
    serviceUrl: 'https://al5-vema.adia.tv',
    loginLogo: logo,
    mainLogo: logo,
    favicon,
    theme: 'vema',
    documentTitle: 'VEMA Beratung',
    showBreadcrumb: false,
    editAccountDisabled: true,

    languageOverrides: {
        de: {
            applicationName: 'VEMA Beratung',
            adHoc: 'Sofort-Meeting',
            adHocMeeting: 'Sofort-Meeting',
        },
        en: {
            applicationName: 'VEMA Beratung',
        },
        fr: {
            applicationName: 'VEMA Beratung',
        },
        it: {
            applicationName: 'VEMA Beratung',
        },
    },
};
