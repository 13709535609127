import React from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import { alcChangeActiveUser, alcSetSpeakerPermission } from './actions';
import VideoListItem from './VideoListItem';
import { MEETING_TYPES } from '../../constants/constants';
import ParticipantList from './ParticipantList';

export function MeetingVideoList(props) {
    let videoItems = [];
    for (let i = 0; i < props.meetingParticipants.length; i++) {
        // not standalone and not self, if webinar only adviser and participant with speakerPermission
        if (
            !props.meetingParticipants[i].standalone &&
            props.meetingParticipants[i].id !== props.clientInfo.id &&
            (props.meetingInfo.type !== MEETING_TYPES.WEBINAR ||
                props.meetingParticipants[i].isAdviser ||
                props.meetingParticipants[i].speakerPermission)
        ) {
            const isMainVideo =
                props.meetingParticipants[i].id === props.mainVideoId;
            const videoActive = props.meetingParticipants[i].deviceInfo.video;
            const screenShareActive =
                props.meetingParticipants[i].deviceInfo.screen;

            let videoView = undefined;

            if (
                (isMainVideo && screenShareActive && videoActive) ||
                (!isMainVideo && !screenShareActive && videoActive)
            ) {
                videoView = props.videoViews.find(
                    (el) => el.userId === props.meetingParticipants[i].id
                );
            } else if (!isMainVideo && screenShareActive) {
                videoView = props.screenShareViews.find(
                    (el) => el.userId === props.meetingParticipants[i].id
                );
            }
            videoItems.push(
                <VideoListItem
                    key={props.meetingParticipants[i].id}
                    userInfo={props.meetingParticipants[i]}
                    isMainVideo={isMainVideo}
                    isSelf={false}
                    isCurrentActiveUser={
                        props.meetingParticipants[i].id ===
                        props.meetingInfo.currentActiveUser
                    }
                    isActiveSpeaker={
                        props.meetingParticipants[i].id ===
                        props.activeSpeakerId
                    }
                    videoView={videoView}
                    onChangeActiveUser={props.alcChangeActiveUser}
                    fullscreenActive={props.fullscreenActive}
                    isRevokeable={
                        props.meetingParticipants[i].speakerPermission
                    }
                    onRevokeSpeakerPermission={() =>
                        props.alcSetSpeakerPermission(
                            props.meetingParticipants[i].id,
                            false
                        )
                    }
                />
            );
        }
    }
    const isMainVideo = props.clientInfo.id === props.mainVideoId;
    const videoActive = props.clientInfo.deviceInfo.video;
    const screenShareActive = props.clientInfo.deviceInfo.screen;

    let videoView = undefined;

    if (
        ((isMainVideo && screenShareActive && videoActive) ||
            (!isMainVideo && !screenShareActive && videoActive)) &&
        !props.settingsPanelShown
    ) {
        videoView = props.ownVideoView;
    } else if (!isMainVideo && screenShareActive) {
        videoView = props.ownScreenShareView;
    }

    return (
        <div
            className={
                'participant-list' +
                (props.controlsVisible ? ' controlsVisible' : '')
            }
        >
            <div className="remote-videos">
                <Scrollbars>
                    {videoItems}
                    {props.meetingInfo.type === MEETING_TYPES.WEBINAR && (
                        <ParticipantList />
                    )}
                </Scrollbars>
            </div>
            <div className="local-video">
                <VideoListItem
                    key={'self'}
                    userInfo={props.clientInfo}
                    isMainVideo={isMainVideo}
                    isSelf={true}
                    isCurrentActiveUser={
                        props.clientInfo.id ===
                        props.meetingInfo.currentActiveUser
                    }
                    isActiveSpeaker={
                        props.clientInfo.id === props.activeSpeakerId
                    }
                    videoView={videoView}
                    onChangeActiveUser={props.alcChangeActiveUser}
                    fullscreenActive={props.fullscreenActive}
                    isRevokeable={false}
                />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        meetingParticipants: state.meetings.meetingParticipants,
        meetingInfo: state.meetings.meetingInfo,
        activeSpeakerId: state.meetings.activeSpeakerId,
        videoViews: state.meetings.videoViews,
        screenShareViews: state.meetings.screenShareViews,
        ownVideoView: state.meetings.ownVideoView,
        ownScreenShareView: state.meetings.ownScreenShareView,
        clientInfo: state.meetings.clientInfo,
        mainVideoId: state.meetings.mainVideoId,
        controlsVisible: state.meetings.controlsVisible,
        fullscreenActive: state.meetings.fullscreenActive,
        settingsPanelShown: state.deviceSettings.settingsPanelShown,
    };
};

const mapDispatchToProps = {
    alcChangeActiveUser,
    alcSetSpeakerPermission,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingVideoList);
