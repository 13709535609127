export const english = {
    // General
    language: 'Language',
    de: 'German',
    en: 'English',
    fr: 'French',
    it: 'Italian',

    applicationName: 'adiaLive Adviser',

    save: 'Save',
    cancel: 'Cancel',
    confirm: 'Confirm',
    close: 'Close',
    join: 'Join',
    edit: 'Edit',
    refresh: 'Refresh',

    areYouSure: 'Are You Sure?',

    username: 'Username',
    password: 'Password',
    serviceId: 'Service-ID',
    captcha: 'Captcha',
    signIn: 'Sign In',
    signOut: 'Logout',

    dashboard: 'Dashboard',
    meetings: 'Meetings',
    phoneConsulting: 'Phone Consulting',
    signatureTemplates: 'Signature templates',

    reconnectingHeader: 'Reconnecting',
    reconnectingMessage: 'Lost Connection to Server. Trying to reconnect...',

    pathDoesNotExist: 'This page does not exist!',

    // Account

    account: 'Account',
    nepatecAccount: 'Nepatec Account',
    editAccount: 'Edit Account',
    name: 'Name',
    lastName: 'Last Name',
    firstName: 'First Name',
    email: 'Email',
    newPassword: 'New Password',
    newPasswordConfirmation: 'Confirmation',
    status: 'Status',
    active: 'Active',

    invalidEmail: 'Invalid email address',
    invalidPassword: 'The password must be at least 8 characters long.',
    editAccountSuccess: 'Your account has been updated.',
    editAccountFailure: "Your account couldn't be updated.",
    editPasswordSuccess: 'Your password has been updated.',
    editPasswordFailure: "Your password couldn't be updated.",
    editPasswordCurrentWrong: 'Invalid existing password.',
    editPasswordDoesNotMatch: "Password confirmation doesn't match.",
    userKeycloakConflict:
        'A user with this username and/or email already exists.',
    getNepatecFailure: 'Failed to get user.',

    // Dashboard

    adviserOnline: 'Adviser Online',
    ownOpenMeetings: 'Your open Meetings',

    // Device Settings

    deviceSettings: 'Settings',
    cam: 'Camera',
    mic: 'Microphone',
    resolution: 'Resolution',
    comModeNone: 'Chat only',
    comModeAudio: 'Audio',
    comModeBoth: 'Audio + Video',

    // Meeting

    agenda: 'Agenda',
    showboard: 'Showboard',
    minutes: 'Minutes',
    documents: 'Documents',
    chat: 'Chat',
    show: 'Show',
    editModePermissions: 'Edit Mode Permissions',

    startFullscreen: 'Start Full Screen',
    stopFullscreen: 'Exit Full Screen',
    startScreenShare: 'Share your Screen',
    stopScreenShare: 'Stop sharing your Screen',
    leaveMeeting: 'Leave Meeting',
    muteMic: 'Mute Microphone',
    unmuteMic: 'Unmute Microphone',
    activateMic: 'Activate Microphone',
    startCam: 'Start Camera',
    stopCam: 'Stop Camera',
    activateCam: 'Activate Camera',
    meetingInfo: 'Meeting Info',
    sharedApplications: 'Shared Applications',
    menu: 'Menu',

    startRecording: 'Start Recording',
    stopRecording: 'Stop Recording',
    muteParticipant: 'Mute Participant',
    kickParticipant: 'Kick Participant',
    msgRemoteMute: 'You have been muted by an Adviser',
    msgKick: 'You have been removed from the meeting by an Adviser',
    participantActions: 'Mute / Kick Participant',
    noParticipants: 'No Participants in Meeting',
    mute: 'Mute',
    kick: 'Kick',
    msgReallyLeaveMeeting: 'Do you really want to leave the meeting?',
    completeMeeting: 'Complete Meeting',
    completeMeetingConfirmation: 'Do you really want to complete this meeting?',
    msgMeetingCompleted: 'The Meeting was completed',

    share: 'Share',
    standaloneApplications: 'Standalone Applications',
    link: 'Link',

    bitrate: 'Bitrate',
    framerate: 'Framerate',
    packetLoss: 'Packet loss',

    // Meeting Manager Standard

    meetingTableDate: 'Date',
    meetingTableTitle: 'Title',
    meetingTableDescription: 'Description',
    meetingTableParticipants: 'Participants',

    // Webinars
    webinars: 'Webinars',
    participants: 'Participants',
    requestedSpeakerPermission: 'Requested Speaking Permission',
    leaveWebinar: 'Leave Webinar',

    // Recordings
    recordings: 'Recordings',
    download: 'Download',
    deleteRecording: 'Delete Recording',
    deleteRecordingConfirmation: 'Do you really want to delete this recording?',
    unsavedRecordings:
        'Recordings found. Please download them now and then delete them from the list.',
    noRecordings: 'No recordings found',
    recordingsNotice:
        'Due to browser storage limits, please keep the download list as short as possible by downloading and then deleting recordings.',
    markedForDelete: 'Will be deleted in {{hours}} hours',
    markedForDelete1Hour: 'Will be deleted in less than an hour',
    needsDownload: 'Please download',
    confirmRecordingTitle: 'Requesting user permission',
    confirmRecordingMessage:
        'Asking participants whether they will allow recording. Please wait for confirmation.',
    confirmRecordingResultAsk: 'Asking participants',
    confirmRecordingResultRejected: 'Rejected',
    confirmRecordingResultConfirmed: 'Confirmed',
    restartConfirmation: 'Request Confirmation again',

    // adHoc
    adHoc: 'Ad Hoc',
    scheduled: 'Scheduled',
    meeting: 'Meeting',
    phoneConsultation: 'Phone consultation',
    meetingId: 'Meeting-ID',
    consultationId: 'Consultation-ID',
    adHocMeeting: 'Ad Hoc Meeting',
    adHocPhoneConsultation: 'Ad Hoc Phone Consultation',
    newMeeting: 'New meeting',
    newPhoneConsultation: 'New phone consultation',

    // Errors

    connectionError: 'Connection to server failed.',
    serverError: 'Oops! Something went wrong.',
    accessDenied: 'Access denied!',
    notAuthorized: 'Not authorized!',
    authInvalidIp: 'Invalid IP.',
    authInvalidUser: 'Invalid user.',
    authInvalidCaptcha: 'Invalid captcha.',
    authInvalidCredentials: 'Invalid credentials.',
    serviceNotFound: 'This service does not exist.',
    invalidRoleError:
        "Only users with the 'adviser' role are allowed to use the Adviser application.",
    autoRecordingError:
        "Automatic recording couldn't be started, you're not allowed to join this meeting without recording.",

    alcErrorDefault: 'alc Error',
    alcErrorMeetingDoesNotExist:
        'This meeting has been completed or does not exist.',
    alcErrorMeetingConnectionLost:
        'Lost connection to Meeting Server. Please try again.',
    alcErrorMeetingFull:
        'Maximum number of Participants reached for this Meeting.',
    alcErrorWebinarAdviserFull:
        'Maximum number of Advisers reached for this Webinar.',
    alcErrorAdviserIdMismatch:
        'This Meeting-Invitation was not issued for your User Account.',

    webRtcErrorDefault: 'WebRTC Error',
    webRtcErrorNotFound:
        'No Camera / Microphone found. Please make sure that your Camera / Microphone is plugged in and click Refresh.',
    webRtcErrorNotAllowed:
        'Please allow access to Devices in your Browser. Activate Audio / Audio + Video afterwards.',
    webRtcErrorNotReadable:
        'Your Camera is blocked by another application. Please close it or select a different Device.',
    webRtcErrorOverconstrained:
        'Your saved Camera / Microphone could not be found. Please check that your Camera / Microphone is plugged in and click Refresh or select a different Device.',
    webRtcErrorConnectionTimeout: 'Timeout trying to connect to meeting server',
    webRtcErrorRecordingStart: 'Could not start recording.',
    webRtcErrorRecordingStream: "A stream can't be recorded.",
    webRtcErrorGetRecordings: 'Recordings could not be retrieved.',

    webRtcBrowserNotSupported:
        'Your Browser is not supported. Please use a recent version of Chrome or Firefox.',
    webRtcBrowserOutOfDate:
        'Your {{browser}} Version is ouf of date and is no longer supported. Please update your Browser.',
    webRtcBrowserNonSafariIos:
        'Unfortunately {{browser}} is not supported on iOS. Please try a recent version of Safari.',
    webRtcBrowserLegacyEdge:
        "You're using Edge Legacy. This version is out of date and is no longer supported. We suggest using the new Edge which you can download {{link}}.",
    webRtcBrowserIe:
        'Internet Explorer is not supported. We suggest using the new Edge which you can download {{link}}.',
};
