import React, { Component } from 'react';
import { connect } from 'react-redux';

import { joinMeetingStandalone } from './actions';
import { LoadingScreen } from '../base/loadingScreen/LoadingScreen';
import SharedApplicationsPanel from './SharedApplicationsPanel';

export class MeetingStandalone extends Component {
    componentDidMount() {
        if (!this.props.alcMeetingJoined) {
            const meetingId = this.props.match.params.id;
            this.props.joinMeetingStandalone(meetingId);
        }
    }

    render() {
        if (this.props.alcMeetingJoined) {
            return (
                <React.Fragment>
                    <SharedApplicationsPanel standalone={true} />
                </React.Fragment>
            );
        } else {
            return (
                <div>
                    <LoadingScreen />
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        alcMeetingJoined: state.meetings.alcMeetingJoined
    };
};

const mapDispatchToProps = {
    joinMeetingStandalone
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingStandalone);
