import React from 'react';

import { getTranslatedString, hasLanguage } from '../i18n/translations';

const Logger = require('loglevelnext/lib/LogLevel');

const urlSearchParams = new URLSearchParams(window.location.search);
export const logging = urlSearchParams.get('debug') === 'true';
export const webRtcLogging = urlSearchParams.get('debugWebRtc') === 'true';

let logLevel = 'silent';
if (logging) {
    logLevel = 'debug';
}

export const log = new Logger({
    level: logLevel,
    prefix: '{{time}} {{level}}',
});

export const storeToLocalStorage = (field, data) => {
    try {
        localStorage.setItem(field, data);
        return true;
    } catch (e) {
        return false;
    }
};

export const readFromLocalStorage = (field) => {
    try {
        return localStorage.getItem(field);
    } catch (e) {
        return null;
    }
};

export const storeToSessionStorage = (field, data) => {
    try {
        sessionStorage.setItem(field, data);
        return true;
    } catch (e) {
        return false;
    }
};

export const readFromSessionStorage = (field) => {
    try {
        return sessionStorage.getItem(field);
    } catch (e) {
        return null;
    }
};

export const removeFromSessionStorage = (field) => {
    try {
        sessionStorage.removeItem(field);
        return true;
    } catch (e) {
        return false;
    }
};

/**
 * replace :serviceId parameter in 'route' with 'serviceId'
 * @param route
 * @param serviceId
 * @returns {*}
 */
export const routeWithServiceId = (route, serviceId) => {
    return route.replace(':serviceId', serviceId);
};

/**
 * @param error - contains error message and status
 * @param onError - onError callbacks:
 *                      fatal: dispatch(forceLogout)
 *                      nonFatal: dispatch(requestFailure)
 */
export const handleError = (error, onError) => {
    log.error(error);

    if (typeof onError !== 'object' && typeof onError === 'function') {
        const callback = onError;
        onError = {
            fatal: callback,
            nonFatal: callback,
        };
    }

    if (
        !error.hasOwnProperty('internalError') &&
        (!error.hasOwnProperty('errorNo') ||
            !error.hasOwnProperty('status') ||
            !error.hasOwnProperty('message'))
    ) {
        error = {
            errorNo: undefined,
            status: undefined,
            message: 'unknown error',
        };
    }

    // 401/403 responses are treated as fatal errors and will lead to a forced logout of the user
    if (error.status === 401 || error.status === 403) {
        onError.fatal(error);
    } else {
        onError.nonFatal(error);
    }
};

/**
 *
 * @param serviceUrl
 * @returns {*|string}
 */
export const serviceUrlToAPIUrl = function (serviceUrl) {
    let apiUrl = '';
    if (serviceUrl.indexOf(':3000') > -1) {
        apiUrl = serviceUrl.replace(':3000', ':3001');
    } else if (serviceUrl.indexOf(':5000') > -1) {
        apiUrl = serviceUrl.replace(':5000', ':5001');
    } else {
        apiUrl = serviceUrl;
    }
    return apiUrl;
};

export const getPreloadLanguage = () => {
    let language;
    let userSystemLanguage = navigator.language || navigator.userLanguage;
    if (userSystemLanguage) {
        userSystemLanguage = userSystemLanguage.slice(0, 2);
        if (hasLanguage(userSystemLanguage)) {
            language = userSystemLanguage;
        }
    }

    // if language is still no set, set it to English by default
    if (!language || !hasLanguage(language)) {
        language = 'en';
    }
    return language;
};

export const webRtcErrorCodeToErrorId = function (errorCode) {
    let errorId;
    switch (errorCode) {
        case 11:
        case 111:
        case 161:
        case 171:
            errorId = 'webRtcErrorNotFound';
            break;
        case 13:
        case 131:
        case 112:
            errorId = 'webRtcErrorNotAllowed';
            break;
        case 113:
        case 162:
        case 172:
            errorId = 'webRtcErrorNotReadable';
            break;
        case 114:
            errorId = 'webRtcErrorOverconstrained';
            break;
        case 211:
            errorId = 'webRtcErrorConnectionTimeout';
            break;
        case 51:
            errorId = 'webRtcBrowserNotSupported';
            break;
        case 61:
            errorId = 'webRtcErrorRecordingStart';
            break;
        case 62:
            errorId = 'webRtcErrorRecordingStream';
            break;
        default:
            log.error('WebRtc Error ' + errorCode);
            errorId = 'webRtcErrorDefault';
    }
    return errorId;
};

const edgeDownloadLinks = {
    en: (
        <a
            href="https://www.microsoft.com/en-us/edge"
            target="_blank"
            rel="noopener noreferrer"
        >
            here
        </a>
    ),
    de: (
        <a
            href="https://www.microsoft.com/de-de/edge"
            target="_blank"
            rel="noopener noreferrer"
        >
            hier
        </a>
    ),
    fr: (
        <a
            href="https://www.microsoft.com/fr-fr/edge"
            target="_blank"
            rel="noopener noreferrer"
        >
            ici
        </a>
    ),
    it: (
        <a
            href="https://www.microsoft.com/it-it/edge"
            target="_blank"
            rel="noopener noreferrer"
        >
            qui
        </a>
    ),
};

export const getBrowserCheckMessage = function (language, errorData) {
    if (errorData.os && errorData.os.includes('iOS')) {
        if (errorData.browserName.includes('Safari')) {
            return getTranslatedString(
                language,
                'webRtcBrowserOutOfDate'
            ).replace('{{browser}}', errorData.browserName);
        } else {
            return getTranslatedString(
                language,
                'webRtcBrowserNonSafariIos'
            ).replace('{{browser}}', errorData.browserName);
        }
    } else if (errorData.browserName.includes('IE')) {
        const stringParts = getTranslatedString(
            language,
            'webRtcBrowserIe'
        ).split('{{link}}');
        if (stringParts.length === 2) {
            return (
                <React.Fragment>
                    {stringParts[0]}
                    {edgeDownloadLinks[language]}
                    {stringParts[1]}
                </React.Fragment>
            );
        }
    } else if (
        errorData.browserName.includes('Edge') &&
        errorData.browserVersion < 70
    ) {
        const stringParts = getTranslatedString(
            language,
            'webRtcBrowserLegacyEdge'
        ).split('{{link}}');
        if (stringParts.length === 2) {
            return (
                <React.Fragment>
                    {stringParts[0]}
                    {edgeDownloadLinks[language]}
                    {stringParts[1]}
                </React.Fragment>
            );
        }
    } else if (
        errorData.browserName.includes('Chrome') ||
        errorData.browserName.includes('Firefox') ||
        errorData.browserName.includes('Samsung') ||
        errorData.browserName.includes('Edge')
    ) {
        return getTranslatedString(language, 'webRtcBrowserOutOfDate').replace(
            '{{browser}}',
            errorData.browserName
        );
    }
    return getTranslatedString(language, 'webRtcBrowserNotSupported');
};

export const alcErrorCodeToErrorId = function (errorCode) {
    let errorId;
    switch (errorCode) {
        case 40499:
            errorId = 'alcErrorMeetingDoesNotExist';
            break;
        case 40101:
            errorId = 'alcErrorMeetingFull';
            break;
        case 40102:
            errorId = 'alcErrorWebinarAdviserFull';
            break;
        case 40005:
            errorId = 'alcErrorAdviserIdMismatch';
            break;
        default:
            log.error('ALC Error ' + errorCode);
            errorId = 'alcErrorDefault';
    }
    return errorId;
};

export function convertMeetingProperties(meetingData, dataFromServer) {
    if (dataFromServer) {
        meetingData.forEach((meeting) => {
            // convert unix timestamp to date
            if (meeting.date) {
                meeting.date = new Date(meeting.date);
            }
            // convert additionalMetadata object to array
            if (meeting.additionalMetadata) {
                meeting.additionalMetadata = Object.entries(
                    meeting.additionalMetadata
                );
            }
        });
    } else {
        // convert date to unix timestamp
        meetingData.date = new Date(meetingData.date).getTime();

        // convert additionalMetadata array to object
        if (meetingData.additionalMetadata) {
            meetingData.additionalMetadata = meetingData.additionalMetadata.reduce(
                (acc, curr) => {
                    acc[curr[0]] = curr[1];
                    return acc;
                },
                {}
            );
        }
    }
}

export const fullscreenEnabled =
    document.fullscreenEnabled || document.webkitFullscreenEnabled;
