export const french = {
    // General
    language: 'Langue',
    de: 'Allemand',
    en: 'Anglais',
    fr: 'Français',
    it: 'Italien',

    applicationName: 'adiaLive Adviser',

    save: 'Sauvegarder',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    close: 'Fermer',
    join: 'Participer',
    edit: 'Modifier',
    refresh: 'Actualiser',

    areYouSure: 'Êtes-vous sûr?',

    username: "Nom d'utilisateur",
    password: 'Mot de passe',
    serviceId: 'ID du service',
    captcha: 'Captcha',
    signIn: 'Se connecter',
    signOut: 'Se déconnecter',

    dashboard: 'Dashboard',
    meetings: 'Meetings',
    phoneConsulting: 'Conseil téléphonique',
    signatureTemplates: 'Modèles avec sign',

    reconnectingHeader: 'Connexion perdue',
    reconnectingMessage:
        'Connexion au serveur est perdue. La connexion sera rétablie ...',

    pathDoesNotExist: "Cette page n'existe pas!",

    // Account

    account: 'Compte',
    nepatecAccount: 'Compte Nepatec',
    editAccount: 'Édition du compte',
    name: 'Nom',
    lastName: 'Nom',
    firstName: 'Prénom',
    email: 'Courriel',
    newPassword: 'Nouveau mot de passe',
    newPasswordConfirmation: 'Confirmation',
    status: 'Statut',
    active: 'Actif',

    invalidEmail: 'Courriel invalide',
    invalidPassword: 'Le mot de passe doit comporter au moins 8 caractères.',
    editAccountSuccess: 'Votre compte a été mis à jour.',
    editAccountFailure: "Votre compte utilisateur n'a pas pu être mis à jour.",
    editPasswordSuccess: 'Votre mot de passe a été mis à jour.',
    editPasswordFailure: "Votre mot de passe n'a pas pu être mis à jour.",
    editPasswordCurrentWrong: 'Mot de passe existant invalide.',
    editPasswordDoesNotMatch:
        'Le mot de passe de confirmation ne correspond pas.',
    userKeycloakConflict:
        "Ce nom d'utilisateur et / ou cet email est déjà utilisé.",
    getNepatecFailure: "L'utilisateur n'a pas pu être récupéré.",

    // Dashboard
    adviserOnline: 'Conseiller en ligne',
    ownOpenMeetings: 'Vos meetings ouverts',

    // Device Settings
    deviceSettings: 'Paramètres',
    cam: 'Caméra',
    mic: 'Microphone',
    resolution: 'Résolution',
    comModeNone: 'Seulement le chat',
    comModeAudio: 'Audio',
    comModeBoth: 'Audio + Vidéo',

    // Meeting
    agenda: 'Agenda',
    showboard: 'Showboard',
    minutes: 'Protocole',
    documents: 'Documents',
    chat: 'Chat',
    show: 'Afficher',
    editModePermissions: 'Modifier les autorisations',

    startFullscreen: 'Démarrer le mode plein écran',
    stopFullscreen: 'Quitter le mode plein écran',
    startScreenShare: "Partager l'écran",
    stopScreenShare: "Arrêtez de partager l'écran",
    leaveMeeting: 'Quitter le meeting',
    muteMic: 'Rendre le microphone muet',
    unmuteMic: 'Rétablir le son du microphone',
    activateMic: 'Activer le microphone',
    startCam: 'Allumer la caméra',
    stopCam: 'Éteindre la caméra',
    activateCam: 'Activer la caméra',
    meetingInfo: 'Informations du meeting',
    sharedApplications: 'Shared Applications',
    menu: 'Menu',

    startRecording: "Commencer l'enregistrement",
    stopRecording: "Arrêter l'enregistrement",
    muteParticipant: 'Couper le son des participants',
    kickParticipant: 'Retirer le participant',
    msgRemoteMute: 'Vous avez été mis en sourdine par un conseiller.',
    msgKick: 'Vous avez été retiré du meeting par un conseiller.',
    participantActions: 'Désactiver / Retirer le participant',
    noParticipants: 'Aucun autre participant au meeting',
    mute: 'Rendre muet',
    kick: 'Retirer',
    msgReallyLeaveMeeting: 'Êtes-vous sûr de vouloir quitter la réunion?',
    completeMeeting: 'Terminer le meeting',
    completeMeetingConfirmation: 'Voulez-vous vraiment terminer le meeting?',
    msgMeetingCompleted: 'Le meeting est terminé',

    share: 'Partager',
    standaloneApplications: 'Standalone Applications',
    link: 'Lien',

    bitrate: 'Bitrate',
    framerate: 'Framerate',
    packetLoss: 'Packet loss',

    // Meeting Manager Standard
    meetingTableDate: 'Date',
    meetingTableTitle: 'Titre',
    meetingTableDescription: 'Description',
    meetingTableParticipants: 'Participants',

    // Webinars
    webinars: 'Webinaires',
    participants: 'Participants',
    requestedSpeakerPermission: 'Permission de parler demandée',
    leaveWebinar: 'Quitter le webinaire',

    // Recordings
    recordings: 'Enregistrements',
    download: 'Télécharger',
    deleteRecording: "Supprimer l'enregistrement",
    deleteRecordingConfirmation:
        'Voulez-vous vraiment supprimer cet enregistrement?',
    unsavedRecordings:
        'De nouveaux enregistrements sont disponibles au téléchargement. Veuillez les télécharger et les supprimer de la liste.',
    noRecordings: 'Aucun enregistrement disponible',
    recordingsNotice:
        'En raison des limites de mémoire du navigateur, vous devez conserver cette liste aussi petite que possible et supprimer les enregistrements de la liste après le téléchargement.',
    markedForDelete: 'Sera supprimé dans {{hours}} heures',
    markedForDelete1Hour: "Sera supprimé dans moins d'une heure.",
    needsDownload: 'Veuillez télécharger',
    confirmRecordingTitle: "Attendez la confirmation de l'enregistrement",
    confirmRecordingMessage:
        "On demande au participant si l'enregistrement peut être démarré. Veuillez attendre que les participants l'aient confirmé.",
    confirmRecordingResultAsk: 'Demande aux participants',
    confirmRecordingResultRejected: 'Rejeté',
    confirmRecordingResultConfirmed: 'Confirmé',
    restartConfirmation: 'Demander à nouveau une confirmation',

    // adHoc
    adHoc: 'Ad hoc',
    scheduled: 'Programmé',
    meeting: 'Meeting',
    phoneConsultation: 'Conseil téléphonique',
    meetingId: 'ID du meeting',
    consultationId: 'ID de la consultation',
    adHocMeeting: 'Réunion ad hoc',
    adHocPhoneConsultation: 'Consultation téléphonique ad hoc',
    newMeeting: 'Nouveau meeting',
    newPhoneConsultation: 'Nouvelle consultation téléphonique',

    // Errors

    connectionError: 'La connexion au serveur a échoué.',
    serverError: 'Oops! Une erreur est survenue.',
    accessDenied: 'Accès refusé!',
    notAuthorized: "Vous n'êtes pas autorisé!",
    authInvalidIp: 'IP invalide.',
    authInvalidUser: 'Utilisateur invalide.',
    authInvalidCaptcha: 'Captcha invalide.',
    authInvalidCredentials: "Données d'identification invalides.",
    serviceNotFound: "Ce service n'existe pas.",
    invalidRoleError:
        "Seuls les utilisateurs avec le rôle 'adviser' sont autorisés à utiliser l'application Adviser.",
    autoRecordingError:
        "L'enregistrement automatique n'a pas pu démarrer, vous n'êtes pas autorisé à rejoindre cette réunion sans enregistrement.",

    alcErrorDefault: 'Erreur alc',
    alcErrorMeetingDoesNotExist: "Le meeting est déjà terminé ou n'existe pas.",
    alcErrorMeetingConnectionLost:
        'Connexion au serveur de meeting perdue. Veuillez réessayer.',
    alcErrorMeetingFull:
        'Le nombre maximum de participants au meeting a été dépassé.',
    alcErrorWebinarAdviserFull:
        'Le nombre maximum de conseillers dans ce webinaire est atteint.',
    alcErrorAdviserIdMismatch:
        "L'invitation que vous avez utilisée n'a pas été créée pour votre utilisateur.",

    webRtcErrorDefault: 'Erreur WebRTC',
    webRtcErrorNotFound:
        'Aucune caméra / microphone détecté. Veuillez vérifier si votre caméra / microphone est branché et cliquez sur Actualiser.',
    webRtcErrorNotAllowed:
        "Veuillez autoriser l'accès à votre caméra / microphone dans les paramètres du navigateur. Activez ensuite Audio / Audio + Vidéo.",
    webRtcErrorNotReadable:
        'Votre caméra est utilisée par une autre application. Veuillez fermer cette application ou utilisez un autre périphérique.',
    webRtcErrorOverconstrained:
        "Votre caméra / microphone enregistré n'est pas disponible. Veuillez vérifier que votre caméra / microphone est branché et cliquer sur Actualiser ou sélectionner un autre périphérique.",
    webRtcErrorConnectionTimeout:
        "Délai d'attente lors de la connexion au serveur de meeting",
    webRtcErrorRecordingStart: "L'enregistrement n'a pas pu démarrer.",
    webRtcErrorRecordingStream: 'Un flux ne peut pas être enregistré.',
    webRtcErrorGetRecordings:
        "Les enregistrements n'ont pas pu être récupérés.",

    webRtcBrowserNotSupported:
        "Votre navigateur n'est pas supporté. Veuillez utiliser une version récente de Chrome / Firefox ou Safari sur iOS.",
    webRtcBrowserOutOfDate:
        "Votre version de {{browser}} est obsolète et n'est plus prise en charge. Veuillez mettre à jour votre navigateur s'il vous plait.",
    webRtcBrowserNonSafariIos:
        "Malheureusement, {{browser}} n'est pas pris en charge sur iOS. Veuillez essayer une version récente de Safari.",
    webRtcBrowserLegacyEdge:
        "Vous utilisez Edge Legacy. Cette version est obsolète et n'est plus prise en charge. Nous vous suggérons d'utiliser le nouveau Edge que vous pouvez télécharger {{link}}.",
    webRtcBrowserIe:
        "Internet Explorer n'est pas pris en charge. Nous vous suggérons d'utiliser le nouveau navigateur Edge que vous pouvez télécharger {{link}}.",
};
