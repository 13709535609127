import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';

import { MEETING_TYPES } from '../../constants/constants';
import { goToMeeting, createAdHocMeeting } from './actions';
import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import MeetingsManager from './MeetingsManager';

// TODO error handling
export class AdHocMeeting extends React.Component {
    constructor(props) {
        super(props);

        let title = '';
        if (props.type === MEETING_TYPES.MEETING) {
            title = getTranslatedString(props.language, 'meeting');
        } else if (props.type === MEETING_TYPES.PHONE_CONSULTING) {
            title = getTranslatedString(props.language, 'phoneConsultation');
        }

        this.state = { title: title };
        this.handleTitleInputChange = this.handleTitleInputChange.bind(this);
        this.handleCreateClick = this.handleCreateClick.bind(this);
        this.handleJoinClick = this.handleJoinClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.type !== this.props.type) {
            let title = '';
            if (this.props.type === MEETING_TYPES.MEETING) {
                title = getTranslatedString(this.props.language, 'meeting');
            } else if (this.props.type === MEETING_TYPES.PHONE_CONSULTING) {
                title = getTranslatedString(
                    this.props.language,
                    'phoneConsultation'
                );
            }
            this.setState({ title: title });
        }
    }

    handleTitleInputChange(e) {
        this.setState({ title: e.target.value });
    }

    handleCreateClick(e) {
        this.props.createAdHocMeeting(this.state.title, this.props.type);
    }

    handleJoinClick(e) {
        this.props.goToMeeting(this.props.adHocMeetingInfo._id);
    }

    render() {
        let headerText = '';
        let createText = '';
        let idText = '';
        if (this.props.type === MEETING_TYPES.MEETING) {
            headerText = getTranslatedString(
                this.props.language,
                'adHocMeeting'
            );
            createText = getTranslatedString(this.props.language, 'newMeeting');
            idText = getTranslatedString(this.props.language, 'meetingId');
        } else if (this.props.type === MEETING_TYPES.PHONE_CONSULTING) {
            headerText = getTranslatedString(
                this.props.language,
                'adHocPhoneConsultation'
            );
            createText = getTranslatedString(
                this.props.language,
                'newPhoneConsultation'
            );
            idText = getTranslatedString(this.props.language, 'consultationId');
        }

        let content;
        if (!this.props.adHocMeetingInfo) {
            content = (
                <React.Fragment>
                    <div className="p-col-12" style={{ paddingTop: '16px' }}>
                        <div className="p-col-12">
                            <span className="md-inputfield">
                                <InputText
                                    className="form-input"
                                    value={this.state.title}
                                    name="title"
                                    onChange={this.handleTitleInputChange}
                                    disabled={this.props.adHocIsLoading}
                                />
                                <label>
                                    <TranslatedString id="meetingTableTitle" />
                                </label>
                            </span>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <Button
                            type="button"
                            label={createText}
                            icon="pi-md-create"
                            onClick={this.handleCreateClick}
                            disabled={this.props.adHocIsLoading}
                        />
                    </div>
                    <div className="p-col-12" style={{ display: 'none' }}>
                        <MeetingsManager type={MEETING_TYPES.MEETING} />
                    </div>
                </React.Fragment>
            );
        } else {
            content = (
                <React.Fragment>
                    <div className="p-col-12">
                        <h3>{idText}:</h3>
                        <h1 style={{ letterSpacing: '8px' }}>
                            {this.props.adHocMeetingInfo.shortId}
                        </h1>
                    </div>
                    <div className="p-col-12">
                        <Button
                            type="button"
                            label={getTranslatedString(
                                this.props.language,
                                'join'
                            )}
                            icon="pi-md-video-library"
                            onClick={this.handleJoinClick}
                        />
                    </div>
                </React.Fragment>
            );
        }

        return (
            <div className="layout-content">
                <div className="p-grid dashboard">
                    <div className="p-col-12">
                        <Card title={headerText}>{content}</Card>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        adHocIsLoading: state.meetingsManager.adHocIsLoading,
        adHocMeetingInfo: state.meetingsManager.adHocMeetingInfo,
        adHocErrorMessage: state.meetingsManager.adHocErrorMessage,
        language: state.base.i18n.language,
    };
};

const mapDispatchToProps = {
    goToMeeting,
    createAdHocMeeting,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdHocMeeting);
