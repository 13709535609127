export default {
    HOME: '/',
    SERVICE_LOGIN: '/:serviceId',
    DASHBOARD: '/:serviceId',
    MEETINGS: '/:serviceId/meetings',
    MEETINGS_NEW: '/:serviceId/meetings/adHoc',
    MEETINGS_EXECUTE: '/:serviceId/meetings/:id',
    MEETINGS_EXECUTE_STANDALONE: '/:serviceId/meetings/standalone/:id',
    WEBINARS: '/:serviceId/webinars',
    PHONE_CONSULTING: '/:serviceId/phoneConsulting/',
    PHONE_CONSULTING_NEW: '/:serviceId/phoneConsulting/adHoc',
    DOCUMENTS: '/:serviceId/documents',
    RECORDINGS: '/:serviceId/recordings',
};
