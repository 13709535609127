import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter, matchPath } from 'react-router';
import throttle from 'lodash/throttle';
import { Dialog } from 'primereact/dialog';
import Helmet from 'react-helmet';

import SerenityLayout from './features/layout/SerenityLayout';
import './serenity/ripple';

import Routes from './constants/routes';
import {
    MEETING_TYPES,
    RESPONSIVE_MODES,
    WEBRTC_MODES,
} from './constants/constants';
import LoginPage from './features/auth/LoginPage';
import ServiceInputPage from './features/auth/ServiceInputPage';
import InitErrorPage from './features/auth/InitErrorPage';
import Meeting from './features/meetings/Meeting';
import MeetingStandalone from './features/meetings/MeetingStandalone';
import Dashboard from './features/dashboard/Dashboard';
import Error404 from './features/base/error404/Error404';
import TranslatedString from './features/base/i18n/TranslatedString';
import DeviceSettings from './features/deviceSettings/DeviceSettings';
import { checkReLogin } from './features/auth/actions';
import { routeWithServiceId } from './features/base/util/helpers';
import { LoadingScreen } from './features/base/loadingScreen/LoadingScreen';
import MeetingsManager from './features/meetingsManager/MeetingsManager';
import Recordings from './features/recordings/Recordings';
import { adiaLiveSettings } from './features/base/util/adiaLiveSettings';
import CustomGrowl from './features/base/messages/CustomGrowl';
import EditAccountDialog from './features/base/editAccount/EditAccountDialog';
import { changeResponsiveMode } from './features/base/common/actions';
import { Config } from './config/Config';
import AdHocMeeting from './features/meetingsManager/AdHocMeeting';
import DocumentsManager from './features/documentsManager/DocumentsManager';

class Adviser extends Component {
    constructor(props) {
        super(props);

        this.handleWindowResizeEvent = this.handleWindowResizeEvent.bind(this);
        this.handleWindowResizeEventThrottled = throttle(
            this.handleWindowResizeEvent,
            100
        );
        this.state = {
            themeLoaded: false,
        };
    }

    componentDidMount() {
        this.props.checkReLogin();

        window.addEventListener(
            'resize',
            this.handleWindowResizeEventThrottled
        );
        this.handleWindowResizeEvent();

        const themeName = Config.theme;
        if (!themeName) {
            import('./css/themes/Theme_default.scss').then(() =>
                this.setState({ themeLoaded: true })
            );
        } else {
            import(`./css/themes/Theme_${themeName}.scss`).then(() =>
                this.setState({ themeLoaded: true })
            );
        }
    }

    componentWillUnmount() {
        window.removeEventListener(
            'resize',
            this.handleWindowResizeEventThrottled
        );
    }

    handleWindowResizeEvent() {
        let newWidth = document.body.clientWidth;
        let newResponsiveMode = 0;
        if (newWidth < RESPONSIVE_MODES.MEDIUM) {
            newResponsiveMode = RESPONSIVE_MODES.SMALL;
        } else if (newWidth < RESPONSIVE_MODES.FULL) {
            newResponsiveMode = RESPONSIVE_MODES.MEDIUM;
        } else {
            newResponsiveMode = RESPONSIVE_MODES.FULL;
        }

        if (this.props.responsiveMode !== newResponsiveMode) {
            this.props.changeResponsiveMode(newResponsiveMode);
        }
    }

    render() {
        let content;
        if (!this.state.themeLoaded) {
            content = null;
        } else if (this.props.reLoginChecked && this.props.authenticated) {
            if (this.props.initFailed) {
                content = <InitErrorPage />;
            } else {
                content = this.renderContent();
            }
        } else {
            if (this.props.reLoginChecked && !this.props.authenticated) {
                content = this.renderLogin();
            } else {
                content = <LoadingScreen />;
            }
        }
        return (
            <React.Fragment>
                <Helmet>
                    <title>{Config.documentTitle}</title>
                    <link rel="shortcut icon" href={Config.favicon} />
                </Helmet>
                {content}
            </React.Fragment>
        );
    }

    renderLogin() {
        return (
            <Switch>
                {/*redirect all service routes to the service login  //TODO generate automatically */}
                <Route
                    path={[
                        Routes.SERVICE_LOGIN,
                        Routes.DASHBOARD,
                        Routes.MEETINGS,
                        Routes.MEETINGS_EXECUTE,
                        Routes.MEETINGS_EXECUTE_STANDALONE,
                        Routes.WEBINARS,
                        Routes.PHONE_CONSULTING,
                        Routes.MEETINGS_NEW,
                        Routes.PHONE_CONSULTING_NEW,
                        Routes.RECORDINGS,
                        Routes.DOCUMENTS,
                    ]}
                    exact
                    render={(props) => (
                        <LoginPage serviceId={props.match.params.serviceId} />
                    )}
                />
                {/*redirect the home route and all other unknown routes to the service input page*/}
                <Route path={Routes.HOME} exact component={ServiceInputPage} />
                <Route render={() => <Redirect to={Routes.HOME} />} />
            </Switch>
        );
    }

    renderContent() {
        const serviceSettings = adiaLiveSettings.flagsToSettings(
            this.props.flags
        );

        const documentsManagerEnabled =
            this.props.nepatecTemplatesActive && this.props.meetingsManagerUrl;

        const menuModel = [
            {
                label: <TranslatedString id={'dashboard'} />,
                icon: 'dashboard',
                to: routeWithServiceId(Routes.DASHBOARD, this.props.service),
            },
        ];

        if (serviceSettings.meetings) {
            if (this.props.adHocMeetingEnabled) {
                menuModel.push({
                    label: <TranslatedString id={'meetings'} />,
                    icon: 'people',
                    items: [
                        {
                            label: <TranslatedString id={'adHoc'} />,
                            icon: 'create',
                            to: routeWithServiceId(
                                Routes.MEETINGS_NEW,
                                this.props.service
                            ),
                        },
                        {
                            label: <TranslatedString id={'scheduled'} />,
                            icon: 'event',
                            to: routeWithServiceId(
                                Routes.MEETINGS,
                                this.props.service
                            ),
                        },
                    ],
                });
            } else {
                menuModel.push({
                    label: <TranslatedString id={'meetings'} />,
                    icon: 'people',
                    to: routeWithServiceId(Routes.MEETINGS, this.props.service),
                });
            }
        }

        if (serviceSettings.webinar) {
            menuModel.push({
                label: <TranslatedString id={'webinars'} />,
                icon: 'school',
                to: routeWithServiceId(Routes.WEBINARS, this.props.service),
            });
        }

        if (serviceSettings.phoneConsulting) {
            if (this.props.adHocPhoneConsultingEnabled) {
                menuModel.push({
                    label: <TranslatedString id={'phoneConsulting'} />,
                    icon: 'perm_phone_msg',
                    items: [
                        {
                            label: <TranslatedString id={'adHoc'} />,
                            icon: 'create',
                            to: routeWithServiceId(
                                Routes.PHONE_CONSULTING_NEW,
                                this.props.service
                            ),
                        },
                        {
                            label: <TranslatedString id={'scheduled'} />,
                            icon: 'event',
                            to: routeWithServiceId(
                                Routes.PHONE_CONSULTING,
                                this.props.service
                            ),
                        },
                    ],
                });
            } else {
                menuModel.push({
                    label: <TranslatedString id={'phoneConsulting'} />,
                    icon: 'perm_phone_msg',
                    to: routeWithServiceId(
                        Routes.PHONE_CONSULTING,
                        this.props.service
                    ),
                });
            }
        }

        if (documentsManagerEnabled) {
            menuModel.push({
                label: <TranslatedString id={'documents'} />,
                icon: 'description',
                items: [
                    {
                        label: <TranslatedString id={'signatureTemplates'} />,
                        icon: 'edit',
                        to: routeWithServiceId(
                            Routes.DOCUMENTS,
                            this.props.service
                        ),
                    },
                ],
            });
        }

        if (this.props.localRecordingEnabled) {
            menuModel.push({
                label: <TranslatedString id={'recordings'} />,
                icon: 'video_library',
                to: routeWithServiceId(Routes.RECORDINGS, this.props.service),
            });
        }

        const dashboardRoute = (
            <Route
                path={Routes.DASHBOARD}
                exact
                render={() => {
                    return (
                        <SerenityLayout
                            menuModel={menuModel}
                            content={<Dashboard />}
                            showTopbar={true}
                            showBreadcrumb={Config.showBreadcrumb}
                            showFooter={false}
                        />
                    );
                }}
            />
        );

        const meetingsManagerRoute = serviceSettings.meetings ? (
            <Route
                path={Routes.MEETINGS}
                exact
                render={() => {
                    return (
                        <SerenityLayout
                            menuModel={menuModel}
                            content={
                                <MeetingsManager type={MEETING_TYPES.MEETING} />
                            }
                            showTopbar={true}
                            showBreadcrumb={Config.showBreadcrumb}
                            showFooter={false}
                        />
                    );
                }}
            />
        ) : null;

        const meetingsAdHocRoute =
            serviceSettings.meetings && this.props.adHocMeetingEnabled ? (
                <Route
                    path={Routes.MEETINGS_NEW}
                    exact
                    render={() => {
                        return (
                            <SerenityLayout
                                menuModel={menuModel}
                                content={
                                    <AdHocMeeting
                                        type={MEETING_TYPES.MEETING}
                                    />
                                }
                                showTopbar={true}
                                showBreadcrumb={Config.showBreadcrumb}
                                showFooter={false}
                            />
                        );
                    }}
                />
            ) : null;

        const meetingsExecuteRoute = serviceSettings.meetings ? (
            <Route
                path={Routes.MEETINGS_EXECUTE}
                exact
                render={(props) => {
                    return <Meeting {...props} />;
                }}
            />
        ) : null;

        const meetingsExecuteStandaloneRoute = serviceSettings.meetings ? (
            <Route
                path={Routes.MEETINGS_EXECUTE_STANDALONE}
                exact
                render={(props) => {
                    return <MeetingStandalone {...props} />;
                }}
            />
        ) : null;

        const webinarsManagerRoute = serviceSettings.webinar ? (
            <Route
                path={Routes.WEBINARS}
                exact
                render={() => {
                    return (
                        <SerenityLayout
                            menuModel={menuModel}
                            content={
                                <MeetingsManager type={MEETING_TYPES.WEBINAR} />
                            }
                            showTopbar={true}
                            showBreadcrumb={Config.showBreadcrumb}
                            showFooter={false}
                        />
                    );
                }}
            />
        ) : null;

        const phoneConsultingManagerRoute = serviceSettings.phoneConsulting ? (
            <Route
                path={Routes.PHONE_CONSULTING}
                exact
                render={() => {
                    return (
                        <SerenityLayout
                            menuModel={menuModel}
                            content={
                                <MeetingsManager
                                    type={MEETING_TYPES.PHONE_CONSULTING}
                                />
                            }
                            showTopbar={true}
                            showBreadcrumb={Config.showBreadcrumb}
                            showFooter={false}
                        />
                    );
                }}
            />
        ) : null;

        const phoneConsultingAdHocRoute =
            serviceSettings.phoneConsulting &&
            this.props.adHocPhoneConsultingEnabled ? (
                <Route
                    path={Routes.PHONE_CONSULTING_NEW}
                    exact
                    render={() => {
                        return (
                            <SerenityLayout
                                menuModel={menuModel}
                                content={
                                    <AdHocMeeting
                                        type={MEETING_TYPES.PHONE_CONSULTING}
                                    />
                                }
                                showTopbar={true}
                                showBreadcrumb={Config.showBreadcrumb}
                                showFooter={false}
                            />
                        );
                    }}
                />
            ) : null;

        const documentsManagerRoute = documentsManagerEnabled ? (
            <Route
                path={Routes.DOCUMENTS}
                exact
                render={(props) => {
                    return (
                        <SerenityLayout
                            menuModel={menuModel}
                            content={<DocumentsManager />}
                            showTopbar={true}
                            showBreadcrumb={Config.showBreadcrumb}
                            showFooter={false}
                        />
                    );
                }}
            />
        ) : null;

        const recordingsRoute = this.props.localRecordingEnabled ? (
            <Route
                path={Routes.RECORDINGS}
                exact
                render={(props) => {
                    return (
                        <SerenityLayout
                            menuModel={menuModel}
                            content={
                                this.props.webRtcInitialized ? (
                                    <Recordings />
                                ) : (
                                    <LoadingScreen />
                                )
                            }
                            showTopbar={true}
                            showBreadcrumb={Config.showBreadcrumb}
                            showFooter={false}
                        />
                    );
                }}
            />
        ) : null;

        const reconnectingDialog = (
            <Dialog
                header={<TranslatedString id="reconnectingHeader" />}
                visible={
                    this.props.alcReconnecting || this.props.webRtcReconnecting
                }
                closable={false}
                onHide={() => {}}
                baseZIndex={999999}
            >
                <div className="p-grid">
                    <div className="p-col-12">
                        <TranslatedString id="reconnectingMessage" />
                    </div>
                    <div className="p-col-12 reconnecting-spinner-container">
                        <LoadingScreen />
                    </div>
                </div>
            </Dialog>
        );

        // topbar is hidden if route is on meetings_execute(_standalone) path
        const topbarHidden =
            !!matchPath(this.props.pathname, Routes.MEETINGS_EXECUTE) ||
            !!matchPath(
                this.props.pathname,
                Routes.MEETINGS_EXECUTE_STANDALONE
            );

        return (
            <React.Fragment>
                {this.props.alcInitialized &&
                this.props.alcAuthenticated &&
                this.props.webRtcInitialized ? (
                    <React.Fragment>
                        <Switch>
                            {dashboardRoute}
                            {meetingsManagerRoute}
                            {meetingsAdHocRoute}
                            {webinarsManagerRoute}
                            {phoneConsultingManagerRoute}
                            {phoneConsultingAdHocRoute}
                            {meetingsExecuteRoute}
                            {meetingsExecuteStandaloneRoute}
                            {documentsManagerRoute}
                            {recordingsRoute}
                            <Route render={() => <Error404 />} />
                        </Switch>
                        {this.props.settingsPanelShown && <DeviceSettings />}
                        {reconnectingDialog}
                        <EditAccountDialog />
                    </React.Fragment>
                ) : (
                    <LoadingScreen />
                )}
                <CustomGrowl topbarHidden={topbarHidden} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const webRtcMode = state.auth.publicServiceInfo
        ? state.auth.publicServiceInfo.webRtcMode
        : undefined;
    const localRecordingEnabled =
        (webRtcMode === WEBRTC_MODES.LIVESWITCH_P2P ||
            webRtcMode === WEBRTC_MODES.ADIA_P2P) &&
        state.meetings.webRtcFlags &&
        state.meetings.webRtcFlags.canRecordMedia &&
        state.auth.publicServiceInfo &&
        (!state.auth.publicServiceInfo.meetingsSettings.hasOwnProperty(
            'enableRecording'
        ) ||
            state.auth.publicServiceInfo.meetingsSettings.enableRecording);
    let meetingsManagerUrl = null;
    if (
        state.auth.publicServiceInfo &&
        state.auth.publicServiceInfo.meetingsSettings
    ) {
        meetingsManagerUrl =
            state.auth.publicServiceInfo.meetingsSettings.meetingsManagerUrl;
    }

    return {
        flags: state.auth.publicServiceInfo
            ? state.auth.publicServiceInfo.flags
            : null,
        adHocMeetingEnabled:
            state.auth.publicServiceInfo &&
            state.auth.publicServiceInfo.meetingsSettings &&
            state.auth.publicServiceInfo.meetingsSettings.enableAdHoc,
        adHocPhoneConsultingEnabled:
            state.auth.publicServiceInfo &&
            state.auth.publicServiceInfo.phoneConsultingSettings &&
            state.auth.publicServiceInfo.phoneConsultingSettings.enableAdHoc,
        reLoginChecked: state.auth.reLoginChecked,
        authenticated: state.auth.authenticated,
        service: state.auth.service,
        alcInitialized: state.meetings.alcInitialized,
        alcAuthenticated: state.meetings.alcAuthenticated,
        alcReconnecting: state.meetings.alcReconnecting,
        webRtcInitialized: state.meetings.webRtcInitialized,
        webRtcReconnecting: state.meetings.webRtcReconnecting,
        settingsPanelShown: state.deviceSettings.settingsPanelShown,
        responsiveMode: state.base.common.responsiveMode,
        pathname: ownProps.location.pathname,
        localRecordingEnabled,
        initFailed: state.auth.initFailed,
        meetingsManagerUrl,
        nepatecTemplatesActive:
            state.auth.publicServiceInfo &&
            state.auth.publicServiceInfo.nepatecSettings &&
            state.auth.publicServiceInfo.nepatecSettings.active &&
            state.auth.publicServiceInfo.nepatecSettings.signatureTemplates,
    };
};

const mapDispatchToProps = {
    checkReLogin,
    changeResponsiveMode,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Adviser)
);
