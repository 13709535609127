import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { createAlcMiddleware } from './middleware/alcMiddleware';
import { createWebRtcLayerMiddleware } from './middleware/webRtcLayerMiddleware';
import { createIFrameMiddleware } from './middleware/iFrameMiddleware';
import { createAuthMiddleware } from './middleware/authMiddleware';
import { createHelperMiddleware } from './middleware/helperMiddleware';

import './css/index.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';

import rootReducer from './rootReducer';
import al5ApiService from './services/al5ApiService';
import rootSaga from './rootSaga';

import Adviser from './Adviser';

import { getPreloadLanguage } from './features/base/util/helpers';

const authMiddleware = createAuthMiddleware();
const alcMiddleware = createAlcMiddleware();
const webRtcLayerMiddleware = createWebRtcLayerMiddleware();
const iFrameMiddleware = createIFrameMiddleware();
const helperMiddleware = createHelperMiddleware();
const sagaMiddleware = createSagaMiddleware();

const history = createBrowserHistory();
const language = getPreloadLanguage();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer(history),
    {
        base: {
            i18n: {
                language: language
            }
        }
    },
    composeEnhancers(
        applyMiddleware(
            routerMiddleware(history),
            thunk.withExtraArgument(al5ApiService),
            authMiddleware,
            alcMiddleware,
            webRtcLayerMiddleware,
            iFrameMiddleware,
            helperMiddleware,
            sagaMiddleware
        )
    )
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Adviser />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
