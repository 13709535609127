import * as types from './actionTypes';
import { hasLanguage, languages } from './translations';
import { LOGIN_SUCCESS, RE_LOGIN_SUCCESS } from '../../auth/actionTypes';

export const initialState = {
    language: languages.ENGLISH
};

export default function i18n(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_LANGUAGE: {
            return {
                ...state,
                language: action.language
            };
        }
        case LOGIN_SUCCESS:
        case RE_LOGIN_SUCCESS: {
            return {
                ...state,
                language: hasLanguage(action.user.locale)
                    ? action.user.locale
                    : state.language
            };
        }
        default:
            return state;
    }
}
