import { defaultConfig }  from './Config_default';

/*
import Config_CONFIGNAME.js
if no CONFIGNAME is defined, use Config_local.js
  */
let config_name = process.env.REACT_APP_CONFIG;

if (!config_name) {
    config_name = 'local';
}

let config = null;

try {
    config = require(`./Config_${config_name}.js`).Config;
} catch (err) {
    throw new Error("Can't find config file Config_" + config_name + '.js !');
}

export const Config = Object.assign(defaultConfig, config);