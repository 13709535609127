import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';

import {
    sharedApplicationsIFrameNewRef,
    sharedApplicationsIFrameLoad,
    switchOwnSharedApplication,
    alcSetCurrentApp,
    alcSetEditPermissions,
    hideSharedApplications,
} from './actions';
import { logout } from '../auth/actions';
import { getTranslatedString } from '../base/i18n/translations';
import {
    SA_APPLICATIONS,
    SHOWBOARD_TOOLBAR_HEIGHT,
} from '../../constants/constants';
import { Config } from '../../config/Config';

export class SharedApplicationsPanel extends Component {
    constructor(props) {
        super(props);

        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleShowClick = this.handleShowClick.bind(this);
        this.handleEditModeChange = this.handleEditModeChange.bind(this);
        this.handleResize = this.handleResize.bind(this);

        this.containerRef = React.createRef();
        this.headerRef = React.createRef();
        this.iFrameRef = React.createRef();

        this.state = {
            bodyHeight: 0,
            bodyWidth: 0,
        };

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.props.sharedApplicationsIFrameNewRef(this.iFrameRef);

        /* content sizing */
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.sharedApplicationsShown !==
                this.props.sharedApplicationsShown ||
            prevProps.controlsVisible !== this.props.controlsVisible ||
            prevProps.saIFrameReady !== this.props.saIFrameReady
        ) {
            this.handleResize();
        }
    }

    componentWillUnmount() {
        this.props.sharedApplicationsIFrameNewRef(null);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        if (this.props.sharedApplicationsShown || this.props.standalone) {
            const mainContainerRect = this.containerRef.current.getBoundingClientRect();
            const headerContainerRect = this.headerRef.current.getBoundingClientRect();

            let bodyTargetHeight =
                mainContainerRect.height - headerContainerRect.height;
            let bodyTargetWidth =
                ((bodyTargetHeight - SHOWBOARD_TOOLBAR_HEIGHT) / 9) * 16;
            if (bodyTargetWidth > mainContainerRect.width) {
                bodyTargetWidth = mainContainerRect.width;
                bodyTargetHeight =
                    (bodyTargetWidth / 16) * 9 + SHOWBOARD_TOOLBAR_HEIGHT;
            }
            this.setState({
                bodyHeight: bodyTargetHeight,
                bodyWidth: bodyTargetWidth,
            });
        }
    }

    handleTabChange(e) {
        this.props.switchOwnSharedApplication(e.value.appId);
    }

    handleEditModeChange(e) {
        const currentShownApp = this.props.ownApplicationId
            ? this.props.ownApplicationId
            : this.props.meetingInfo.currentApp;
        this.props.meetingParticipants.forEach((participant) => {
            if (!participant.isAdviser) {
                const hasEditMode = participant.editPermissions
                    ? participant.editPermissions.includes(currentShownApp)
                    : false;
                const shouldHaveEditMode = e.value.includes(participant.id);
                // if participant currently has edit permission for currently shown app, and isn't in new list, remove it
                if (hasEditMode && !shouldHaveEditMode) {
                    const newEditPermissions = [...participant.editPermissions];
                    const index = newEditPermissions.indexOf(currentShownApp);
                    if (index !== -1) {
                        newEditPermissions.splice(index, 1);
                    }
                    this.props.alcSetEditPermissions(
                        participant.id,
                        newEditPermissions
                    );
                    // if participant doesn't have edit permission but is in new list, add it
                } else if (!hasEditMode && shouldHaveEditMode) {
                    const newEditPermissions = participant.editPermissions
                        ? [...participant.editPermissions]
                        : [];
                    newEditPermissions.push(currentShownApp);
                    this.props.alcSetEditPermissions(
                        participant.id,
                        newEditPermissions
                    );
                }
            }
        });
    }

    handleShowClick() {
        // if currentApp active and currently on current app panel -> disable show mode
        if (this.isShowActiveOnCurrentPanel()) {
            this.props.alcSetCurrentApp('');
        } else {
            this.props.alcSetCurrentApp(this.props.ownApplicationId);
        }
    }

    isShowActiveOnCurrentPanel() {
        return (
            this.props.meetingInfo.currentApp &&
            (!this.props.ownApplicationId ||
                this.props.meetingInfo.currentApp ===
                    this.props.ownApplicationId)
        );
    }

    render() {
        const agendaItem = {
            label: getTranslatedString(this.props.language, 'agenda'),
            icon: 'pi-md-format-list-bulleted',
            appId: SA_APPLICATIONS.AGENDA,
            className: classNames({
                'active-sa-tab':
                    this.props.meetingInfo.currentApp ===
                    SA_APPLICATIONS.AGENDA,
            }),
        };

        const showboardItem = {
            label: getTranslatedString(this.props.language, 'showboard'),
            icon: 'pi-md-laptop',
            appId: SA_APPLICATIONS.SHOWBOARD,
            className: classNames({
                'active-sa-tab':
                    this.props.meetingInfo.currentApp ===
                    SA_APPLICATIONS.SHOWBOARD,
            }),
        };

        const minutesItem = {
            label: getTranslatedString(this.props.language, 'minutes'),
            icon: 'pi-md-speaker-notes',
            appId: SA_APPLICATIONS.MINUTES,
            className: classNames({
                'active-sa-tab':
                    this.props.meetingInfo.currentApp ===
                    SA_APPLICATIONS.MINUTES,
            }),
        };

        const documentsItem = {
            label: getTranslatedString(this.props.language, 'documents'),
            icon: 'pi-md-laptop',
            appId: SA_APPLICATIONS.DOCUMENTS,
        };

        const chatItem = {
            label: getTranslatedString(this.props.language, 'chat'),
            icon: 'pi-md-speaker-notes',
            appId: SA_APPLICATIONS.CHAT,
        };

        const items = [];
        if (!Config.agendaDisabled) {
            items.push(agendaItem);
        }
        items.push(showboardItem);
        if (!Config.minutesDisabled) {
            items.push(minutesItem);
        }

        if (this.props.standalone) {
            items.push(documentsItem);
            items.push(chatItem);
        }

        const currentShownApp = this.props.ownApplicationId
            ? this.props.ownApplicationId
            : this.props.meetingInfo.currentApp;

        let activeItem = null;
        switch (currentShownApp) {
            case SA_APPLICATIONS.AGENDA: {
                activeItem = agendaItem;
                break;
            }
            case SA_APPLICATIONS.SHOWBOARD: {
                activeItem = showboardItem;
                break;
            }
            case SA_APPLICATIONS.MINUTES: {
                activeItem = minutesItem;
                break;
            }
            case SA_APPLICATIONS.DOCUMENTS: {
                activeItem = documentsItem;
                break;
            }
            case SA_APPLICATIONS.CHAT: {
                activeItem = chatItem;
                break;
            }
            default: {
                activeItem = agendaItem;
                if (Config.agendaDisabled) {
                    activeItem = showboardItem;
                }
                break;
            }
        }

        // get options {label; name, value: id} for each participant who isn't an adviser
        // get all non adviser participants who have edit mode on currently shown application
        const {
            editModeOptions,
            editModeValue,
        } = this.props.meetingParticipants.reduce(
            (result, currentElement) => {
                if (!currentElement.isAdviser) {
                    if (currentElement.info.name && currentElement.id) {
                        result.editModeOptions.push({
                            label: currentElement.info.name,
                            value: currentElement.id,
                        });

                        if (
                            currentElement.editPermissions &&
                            currentElement.editPermissions.includes(
                                currentShownApp
                            )
                        ) {
                            result.editModeValue.push(currentElement.id);
                        }
                    }
                }
                return result;
            },
            { editModeOptions: [], editModeValue: [] }
        );

        let sharedApplicationsUrl = this.props.sharedApplicationsUrl;
        if (this.props.saAuthToken) {
            if (sharedApplicationsUrl.indexOf('?') > -1) {
                sharedApplicationsUrl += '&jws=' + this.props.saAuthToken;
            } else {
                sharedApplicationsUrl += '?jws=' + this.props.saAuthToken;
            }
        }

        let closeIcon = null;
        if (this.props.meetingInfo && this.props.meetingInfo.currentApp) {
            closeIcon = <i className="pi-md-close close-icon disabled" />;
        } else {
            closeIcon = (
                <i
                    className="pi-md-close close-icon clickable"
                    onClick={this.props.hideSharedApplications}
                />
            );
        }

        return (
            <div
                className={classNames({
                    'sa-panel-container': true,
                    hidden: !(
                        this.props.saIFrameReady &&
                        (this.props.sharedApplicationsShown ||
                            this.props.standalone)
                    ),
                    controlsVisible: this.props.controlsVisible,
                    standalone: this.props.standalone,
                })}
                ref={this.containerRef}
            >
                <div className="sa-panel">
                    <div className="sa-panel-header" ref={this.headerRef}>
                        <TabMenu
                            model={items}
                            activeItem={activeItem}
                            onTabChange={this.handleTabChange}
                        />
                        <div className="sa-panel-controls">
                            {currentShownApp !== SA_APPLICATIONS.DOCUMENTS &&
                                currentShownApp !== SA_APPLICATIONS.CHAT && (
                                    <React.Fragment>
                                        <Button
                                            className={classNames({
                                                'p-button-success': this.isShowActiveOnCurrentPanel(),
                                            })}
                                            label={getTranslatedString(
                                                this.props.language,
                                                'show'
                                            )}
                                            icon="pi-md-remove-red-eye"
                                            onClick={this.handleShowClick}
                                        />
                                        <MultiSelect
                                            value={editModeValue}
                                            options={editModeOptions}
                                            onChange={this.handleEditModeChange}
                                            filter={false}
                                            placeholder={getTranslatedString(
                                                this.props.language,
                                                'editModePermissions'
                                            )}
                                            fixedPlaceholder={true}
                                            disabled={
                                                editModeOptions.length === 0
                                            }
                                            className={'edit-mode-select'}
                                        />
                                    </React.Fragment>
                                )}
                            {!this.props.standalone && closeIcon}
                            {this.props.standalone && (
                                <i
                                    className="pi-md-exit-to-app clickable"
                                    onClick={this.props.logout}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className="sa-panel-body"
                        style={{
                            height: this.state.bodyHeight,
                            width: this.state.bodyWidth,
                        }}
                    >
                        <iframe
                            className="shared-applications-iframe"
                            title="shared application iframe"
                            src={sharedApplicationsUrl}
                            ref={this.iFrameRef}
                            onLoad={this.props.sharedApplicationsIFrameLoad}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedApplicationsShown: state.meetings.sharedApplicationsShown,
        sharedApplicationsUrl:
            state.auth.publicServiceInfo.meetingsSettings.sharedApplicationsUrl,
        ownApplicationId: state.meetings.ownApplicationId,
        meetingInfo: state.meetings.meetingInfo,
        meetingParticipants: state.meetings.meetingParticipants,
        saAuthToken: state.meetings.saAuthToken,
        controlsVisible: state.meetings.controlsVisible,
        saIFrameReady: state.meetings.saIFrameReady,
        language: state.base.i18n.language,
    };
};

const mapDispatchToProps = {
    sharedApplicationsIFrameNewRef,
    sharedApplicationsIFrameLoad,
    switchOwnSharedApplication,
    alcSetCurrentApp,
    alcSetEditPermissions,
    hideSharedApplications,
    logout,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SharedApplicationsPanel);
