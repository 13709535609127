import { serviceUrlToAPIUrl } from '../features/base/util/helpers';
import { Config } from '../config/Config';
import { ERRORS } from '../constants/constants';

const apiUrl = serviceUrlToAPIUrl(Config.serviceUrl);

const al5ApiService = {
    /** LOGIN: user authorisation **/
    serviceLogin: async (loginData) => {
        const url = apiUrl + '/login/service/' + loginData.serviceId;

        let response = {
            user: undefined,
            token: undefined,
            service: undefined,
            error: undefined,
            captcha: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(loginData.credentials),
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.token = responseJson.token;
                    response.user = responseJson.user;
                } else {
                    response.error = responseJson;
                    response.captcha = responseJson.captcha;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /** PUBLIC: public routes (no authentication needed) **/
    getPublicServiceInfo: async (serviceId) => {
        const url = apiUrl + '/public/services/' + serviceId;

        let response = {
            publicServiceInfo: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'GET',
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.publicServiceInfo = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    updateServiceToken: async (serviceId, token) => {
        const url = apiUrl + '/api/v1/token/' + serviceId + '/update';

        let response = {
            token: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token,
                },
                method: 'GET',
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.token = responseJson.token;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /** MEETINGS: meeting administration **/
    getMeetings: async (
        serviceId,
        fields,
        sort,
        range,
        token,
        adviserId,
        stateId
    ) => {
        let params = '';

        if (fields && fields.length) {
            params += '?fields=';
            params += fields.join('%2C');
        }
        if (sort && sort.length) {
            params += (params ? '&' : '?') + 'sort=';
            params += sort.join('%2C');
        }
        if (adviserId) {
            params +=
                (params ? '&' : '?') + 'participants.adviserId=' + adviserId;
        }
        if (stateId) {
            params += (params ? '&' : '?') + 'state=' + stateId;
        }

        const url = apiUrl + '/api/v1/meetings/' + serviceId + params;
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': token,
        };

        if (
            range &&
            range.hasOwnProperty('startIndex') &&
            range.hasOwnProperty('endIndex')
        ) {
            headers['Range'] =
                'items=' + range.startIndex + '-' + range.endIndex;
        }

        let response = {
            meetings: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: headers,
                method: 'GET',
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200 || apiResponse.status === 206) {
                    response.meetings = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    updateUser: async (serviceId, userId, userData, token) => {
        const url = apiUrl + '/api/v1/users/' + serviceId + '/' + userId;

        let response = {
            user: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token,
                },
                method: 'PUT',
                body: JSON.stringify(userData),
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.user = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    updatePassword: async (serviceId, userId, passwordData, token) => {
        const url =
            apiUrl + '/api/v1/users/' + serviceId + '/' + userId + '/password';

        let response = {
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token,
                },
                method: 'PUT',
                body: JSON.stringify(passwordData),
            });
            try {
                if (apiResponse.status !== 200) {
                    response.error = await apiResponse.json();
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    createMeeting: async (serviceId, meetingData, token) => {
        const url = apiUrl + '/api/v1/meetings/' + serviceId;

        let response = {
            meeting: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token,
                },
                method: 'POST',
                body: JSON.stringify(meetingData),
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.meeting = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    getMeeting: async (serviceId, meetingId, token) => {
        const url = apiUrl + '/api/v1/meetings/' + serviceId + '/' + meetingId;

        let response = {
            meeting: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token,
                },
                method: 'GET',
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.meeting = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    getNepatecCredentials: async (serviceId, userId, token) => {
        const url =
            apiUrl +
            '/api/v1/users/' +
            serviceId +
            '/' +
            userId +
            '/nepatecCredentials';

        let response = {
            nepatecCredentials: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token,
                },
                method: 'GET',
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.nepatecCredentials = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    setNepatecCredentials: async (serviceId, userId, data, token) => {
        const url =
            apiUrl +
            '/api/v1/users/' +
            serviceId +
            '/' +
            userId +
            '/nepatecCredentials';

        let response = {
            nepatecCredentials: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token,
                },
                method: 'PUT',
                body: JSON.stringify(data),
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.nepatecCredentials = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },
};

export default al5ApiService;
