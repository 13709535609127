import * as types from './actionTypes';
import { MEETING_TYPES, SA_APPLICATIONS } from '../../constants/constants';
import { Config } from '../../config/Config';

// TODO: move alcInitialized, webRtcInitSuccess to different slice?

export const initialState = {
    alcInitialized: false,
    alcAuthenticated: false,
    alcMeetingJoined: false,
    alcReconnecting: false,
    webRtcInitialized: false,
    webRtcFlags: {},
    webRtcMeetingJoined: false,
    webRtcReconnecting: false,
    clientInfo: undefined,
    meetingInfo: undefined,
    ownVideoView: undefined,
    ownScreenShareView: undefined,
    videoViews: [],
    screenShareViews: [],
    meetingParticipants: [],
    mainVideoId: null,
    activeSpeakerId: null,
    screenShareOn: false,
    sharedApplicationsShown: false,
    ownApplicationId: null,
    documentsPanelShown: false,
    chatPanelShown: false,
    chatMessagePending: false,
    documentPending: false,
    saAuthToken: undefined,
    controlsVisible: false,
    menuVisible: false,
    infoPanelVisible: false,
    participantActionsVisible: false,
    completeMeetingDialogVisible: false,
    leaveMeetingDialogVisible: false,
    fullscreenActive: false,
    serverTimeDiff: 0,
    chatDocumentsIFrameInitialized: false,
    chatDocumentsIFrameReady: false,
    recordingConfirmationDialogVisible: false,
};

export default function meetings(state = initialState, action) {
    switch (action.type) {
        case types.WEBRTC_INIT_SUCCESS: {
            return {
                ...state,
                webRtcInitialized: true,
                webRtcFlags: action.webRtcFlags,
            };
        }
        case types.ALC_INITIALIZED: {
            return {
                ...initialState,
                alcInitialized: true,
                alcMeetingJoined: false,
                webRtcInitialized: state.webRtcInitialized,
                webRtcMeetingJoined: state.webRtcMeetingJoined,
                webRtcFlags: state.webRtcFlags,
            };
        }
        case types.ALC_RECONNECTING: {
            return {
                ...state,
                alcReconnecting: true,
            };
        }
        case types.ALC_SESSION_RESTORED: {
            return {
                ...state,
                alcReconnecting: false,
            };
        }
        case types.ALC_JOIN_MEETING_SUCCESS: {
            let sharedApplicationsShown = state.sharedApplicationsShown;
            if (action.meetingInfo.currentApp) {
                sharedApplicationsShown = true;
            }

            // TODO remove special webinar case once handled better
            let controlsVisible = state.controlsVisible;
            if (action.meetingInfo.type === MEETING_TYPES.WEBINAR) {
                controlsVisible = true;
            }

            return {
                ...state,
                alcMeetingJoined: true,
                meetingInfo: action.meetingInfo,
                sharedApplicationsShown,
                saAuthToken: action.saAuth ? action.saAuth.token : undefined,
                controlsVisible,
            };
        }
        case types.ALC_CLIENT_INFO_UPDATE: {
            return {
                ...state,
                alcAuthenticated: action.clientInfo.isAdviser,
                clientInfo: action.clientInfo,
            };
        }
        case types.WEBRTC_JOIN_SUCCESS: {
            return {
                ...state,
                webRtcMeetingJoined: true,
                ownVideoView: action.ownVideoView,
            };
        }
        case types.WEBRTC_USERJOINED: {
            if (action.videoView.type === 'remote') {
                const videoViews = state.videoViews.slice();
                const previousIndex = videoViews.findIndex(
                    (el) => el.userId === action.videoView.userId
                );
                if (previousIndex !== -1) {
                    videoViews[previousIndex] = action.videoView;
                } else {
                    videoViews.push(action.videoView);
                }
                const mainVideoId = determineMainVideoId(
                    state.activeSpeakerId,
                    state.meetingParticipants,
                    videoViews,
                    state.ownVideoView,
                    state.meetingInfo
                );
                return {
                    ...state,
                    videoViews: videoViews,
                    mainVideoId,
                };
            } else if (action.videoView.type === 'screen') {
                const screenShareViews = state.screenShareViews.slice();
                const previousIndex = screenShareViews.findIndex(
                    (el) => el.userId === action.videoView.userId
                );
                if (previousIndex !== -1) {
                    screenShareViews[previousIndex] = action.videoView;
                } else {
                    screenShareViews.push(action.videoView);
                }
                /*
                const mainVideoId = determineMainVideoId(
                    state.activeSpeakerId,
                    state.meetingParticipants,
                    videoViews,
                    state.ownVideoView,
                    state.meetingInfo
                );
                 */
                return {
                    ...state,
                    screenShareViews: screenShareViews,
                    //mainVideoId
                };
            } else {
                return state;
            }
        }
        case types.WEBRTC_USERLEFT: {
            if (action.userType === 'remote') {
                let videoViews = state.videoViews.slice();
                const indexToDelete = videoViews.findIndex(
                    (el) => el.userId === action.userId
                );
                if (indexToDelete !== -1) {
                    videoViews.splice(indexToDelete, 1);
                }
                const mainVideoId = determineMainVideoId(
                    state.activeSpeakerId,
                    state.meetingParticipants,
                    videoViews,
                    state.ownVideoView,
                    state.meetingInfo
                );
                return {
                    ...state,
                    videoViews: videoViews,
                    mainVideoId,
                };
            } else if (action.userType === 'screen') {
                let screenShareViews = state.screenShareViews.slice();
                const indexToDelete = screenShareViews.findIndex(
                    (el) => el.userId === action.userId
                );
                if (indexToDelete !== -1) {
                    screenShareViews.splice(indexToDelete, 1);
                }
                /*
                const mainVideoId = determineMainVideoId(
                    state.activeSpeakerId,
                    state.meetingParticipants,
                    videoViews,
                    state.ownVideoView,
                    state.meetingInfo
                );
                 */
                return {
                    ...state,
                    screenShareViews: screenShareViews,
                    //mainVideoId
                };
            } else {
                return state;
            }
        }
        case types.WEBRTC_SPEAKER_CHANGED: {
            // ignore self
            if (action.userId === state.clientInfo.id) {
                return state;
            } else {
                const mainVideoId = determineMainVideoId(
                    action.userId,
                    state.meetingParticipants,
                    state.videoViews,
                    state.ownVideoView,
                    state.meetingInfo
                );
                return {
                    ...state,
                    activeSpeakerId: action.userId,
                    mainVideoId,
                };
            }
        }
        case types.WEBRTC_START_SCREENSHARE_SUCCESS: {
            return {
                ...state,
                ownScreenShareView: action.screenShareView,
            };
        }
        case types.WEBRTC_STOP_SCREENSHARE_SUCCESS: {
            return {
                ...state,
                ownScreenShareView: undefined,
            };
        }
        case types.WEBRTC_CLOSE: {
            return {
                ...state,
                webRtcInitialized: false,
                webRtcFlags: {},
            };
        }
        case types.WEBRTC_RECONNECTING: {
            return {
                ...state,
                webRtcReconnecting: true,
            };
        }
        case types.WEBRTC_RECONNECTING_SUCCESS: {
            return {
                ...state,
                webRtcReconnecting: false,
            };
        }
        case types.ALC_MEETING_PARTICIPANTS_UPDATE: {
            const adviser = [];
            const clients = [];
            action.meetingParticipants.forEach((el) => {
                if (el.isAdviser) {
                    adviser.push(el);
                } else {
                    clients.push(el);
                }
            });
            const meetingParticipants = adviser.concat(clients);

            const mainVideoId = determineMainVideoId(
                state.activeSpeakerId,
                meetingParticipants,
                state.videoViews,
                state.ownVideoView,
                state.meetingInfo
            );
            return {
                ...state,
                meetingParticipants,
                mainVideoId,
            };
        }
        case types.ALC_MEETING_INFO_UPDATE: {
            const mainVideoId = determineMainVideoId(
                state.activeSpeakerId,
                state.meetingParticipants,
                state.videoViews,
                state.ownVideoView,
                action.meetingInfo
            );
            let sharedApplicationsShown = false;
            if (
                action.meetingInfo.currentApp ||
                (state.ownApplicationId && state.sharedApplicationsShown)
            ) {
                sharedApplicationsShown = true;
            }
            return {
                ...state,
                meetingInfo: action.meetingInfo,
                mainVideoId,
                sharedApplicationsShown,
            };
        }
        case types.ALC_SET_CURRENT_APP: {
            let ownApplicationId = action.sharedApplicationId;
            if (!ownApplicationId) {
                ownApplicationId = state.ownApplicationId;
            }
            return {
                ...state,
                ownApplicationId,
            };
        }
        case types.ALC_UPDATE_SERVERTIME_DIFF: {
            return {
                ...state,
                serverTimeDiff: action.serverTimeDiff,
            };
        }
        case types.ALC_CLOSE: {
            return {
                ...state,
                alcInitialized: false,
            };
        }
        case types.JOIN_MEETING_SUCCESS: {
            const mainVideoId = determineMainVideoId(
                state.activeSpeakerId,
                state.meetingParticipants,
                state.videoViews,
                state.ownVideoView,
                state.meetingInfo
            );
            return {
                ...state,
                mainVideoId,
            };
        }
        case types.SA_SHOW: {
            let ownApplicationId = SA_APPLICATIONS.AGENDA;
            if (Config.agendaDisabled) {
                ownApplicationId = SA_APPLICATIONS.SHOWBOARD;
            }
            return {
                ...state,
                sharedApplicationsShown: true,
                ownApplicationId,
            };
        }
        case types.SA_SWITCH_OWN: {
            return {
                ...state,
                ownApplicationId: action.sharedApplicationId,
            };
        }
        case types.SA_HIDE: {
            return {
                ...state,
                sharedApplicationsShown: false,
                ownApplicationId: null,
            };
        }
        case types.DOCUMENTS_SHOW: {
            return {
                ...state,
                chatPanelShown: false,
                documentsPanelShown: true,
                documentPending: false,
            };
        }
        case types.DOCUMENTS_HIDE: {
            return {
                ...state,
                chatPanelShown: false,
                documentsPanelShown: false,
            };
        }
        case types.DOCUMENTS_NEW_UPLOAD: {
            if (state.documentsPanelShown) {
                return state;
            } else {
                return {
                    ...state,
                    documentPending: true,
                };
            }
        }
        case types.CHAT_SHOW: {
            return {
                ...state,
                chatPanelShown: true,
                documentsPanelShown: false,
                chatMessagePending: false,
            };
        }
        case types.CHAT_HIDE: {
            return {
                ...state,
                chatPanelShown: false,
                documentsPanelShown: false,
            };
        }
        case types.CHAT_NEW_MESSAGE: {
            if (state.chatPanelShown) {
                return state;
            } else {
                return {
                    ...state,
                    chatMessagePending: true,
                };
            }
        }
        case types.ALC_LEAVE_MEETING: {
            return {
                ...state,
                alcMeetingJoined: false,
                meetingInfo: undefined,
                meetingParticipants: [],
            };
        }
        case types.ALC_MEETING_COMPLETED:
        case types.ALC_KICKED: {
            return {
                ...state,
                alcMeetingJoined: false,
                meetingInfo: undefined,
                meetingParticipants: [],
                mainVideoId: null,
                activeSpeakerId: null,
                sharedApplicationsShown: false,
                ownApplicationId: null,
                documentsPanelShown: false,
                chatPanelShown: false,
                chatMessagePending: false,
                documentPending: false,
                menuVisible: false,
                infoPanelVisible: false,
                recordingConfirmationDialogVisible: false,
            };
        }
        case types.WEBRTC_LEAVE:
        case types.WEBRTC_RECONNECTING_FAILURE:
        case types.WEBRTC_JOIN_FAILURE: {
            return {
                ...state,
                webRtcMeetingJoined: false,
                ownVideoView: undefined,
                videoViews: [],
                ownScreenShareView: undefined,
                screenShareViews: [],
                screenShareOn: false,
                webRtcReconnecting: false,
            };
        }
        case types.LEAVE_MEETING: {
            return {
                ...state,
                mainVideoId: null,
                activeSpeakerId: null,
                sharedApplicationsShown: false,
                ownApplicationId: null,
                documentsPanelShown: false,
                chatPanelShown: false,
                chatMessagePending: false,
                documentPending: false,
                menuVisible: false,
                infoPanelVisible: false,
                participantActionsVisible: false,
                completeMeetingDialogVisible: false,
                leaveMeetingDialogVisible: false,
                recordingConfirmationDialogVisible: false,
            };
        }
        case types.CONTROLS_SHOW: {
            return {
                ...state,
                controlsVisible: true,
            };
        }
        case types.CONTROLS_HIDE: {
            // TODO remove special webinar case once handled better
            if (
                state.meetingInfo &&
                state.meetingInfo.type === MEETING_TYPES.WEBINAR
            ) {
                return state;
            } else {
                return {
                    ...state,
                    controlsVisible: false,
                    menuVisible: false,
                    infoPanelVisible: false,
                };
            }
        }
        case types.MENU_SHOW: {
            return {
                ...state,
                menuVisible: true,
                infoPanelVisible: false,
            };
        }
        case types.MENU_HIDE: {
            return {
                ...state,
                menuVisible: false,
            };
        }
        case types.INFO_PANEL_SHOW: {
            return {
                ...state,
                infoPanelVisible: true,
                menuVisible: false,
            };
        }
        case types.INFO_PANEL_HIDE: {
            return {
                ...state,
                infoPanelVisible: false,
            };
        }
        case types.PARTICIPANT_ACTIONS_SHOW: {
            return {
                ...state,
                participantActionsVisible: true,
            };
        }
        case types.PARTICIPANT_ACTIONS_HIDE: {
            return {
                ...state,
                participantActionsVisible: false,
            };
        }
        case types.COMPLETE_MEETING_DIALOG_SHOW: {
            return {
                ...state,
                completeMeetingDialogVisible: true,
            };
        }
        case types.COMPLETE_MEETING_DIALOG_HIDE: {
            return {
                ...state,
                completeMeetingDialogVisible: false,
            };
        }
        case types.LEAVE_MEETING_DIALOG_SHOW: {
            return {
                ...state,
                leaveMeetingDialogVisible: true,
            };
        }
        case types.LEAVE_MEETING_DIALOG_HIDE: {
            return {
                ...state,
                leaveMeetingDialogVisible: false,
            };
        }
        case types.FULLSCREEN_CHANGE: {
            return {
                ...state,
                fullscreenActive: action.fullscreenActive,
            };
        }
        case types.SA_IFRAME_NEW_REF: {
            return {
                ...state,
                saIFrameReady: false,
            };
        }
        case types.SA_IFRAME_READY: {
            return {
                ...state,
                saIFrameReady: true,
            };
        }
        case types.CHAT_DOCUMENTS_IFRAME_NEW_REF: {
            return {
                ...state,
                chatDocumentsIFrameReady: false,
            };
        }
        case types.CHAT_DOCUMENTS_IFRAME_READY: {
            return {
                ...state,
                chatDocumentsIFrameReady: true,
            };
        }
        case types.RECORDING_CONFIRMATION_DIALOG_SHOW: {
            return {
                ...state,
                recordingConfirmationDialogVisible: true,
            };
        }
        case types.RECORDING_CONFIRMATION_DIALOG_HIDE: {
            return {
                ...state,
                recordingConfirmationDialogVisible: false,
            };
        }
        default:
            return state;
    }

    function determineMainVideoId(
        activeSpeakerId,
        meetingParticipants,
        videoViews,
        ownVideoView,
        meetingInfo
    ) {
        if (!meetingInfo) {
            return null;
        }
        // if shared application shown no video is big
        else if (meetingInfo.currentApp) {
            return null;
            // if currentActiveUser is set
        } else if (meetingInfo.currentActiveUser) {
            return meetingInfo.currentActiveUser;
            // else current speaker is big (if in meeting)
        } else if (
            activeSpeakerId &&
            meetingParticipants.find((el) => el.id === activeSpeakerId)
        ) {
            return activeSpeakerId;
            // ownVideo is big if no one else in meeting (excluding standalone users)
        } else if (
            ownVideoView &&
            !meetingParticipants.find((participant) => {
                return (
                    participant.id !== ownVideoView.userId &&
                    !participant.standalone
                );
            })
        ) {
            return ownVideoView.userId;
            // if nothing else applies just show the first webrtc user found
        } else {
            for (let i = 0; i < videoViews.length; i++) {
                if (
                    meetingParticipants.findIndex(
                        (el) => el.id === videoViews[i].userId
                    ) !== -1
                ) {
                    return videoViews[i].userId;
                }
            }
        }
        // if no webrtc views select any user (not self)
        if (ownVideoView && meetingParticipants.length > 1) {
            const participant = meetingParticipants.find(
                (el) => el.id !== ownVideoView.userId
            );
            if (participant) {
                return participant.id;
            }
        }
        return null;
    }
}
