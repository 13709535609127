import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu } from 'primereact/menu';
import classNames from 'classnames';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';

import { Config } from '../../config/Config';

import {
    hideChat,
    hideDocuments,
    hideSharedApplications,
    hideMenu,
    hideInfoPanel,
    leaveMeeting,
    showChat,
    showDocuments,
    showSharedApplications,
    showMenu,
    showInfoPanel,
    showParticipantActions,
    showCompleteMeetingDialog,
    showLeaveMeetingDialog,
    startRecording,
    stopRecording,
    webRtcStartScreenShare,
    webRtcStopScreenShare,
    fullscreenToggle,
} from './actions';
import {
    hideSettings,
    showSettings,
    webRtcMuteAudio,
    webRtcMuteVideo,
} from '../deviceSettings/actions';
import MeetingInfoPanel from './MeetingInfoPanel';
import TranslatedString from '../base/i18n/TranslatedString';
import {
    COMMUNICATION_MODES,
    MEETING_TYPES,
    RESPONSIVE_MODES,
    TOOLTIP_TOUCH_DELAY,
    WEBRTC_MODES,
} from '../../constants/constants';
import { fullscreenEnabled } from '../base/util/helpers';
import Routes from '../../constants/routes';

export class MeetingToolbar extends Component {
    constructor(props) {
        super(props);

        this.handleLanguageSelectChange = this.handleLanguageSelectChange.bind(
            this
        );
        this.handleSAButtonClick = this.handleSAButtonClick.bind(this);
        this.handleActivateCamera = this.handleActivateCamera.bind(this);
        this.handleActivateMic = this.handleActivateMic.bind(this);
        this.handleLeaveMeeting = this.handleLeaveMeeting.bind(this);
    }

    handleLanguageSelectChange(e) {
        this.props.changeLanguage(e.target.value);
    }

    handleSAButtonClick() {
        if (this.props.saIFrameReady) {
            if (this.props.sharedApplicationsShown) {
                if (!this.props.currentApp) {
                    this.props.hideSharedApplications();
                }
            } else {
                this.props.showSharedApplications();
            }
        }
    }

    handleActivateCamera() {
        if (
            this.props.communicationMode === COMMUNICATION_MODES.AUDIO ||
            this.props.communicationMode === COMMUNICATION_MODES.NONE
        ) {
            this.props.showSettings();
        } else {
            this.props.webRtcMuteVideo(false);
        }
    }

    handleActivateMic() {
        if (this.props.communicationMode === COMMUNICATION_MODES.NONE) {
            this.props.showSettings();
        } else {
            this.props.webRtcMuteAudio(false);
        }
    }

    handleLeaveMeeting() {
        if (
            Config.completeMeetingDialogOnLeave &&
            this.props.canCompleteMeeting
        ) {
            this.props.showLeaveMeetingDialog();
        } else {
            if (this.props.meetingType === MEETING_TYPES.WEBINAR) {
                this.props.leaveMeeting(Routes.WEBINARS);
            } else if (
                this.props.meetingType === MEETING_TYPES.PHONE_CONSULTING
            ) {
                this.props.leaveMeeting(Routes.PHONE_CONSULTING);
            } else {
                this.props.leaveMeeting(Routes.MEETINGS);
            }
        }
    }

    render() {
        const responsiveMenuActive =
            this.props.responsiveMode < RESPONSIVE_MODES.MEDIUM;

        const micInactive =
            this.props.communicationMode === COMMUNICATION_MODES.NONE;

        const audioMuteButton = (
            <div aria-label="Toggle mute audio" className="toolbox-button">
                <div className="toolbox-icon">
                    {this.props.audioMuted || micInactive ? (
                        <Tippy
                            content={
                                micInactive ? (
                                    <TranslatedString id="activateMic" />
                                ) : (
                                    <TranslatedString id="unmuteMic" />
                                )
                            }
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className="icon-mic-disabled toggled"
                                onClick={this.handleActivateMic}
                            />
                        </Tippy>
                    ) : (
                        <Tippy
                            content={<TranslatedString id="muteMic" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className="icon-microphone"
                                onClick={() => this.props.webRtcMuteAudio(true)}
                            />
                        </Tippy>
                    )}
                </div>
            </div>
        );

        const leaveMeetingButton = (
            <div aria-label="Leave the call" className="toolbox-button">
                <div className="toolbox-icon">
                    <Tippy
                        content={
                            <TranslatedString
                                id={
                                    this.props.meetingType ===
                                    MEETING_TYPES.WEBINAR
                                        ? 'leaveWebinar'
                                        : 'leaveMeeting'
                                }
                            />
                        }
                        arrow
                        touch={['hold', TOOLTIP_TOUCH_DELAY]}
                    >
                        <i
                            className="icon-hangup"
                            onClick={this.handleLeaveMeeting}
                        />
                    </Tippy>
                </div>
            </div>
        );

        const cameraInactive =
            this.props.communicationMode === COMMUNICATION_MODES.AUDIO ||
            this.props.communicationMode === COMMUNICATION_MODES.NONE;

        const videoMuteButton = (
            <div aria-label="Toggle mute video" className="toolbox-button">
                <div className="toolbox-icon">
                    {this.props.videoMuted || cameraInactive ? (
                        <Tippy
                            content={
                                cameraInactive ? (
                                    <TranslatedString id="activateCam" />
                                ) : (
                                    <TranslatedString id="startCam" />
                                )
                            }
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className="icon-camera-disabled toggled"
                                onClick={this.handleActivateCamera}
                            />
                        </Tippy>
                    ) : (
                        <Tippy
                            content={<TranslatedString id="stopCam" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className="icon-camera"
                                onClick={() => this.props.webRtcMuteVideo(true)}
                            />
                        </Tippy>
                    )}
                </div>
            </div>
        );

        let recordingMenuItem;
        if (this.props.recordingEnabled) {
            if (this.props.canStopRecording) {
                recordingMenuItem = {
                    label: <TranslatedString id="stopRecording" />,
                    icon: 'pi pi-fw pi-md-fiber-manual-record',
                    className: 'recording-on',
                    disabled: this.props.stopRecordingDisabled,
                    command: () => {
                        this.props.stopRecording();
                        this.props.hideMenu();
                    },
                };
            } else {
                recordingMenuItem = {
                    label: <TranslatedString id="startRecording" />,
                    icon: 'pi pi-fw pi-md-fiber-manual-record',
                    command: () => {
                        this.props.startRecording();
                        this.props.hideMenu();
                    },
                };
            }
        }

        let menuItems = [];
        if (this.props.recordingEnabled) {
            menuItems.push(recordingMenuItem);
        }

        menuItems.push({
            label: <TranslatedString id="participantActions" />,
            icon: 'pi pi-fw pi-md-mic-off',
            command: () => {
                this.props.showParticipantActions();
                this.props.hideMenu();
            },
        });

        if (responsiveMenuActive) {
            if (this.props.screenShareEnabled) {
                if (this.props.ownScreenShareView) {
                    menuItems.push({
                        label: <TranslatedString id="stopScreenShare" />,
                        icon: 'pi pi-fw pi-md-desktop-windows',
                        command: () => {
                            this.props.webRtcStopScreenShare();
                            this.props.hideMenu();
                        },
                    });
                } else {
                    menuItems.push({
                        label: <TranslatedString id="startScreenShare" />,
                        icon: 'pi pi-fw pi-md-desktop-windows',
                        command: () => {
                            this.props.webRtcStartScreenShare();
                            this.props.hideMenu();
                        },
                    });
                }
            }
            if (this.props.sharedApplicationsUrl) {
                menuItems.push({
                    label: <TranslatedString id="chat" />,
                    icon: 'pi pi-fw pi-md-chat-bubble-outline',
                    command: this.props.chatPanelShown
                        ? () => {
                              this.props.hideChat();
                              this.props.hideMenu();
                          }
                        : () => {
                              this.props.showChat();
                              this.props.hideMenu();
                          },
                    disabled: !this.props.chatDocumentsIFrameReady,
                    className: this.props.chatMessagePending
                        ? 'item-pending'
                        : null,
                });
                menuItems.push({
                    label: <TranslatedString id="documents" />,
                    icon: 'pi pi-fw pi-md-insert-drive-file',
                    command: this.props.documentsPanelShown
                        ? () => {
                              this.props.hideDocuments();
                              this.props.hideMenu();
                          }
                        : () => {
                              this.props.showDocuments();
                              this.props.hideMenu();
                          },
                    disabled: !this.props.chatDocumentsIFrameReady,
                    className: this.props.documentPending
                        ? 'item-pending'
                        : null,
                });
            }
            menuItems.push({
                label: <TranslatedString id="meetingInfo" />,
                icon: 'pi pi-fw pi-md-info-outline',
                command: this.props.infoPanelVisible
                    ? this.props.hideInfoPanel
                    : this.props.showInfoPanel,
            });
            if (this.props.sharedApplicationsUrl) {
                menuItems.push({
                    label: <TranslatedString id="sharedApplications" />,
                    icon: 'pi pi-fw pi-md-view-quilt',
                    command: this.handleSAButtonClick,
                    disabled: !this.props.saIFrameReady,
                });
            }
            menuItems.push({
                label: <TranslatedString id="deviceSettings" />,
                icon: 'pi pi-fw pi-md-settings',
                command: () => {
                    this.props.showSettings();
                    this.props.hideMenu();
                },
            });
        }

        menuItems = menuItems.concat([
            {
                separator: true,
            },
            {
                label: <TranslatedString id="completeMeeting" />,
                icon: 'pi pi-fw pi-md-check-circle',
                command: () => {
                    this.props.showCompleteMeetingDialog();
                    this.props.hideMenu();
                },
                disabled: !this.props.canCompleteMeeting,
            },
        ]);

        const fullscreenButton = fullscreenEnabled ? (
            <div aria-label="Toggle fullscreen" className="toolbox-button">
                <div className="toolbox-icon">
                    {this.props.fullscreenActive ? (
                        <Tippy
                            content={<TranslatedString id="stopFullscreen" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className="icon-full-screen toggled "
                                onClick={this.props.fullscreenToggle}
                            />
                        </Tippy>
                    ) : (
                        <Tippy
                            content={<TranslatedString id="startFullscreen" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className="icon-full-screen"
                                onClick={this.props.fullscreenToggle}
                            />
                        </Tippy>
                    )}
                </div>
            </div>
        ) : null;

        const screenShareButton =
            this.props.screenShareEnabled && !responsiveMenuActive ? (
                <div aria-label="Toggle screenshare" className="toolbox-button">
                    <div className="toolbox-icon">
                        {this.props.ownScreenShareView ? (
                            <Tippy
                                content={
                                    <TranslatedString id="stopScreenShare" />
                                }
                                arrow
                                touch={['hold', TOOLTIP_TOUCH_DELAY]}
                            >
                                <i
                                    className="icon-share-desktop toggled "
                                    onClick={this.props.webRtcStopScreenShare}
                                />
                            </Tippy>
                        ) : (
                            <Tippy
                                content={
                                    <TranslatedString id="startScreenShare" />
                                }
                                arrow
                                touch={['hold', TOOLTIP_TOUCH_DELAY]}
                            >
                                <i
                                    className="icon-share-desktop"
                                    onClick={this.props.webRtcStartScreenShare}
                                />
                            </Tippy>
                        )}
                    </div>
                </div>
            ) : null;

        const chatButton =
            this.props.sharedApplicationsUrl && !responsiveMenuActive ? (
                <div aria-label="Toggle chat" className="toolbox-button">
                    <div className="toolbox-icon">
                        <Tippy
                            content={<TranslatedString id="chat" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            {this.props.chatPanelShown ? (
                                <i
                                    className="icon-chat toggled"
                                    onClick={this.props.hideChat}
                                />
                            ) : (
                                <i
                                    className={classNames('icon-chat', {
                                        disabled: !this.props
                                            .chatDocumentsIFrameReady,
                                    })}
                                    onClick={
                                        this.props.chatDocumentsIFrameReady
                                            ? this.props.showChat
                                            : null
                                    }
                                />
                            )}
                        </Tippy>
                    </div>
                    {this.props.chatMessagePending && (
                        <span className="toolbox-badge">
                            <span className="badge-round">N</span>
                        </span>
                    )}
                </div>
            ) : null;

        const documentsButton =
            this.props.sharedApplicationsUrl && !responsiveMenuActive ? (
                <div aria-label="Toggle Documents" className="toolbox-button">
                    <div className="toolbox-icon">
                        <Tippy
                            content={<TranslatedString id="documents" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            {this.props.documentsPanelShown ? (
                                <i
                                    className="icon-share-doc toggled"
                                    onClick={this.props.hideDocuments}
                                />
                            ) : (
                                <i
                                    className={classNames('icon-share-doc', {
                                        disabled: !this.props
                                            .chatDocumentsIFrameReady,
                                    })}
                                    onClick={
                                        this.props.chatDocumentsIFrameReady
                                            ? this.props.showDocuments
                                            : null
                                    }
                                />
                            )}
                        </Tippy>
                        {this.props.documentPending && (
                            <span className="toolbox-badge">
                                <span className="badge-round">N</span>
                            </span>
                        )}
                    </div>
                </div>
            ) : null;

        const meetingInfoButton = !responsiveMenuActive ? (
            <div aria-label="Show Info Box" className="toolbox-button">
                <div className="toolbox-icon">
                    <Tippy
                        content={<TranslatedString id="meetingInfo" />}
                        arrow
                        touch={['hold', TOOLTIP_TOUCH_DELAY]}
                    >
                        {this.props.infoPanelVisible ? (
                            <i
                                className="icon-info toggled"
                                onClick={this.props.hideInfoPanel}
                            />
                        ) : (
                            <i
                                className="icon-info"
                                onClick={this.props.showInfoPanel}
                            />
                        )}
                    </Tippy>
                </div>
            </div>
        ) : null;

        const sharedApplicationsButton =
            this.props.sharedApplicationsUrl && !responsiveMenuActive ? (
                <div
                    aria-label="Show Shared Applications"
                    className="toolbox-button"
                >
                    <div className="toolbox-icon">
                        <Tippy
                            content={
                                <TranslatedString id="sharedApplications" />
                            }
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className={classNames(
                                    'icon-tiles-many',
                                    {
                                        toggled: this.props
                                            .sharedApplicationsShown,
                                    },
                                    {
                                        disabled:
                                            this.props.currentApp ||
                                            !this.props.saIFrameReady,
                                    }
                                )}
                                onClick={this.handleSAButtonClick}
                            />
                        </Tippy>
                    </div>
                </div>
            ) : null;

        const settingsButton = !responsiveMenuActive ? (
            <div aria-label="Open Settings" className="toolbox-button">
                <div className="toolbox-icon">
                    <Tippy
                        content={<TranslatedString id="deviceSettings" />}
                        arrow
                        touch={['hold', TOOLTIP_TOUCH_DELAY]}
                    >
                        {this.props.settingsPanelShown ? (
                            <i
                                className="icon-settings toggled"
                                onClick={this.props.hideSettings}
                            />
                        ) : (
                            <i
                                className="icon-settings"
                                onClick={() => this.props.showSettings()}
                            />
                        )}
                    </Tippy>
                </div>
            </div>
        ) : null;

        const menuButton = (
            <div aria-label="More" className="toolbox-button">
                <div className="toolbox-icon">
                    {this.props.menuVisible ? (
                        <i
                            className="icon-thumb-menu toggled"
                            onClick={this.props.hideMenu}
                        />
                    ) : (
                        <Tippy
                            content={<TranslatedString id="menu" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className="icon-thumb-menu"
                                onClick={this.props.showMenu}
                            />
                        </Tippy>
                    )}
                </div>
                {responsiveMenuActive &&
                    (this.props.chatMessagePending ||
                        this.props.documentPending) && (
                        <span className="toolbox-badge">
                            <span className="badge-round">N</span>
                        </span>
                    )}
            </div>
        );

        return (
            <div
                className={
                    'meeting-toolbar' +
                    (this.props.controlsVisible ? ' visible' : '')
                }
            >
                <div className="toolbox-background" />
                <div className="toolbox-menu-container">
                    {this.props.menuVisible && <Menu model={menuItems} />}
                </div>
                {this.props.infoPanelVisible && <MeetingInfoPanel />}
                <div className="toolbox-content">
                    <div className="button-group-left">
                        {fullscreenButton}
                        {screenShareButton}
                        {chatButton}
                        {documentsButton}
                    </div>
                    <div className="button-group-center">
                        {audioMuteButton}
                        {leaveMeetingButton}
                        {videoMuteButton}
                    </div>
                    <div className={'button-group-right'}>
                        {meetingInfoButton}
                        {sharedApplicationsButton}
                        {settingsButton}
                        {menuButton}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // TODO: workaround for VZ requirement, remove when handled properly
    let screenShareEnabled = state.meetings.webRtcFlags.hasDisplayMedia;
    if (
        state.auth.service === 'VZ-CH-01' ||
        state.auth.service === 'DEV-VZ-CH-01'
    ) {
        screenShareEnabled = false;
        if (
            (state.deviceSettings.currentSettings.cam &&
                state.deviceSettings.currentSettings.cam.includes(
                    'AMX Acendo Vibe'
                )) ||
            (state.deviceSettings.currentSettings.mic &&
                state.deviceSettings.currentSettings.mic.includes(
                    'AMX Acendo Vibe'
                ))
        ) {
            screenShareEnabled = true;
        }
    }

    const canCompleteMeeting = state.meetings.meetingInfo
        ? state.meetings.meetingInfo.hasSessions ||
          state.meetings.meetingInfo.state === 'ongoing'
        : false;

    const recordingEnabled =
        (state.auth.publicServiceInfo.webRtcMode ===
            WEBRTC_MODES.LIVESWITCH_SFU ||
            ((state.auth.publicServiceInfo.webRtcMode ===
                WEBRTC_MODES.LIVESWITCH_P2P ||
                state.auth.publicServiceInfo.webRtcMode ===
                    WEBRTC_MODES.ADIA_P2P) &&
                state.meetings.webRtcFlags.canRecordMedia)) &&
        (!state.auth.publicServiceInfo.meetingsSettings.hasOwnProperty(
            'enableRecording'
        ) ||
            state.auth.publicServiceInfo.meetingsSettings.enableRecording);

    let canStopRecording = false;
    if (
        state.auth.publicServiceInfo.webRtcMode ===
            WEBRTC_MODES.LIVESWITCH_P2P ||
        state.auth.publicServiceInfo.webRtcMode === WEBRTC_MODES.ADIA_P2P
    ) {
        if (
            state.meetings.clientInfo &&
            state.meetings.clientInfo.isRecording
        ) {
            canStopRecording = true;
        }
    } else {
        canStopRecording = state.meetings.meetingInfo
            ? state.meetings.meetingInfo.isRecording
            : false;
    }

    let stopRecordingDisabled = false;
    if (
        state.auth.publicServiceInfo.meetingsSettings &&
        state.auth.publicServiceInfo.meetingsSettings.enableAutoRecording
    ) {
        if (
            state.meetings.meetingParticipants.find(
                (participant) =>
                    participant.isAdviser &&
                    participant.info.roles.includes('autorecord')
            )
        ) {
            stopRecordingDisabled = true;
        }
    }

    return {
        language: state.base.i18n.language,
        currentApp: state.meetings.meetingInfo
            ? state.meetings.meetingInfo.currentApp
            : null,
        meetingType:
            state.meetings.meetingInfo && state.meetings.meetingInfo.type,
        audioMuted: state.deviceSettings.currentSettings.audioMuted,
        videoMuted: state.deviceSettings.currentSettings.videoMuted,
        ownScreenShareView: state.meetings.ownScreenShareView,
        sharedApplicationsShown: state.meetings.sharedApplicationsShown,
        documentsPanelShown: state.meetings.documentsPanelShown,
        chatPanelShown: state.meetings.chatPanelShown,
        chatMessagePending: state.meetings.chatMessagePending,
        documentPending: state.meetings.documentPending,
        settingsPanelShown: state.deviceSettings.settingsPanelShown,
        controlsVisible: state.meetings.controlsVisible,
        menuVisible: state.meetings.menuVisible,
        infoPanelVisible: state.meetings.infoPanelVisible,
        communicationMode: state.deviceSettings.communicationMode,
        sharedApplicationsUrl:
            state.auth.publicServiceInfo.meetingsSettings.sharedApplicationsUrl,
        screenShareEnabled,
        responsiveMode: state.base.common.responsiveMode,
        fullscreenActive: state.meetings.fullscreenActive,
        chatDocumentsIFrameReady: state.meetings.chatDocumentsIFrameReady,
        saIFrameReady: state.meetings.saIFrameReady,
        recordingEnabled,
        canCompleteMeeting,
        canStopRecording,
        stopRecordingDisabled,
    };
};

const mapDispatchToProps = {
    hideSettings,
    showSettings,
    hideChat,
    hideDocuments,
    hideSharedApplications,
    hideMenu,
    hideInfoPanel,
    leaveMeeting,
    showChat,
    showDocuments,
    showSharedApplications,
    showMenu,
    showInfoPanel,
    showParticipantActions,
    showCompleteMeetingDialog,
    showLeaveMeetingDialog,
    startRecording,
    stopRecording,
    webRtcMuteAudio,
    webRtcMuteVideo,
    webRtcStartScreenShare,
    webRtcStopScreenShare,
    fullscreenToggle,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingToolbar);
