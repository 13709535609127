import React, { Component } from 'react';
import { connect } from 'react-redux';

import { managerIFrameLoad, managerIFrameNewRef } from './actions';

export class MeetingsManagerExternal extends Component {
    constructor(props) {
        super(props);
        this.iFrameRef = React.createRef();
    }

    componentDidMount() {
        this.props.managerIFrameNewRef(this.iFrameRef);
    }

    componentWillUnmount() {
        this.props.managerIFrameNewRef(null);
    }

    render() {
        return (
            <iframe
                className="meetings-manager-iframe"
                title="meetings manager iframe"
                src={this.props.meetingsManagerUrl}
                allowFullScreen
                ref={this.iFrameRef}
                onLoad={this.props.managerIFrameLoad}
            />
        );
    }
}

const mapDispatchToProps = {
    managerIFrameLoad,
    managerIFrameNewRef
};

export default connect(undefined, mapDispatchToProps)(MeetingsManagerExternal);
