import * as types from './actionTypes';

export const initialState = {
    adviserList: undefined,
    ownMeetings: [],
    ownMeetingsLoading: false
};

export default function dashboard(state = initialState, action) {
    switch (action.type) {
        case types.ALC_ADVISER_LIST_UPDATE: {
            return {
                ...state,
                adviserList: action.adviserList
            };
        }
        case types.REQUEST_OWN_MEETINGS:
            return {
                ...state,
                ownMeetingsLoading: true
            };
        case types.REQUEST_OWN_MEETINGS_SUCCESS:
            return {
                ...state,
                ownMeetingsLoading: false,
                ownMeetings: action.meetings
            };
        case types.REQUEST_OWN_MEETINGS_FAILURE:
            return {
                ...state,
                ownMeetingsLoading: false,
                ownMeetings: []
            };
        default:
            return state;
    }
}
