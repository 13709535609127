import React from 'react';
import { connect } from 'react-redux';

import MeetingsManagerExternal from './MeetingsManagerExternal';
import MeetingsManagerStandard from './MeetingsManagerStandard';
import { MEETING_TYPES } from '../../constants/constants';

export function MeetingsManager(props) {
    if (props.meetingsManagerUrl) {
        let managerUrl = props.meetingsManagerUrl;
        // TODO replace with proper setting once implemented
        if (props.type === MEETING_TYPES.WEBINAR) {
            managerUrl = props.meetingsManagerUrl.replace(
                '/meetings',
                '/webinars'
            );
        } else if (props.type === MEETING_TYPES.PHONE_CONSULTING) {
            managerUrl = props.meetingsManagerUrl.replace(
                '/meetings',
                '/phone-consulting'
            );
        }
        return <MeetingsManagerExternal meetingsManagerUrl={managerUrl} />;
    } else {
        return <MeetingsManagerStandard type={props.type} />;
    }
}

const mapStateToProps = (state) => {
    let meetingsManagerUrl = null;
    if (
        state.auth.publicServiceInfo &&
        state.auth.publicServiceInfo.meetingsSettings
    ) {
        meetingsManagerUrl =
            state.auth.publicServiceInfo.meetingsSettings.meetingsManagerUrl;
    }

    return {
        meetingsManagerUrl,
    };
};

export default connect(mapStateToProps, undefined)(MeetingsManager);
