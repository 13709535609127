import { push } from 'connected-react-router';

import * as types from './actionTypes';
import { forceLogout, noServiceFound } from '../auth/actions';

import {
    convertMeetingProperties,
    handleError,
    routeWithServiceId
} from '../base/util/helpers';
import authHelper from '../base/util/authHelper';
import Routes from '../../constants/routes';

function requestMeetingsRequest() {
    return { type: types.REQUEST_MEETINGS };
}

function requestMeetingsSuccess(meetings) {
    return { type: types.REQUEST_MEETINGS_SUCCESS, meetings };
}

function requestMeetingsFailure(errorMessage) {
    return { type: types.REQUEST_MEETINGS_FAILURE, errorMessage };
}

export function requestMeetings(range) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(requestMeetingsRequest());
            const response = await api.getMeetings(
                service,
                null,
                ['-date'],
                range,
                authHelper.getToken()
            );
            if (!response.error) {
                convertMeetingProperties(response.meetings, true);
                dispatch(requestMeetingsSuccess(response.meetings));
            } else {
                handleError(response.error, {
                    fatal: errorMessage => dispatch(forceLogout(errorMessage)),
                    nonFatal: errorMessage =>
                        dispatch(requestMeetingsFailure(errorMessage))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

export function goToMeeting(meetingId) {
    return async (dispatch, getState, api) => {
        dispatch({type: types.GO_TO_MEETING});
        dispatch(
            push(
                '' +
                    routeWithServiceId(
                        Routes.MEETINGS_EXECUTE.replace(':id', meetingId),
                        getState().auth.service
                    )
            )
        );
    };
}

export function openEditMeeting(meetingId, meetingType) {
    return { type: types.OPEN_EDIT_MEETING, meetingId, meetingType };
}

export function managerIFrameLoad() {
    return { type: types.MANAGER_IFRAME_LOAD };
}

export function managerIFrameReady() {
    return { type: types.MANAGER_IFRAME_READY };
}

export function managerIFrameNewRef(iFrameRef) {
    return { type: types.MANAGER_IFRAME_NEW_REF, iFrameRef };
}

export function managerIFrameSendCheckCompleted(meetingInfo) {
    return { type: types.MANAGER_IFRAME_SEND_CHECK_COMPLETED, meetingInfo };
}

export function managerIFrameSendOpenEdit(meetingId) {
    return { type: types.MANAGER_IFRAME_SEND_OPEN_EDIT, meetingId };
}

function createAdHocMeetingRequest() {
    return { type: types.CREATE_ADHOC_MEETING };
}

function createAdHocMeetingSuccess(meetingInfo) {
    return { type: types.CREATE_ADHOC_MEETING_SUCCESS, meetingInfo };
}

function createAdHocMeetingFailure(errorMessage) {
    return { type: types.CREATE_ADHOC_MEETING_FAILURE, errorMessage };
}

export function createAdHocMeeting(title, type) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(createAdHocMeetingRequest());

            const user = getState().auth.user;
            const meetingData = {
                title: title,
                date: Date.now(),
                durationInMinutes: 120,
                participants: [
                    {
                        name: user.firstName ? user.firstName + ' ' + user.name : user.name,
                        isPublic: true,
                        email: user.email,
                        adviserId: user._id,
                    }
                ],
                type: type,
                organizerKeycloakId : user._id,
            }

            const response = await api.createMeeting(
                service,
                meetingData,
                authHelper.getToken()
            );
            if (!response.error) {
                // TODO: remove get request when proper response is returned from create
                const getMeetingResponse = await api.getMeeting(service, response.meeting._id, authHelper.getToken());
                if (!getMeetingResponse.error) {
                    dispatch(createAdHocMeetingSuccess(getMeetingResponse.meeting));
                } else {
                    handleError(getMeetingResponse.error, {
                        fatal: errorMessage => dispatch(forceLogout(errorMessage)),
                        nonFatal: errorMessage =>
                            dispatch(createAdHocMeetingFailure(errorMessage))
                    });
                }
            } else {
                handleError(response.error, {
                    fatal: errorMessage => dispatch(forceLogout(errorMessage)),
                    nonFatal: errorMessage =>
                        dispatch(createAdHocMeetingFailure(errorMessage))
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}