import * as types from './actionTypes';

export const initialState = {
    isLoading: false,
    meetings: [],
    errorMessage: undefined,

    adHocIsLoading: false,
    adHocMeetingInfo: undefined,
    adHocErrorMessage: undefined
};

/**
 *
 * @param state
 * @param action
 * @returns new state
 */
export default function meetingsManager(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_MEETINGS:
            return {
                ...state,
                isLoading: true,
                errorMessage: undefined
            };
        case types.REQUEST_MEETINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                meetings: action.meetings
            };
        case types.REQUEST_MEETINGS_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.errorMessage
            };
        case types.CREATE_ADHOC_MEETING:
            return {
                ...state,
                adHocIsLoading: true,
                adHocMeetingInfo: undefined,
                adHocErrorMessage: undefined
            };
        case types.CREATE_ADHOC_MEETING_SUCCESS:
            return {
                ...state,
                adHocIsLoading: false,
                adHocMeetingInfo: action.meetingInfo
            };
        case types.CREATE_ADHOC_MEETING_FAILURE:
            return {
                ...state,
                adHocIsLoading: false,
                adHocErrorMessage: action.errorMessage
            };
        case types.GO_TO_MEETING: {
            return {
                ...state,
                adHocIsLoading: false,
                adHocMeetingInfo: undefined,
                adHocErrorMessage: undefined
            };
        }
        default:
            return state;
    }
}
