import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';

import {
    hideParticipantActions,
    alcMuteParticipant,
    alcKickParticipant,
} from './actions';

export class ParticipantActionsDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmMute: false,
            confirmKick: false,
            userId: null,
            userName: null,
        };

        this.handleAttemptMute = this.handleAttemptMute.bind(this);
        this.handleMuteConfirm = this.handleMuteConfirm.bind(this);
        this.handleAttemptKick = this.handleAttemptKick.bind(this);
        this.handleKickConfirm = this.handleKickConfirm.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    handleAttemptMute(userId, userName) {
        this.setState({
            confirmMute: true,
            userId,
            userName,
        });
    }

    handleMuteConfirm() {
        this.props.alcMuteParticipant(this.state.userId);
        this.handleClose();
    }

    handleAttemptKick(userId, userName) {
        this.setState({
            confirmKick: true,
            userId,
            userName,
        });
    }

    handleKickConfirm() {
        this.props.alcKickParticipant(this.state.userId);
        this.handleClose();
    }

    handleClose() {
        this.props.hideParticipantActions();
        setTimeout(this.resetState, 1000);
    }

    resetState() {
        this.setState({
            confirmMute: false,
            confirmKick: false,
            userId: null,
            userName: null,
        });
    }

    render() {
        if (this.state.confirmMute || this.state.confirmKick) {
            const footer = (
                <div>
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'cancel'
                        )}
                        className="p-button-secondary"
                        icon="pi pi-times"
                        onClick={this.resetState}
                    />
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'confirm'
                        )}
                        className="p-button"
                        icon="pi pi-check"
                        onClick={
                            this.state.confirmMute
                                ? this.handleMuteConfirm
                                : this.handleKickConfirm
                        }
                    />
                </div>
            );

            return (
                <Dialog
                    className="participant-actions-dialog"
                    header={<TranslatedString id={'participantActions'} />}
                    footer={footer}
                    visible={this.props.participantActionsVisible}
                    onHide={this.handleClose}
                    baseZIndex={999999}
                    focusOnShow={false}
                >
                    <div className="p-grid form-group">
                        <div className="p-col-12">
                            <TranslatedString id="areYouSure" />
                        </div>
                        <div className="p-col-12">
                            <TranslatedString
                                id={
                                    this.state.confirmMute
                                        ? 'muteParticipant'
                                        : 'kickParticipant'
                                }
                            />
                            <span style={{ fontWeight: 'bold' }}>
                                {':  ' + this.state.userName}
                            </span>
                        </div>
                    </div>
                </Dialog>
            );
        } else {
            const footer = (
                <div>
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'cancel'
                        )}
                        className="p-button-secondary"
                        icon="pi pi-times"
                        onClick={this.handleClose}
                    />
                </div>
            );

            let adviserItems = [];
            let participantItems = [];

            this.props.meetingParticipants.forEach((item) => {
                if (!item.standalone && item.id !== this.props.clientInfo.id) {
                    const actionListItem = (
                        <tr key={item.id}>
                            <td>{item.info.name}</td>
                            <td>
                                <Button
                                    label={getTranslatedString(
                                        this.props.language,
                                        'mute'
                                    )}
                                    icon="pi pi-md-mic-off"
                                    onClick={this.handleAttemptMute.bind(
                                        this,
                                        item.id,
                                        item.info.name
                                    )}
                                    disabled={!item.deviceInfo.audio}
                                />
                            </td>
                            <td>
                                <Button
                                    label={getTranslatedString(
                                        this.props.language,
                                        'kick'
                                    )}
                                    icon="pi pi-md-exit-to-app"
                                    onClick={this.handleAttemptKick.bind(
                                        this,
                                        item.id,
                                        item.info.name
                                    )}
                                />
                            </td>
                        </tr>
                    );
                    if (item.isAdviser) {
                        adviserItems.push(actionListItem);
                    } else {
                        participantItems.push(actionListItem);
                    }
                }
            });

            return (
                <Dialog
                    className="participant-actions-dialog"
                    header={<TranslatedString id={'participantActions'} />}
                    footer={footer}
                    visible={this.props.participantActionsVisible}
                    onHide={this.handleClose}
                    baseZIndex={999999}
                    focusOnShow={false}
                >
                    <div className="p-grid form-group">
                        <div className="p-col-12">
                            {participantItems.length > 0 ||
                            adviserItems.length > 0 ? (
                                <table>
                                    <tbody>
                                        {participantItems}
                                        {adviserItems}
                                    </tbody>
                                </table>
                            ) : (
                                <TranslatedString id="noParticipants" />
                            )}
                        </div>
                    </div>
                </Dialog>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        participantActionsVisible: state.meetings.participantActionsVisible,
        clientInfo: state.meetings.clientInfo,
        meetingParticipants: state.meetings.meetingParticipants,
        language: state.base.i18n.language,
    };
};

const mapDispatchToProps = {
    hideParticipantActions,
    alcMuteParticipant,
    alcKickParticipant,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ParticipantActionsDialog);
