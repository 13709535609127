import { LOCATION_CHANGE } from 'connected-react-router';

import {
    SA_IFRAME_NEW_REF,
    SA_IFRAME_LOAD,
    SA_SHOW,
    SA_SWITCH_OWN,
    SA_HIDE,
    SA_IFRAME_READY,
    ALC_JOIN_MEETING_SUCCESS,
    ALC_MEETING_INFO_UPDATE,
    CHAT_DOCUMENTS_IFRAME_NEW_REF,
    CHAT_DOCUMENTS_IFRAME_LOAD,
    CHAT_DOCUMENTS_IFRAME_READY,
    DOCUMENTS_SHOW,
    CHAT_SHOW,
    DOCUMENTS_HIDE,
    CHAT_HIDE,
    SA_IFRAME_INIT_CONFIRMED,
    CHAT_DOCUMENTS_IFRAME_INIT_CONFIRMED,
} from '../features/meetings/actionTypes';
import {
    MANAGER_IFRAME_NEW_REF,
    MANAGER_IFRAME_LOAD,
    MANAGER_IFRAME_READY,
    MANAGER_IFRAME_SEND_CHECK_COMPLETED,
    MANAGER_IFRAME_SEND_OPEN_EDIT,
} from '../features/meetingsManager/actionTypes';
import {
    DOC_MANAGER_IFRAME_NEW_REF,
    DOC_MANAGER_IFRAME_LOAD,
    DOC_MANAGER_IFRAME_READY,
} from '../features/documentsManager/actionTypes';
import { CHANGE_LANGUAGE } from '../features/base/i18n/actionTypes';
import {
    sharedApplicationsIFrameReady,
    documentsNewUpload,
    chatDocumentsIFrameReady,
    chatNewMessage,
    sharedApplicationsIFrameInitConfirmed,
    chatDocumentsIFrameInitConfirmed,
} from '../features/meetings/actions';
import {
    managerIFrameReady,
    goToMeeting,
} from '../features/meetingsManager/actions';
import { docManagerIFrameReady } from '../features/documentsManager/actions';
import {
    SA_NAMESPACE,
    SA_IDENTIFIERS,
    SA_APPLICATIONS,
    IFRAME_COMMANDS,
    IFRAME_INIT_INTERVAL,
} from '../constants/constants';
import { log, routeWithServiceId } from '../features/base/util/helpers';
import Routes from '../constants/routes';
import { Config } from '../config/Config';

export const createIFrameMiddleware = () => {
    let saIFrame = null;
    let chatDocumentsIFrame = null;
    let managerIFrame = null;
    let docManagerIFrame = null;

    let saAuth = null;

    let saInitInterval = null;
    let chatDocumentsInitInterval = null;
    let managerInitInterval = null;
    let docManagerInitInterval = null;

    function sendMessageToSAIFrame(msg) {
        if (saIFrame && saIFrame.contentWindow) {
            msg.namespace = SA_NAMESPACE;
            msg.identifier = SA_IDENTIFIERS.MAIN;
            saIFrame.contentWindow.postMessage(msg, '*');
            log.debug(msg);
        }
    }

    function sendSaInitMessage(language) {
        if (saAuth) {
            sendMessageToSAIFrame({
                cmd: IFRAME_COMMANDS.INIT,
                language: language,
                participantInfo: saAuth.participantInfo,
                meetingInfo: saAuth.meetingInfo,
                token: saAuth.token,
            });
        }
    }

    function sendMessageToChatDocumentsIFrame(msg) {
        if (chatDocumentsIFrame && chatDocumentsIFrame.contentWindow) {
            msg.namespace = SA_NAMESPACE;
            msg.identifier = SA_IDENTIFIERS.CHAT_DOCUMENTS;
            chatDocumentsIFrame.contentWindow.postMessage(msg, '*');
            log.debug(msg);
        }
    }

    function sendChatInitMessage(language) {
        if (saAuth) {
            sendMessageToChatDocumentsIFrame({
                cmd: IFRAME_COMMANDS.INIT,
                language: language,
                participantInfo: saAuth.participantInfo,
                meetingInfo: saAuth.meetingInfo,
                token: saAuth.token,
            });
        }
    }

    function sendMessageToManagerIFrame(msg) {
        if (managerIFrame && managerIFrame.contentWindow) {
            msg.namespace = SA_NAMESPACE;
            msg.identifier = SA_IDENTIFIERS.MANAGER;
            managerIFrame.contentWindow.postMessage(msg, '*');
            log.debug(msg);
        }
    }

    function sendManagerInitMessage(language) {
        sendMessageToManagerIFrame({
            cmd: IFRAME_COMMANDS.INIT,
            language: language,
        });
    }

    function sendMessageToDocManagerIFrame(msg) {
        if (docManagerIFrame && docManagerIFrame.contentWindow) {
            msg.namespace = SA_NAMESPACE;
            msg.identifier = SA_IDENTIFIERS.DOC_MANAGER;
            docManagerIFrame.contentWindow.postMessage(msg, '*');
            log.debug(msg);
        }
    }

    function sendDocManagerInitMessage(language) {
        sendMessageToDocManagerIFrame({
            cmd: IFRAME_COMMANDS.INIT,
            language: language,
        });
    }

    const saMessageEventHandler = (store) => (event) => {
        if (
            event.data &&
            event.data.namespace === SA_NAMESPACE &&
            event.data.identifier === SA_IDENTIFIERS.MAIN
        ) {
            log.debug(event.data);
            switch (event.data.cmd) {
                case IFRAME_COMMANDS.INIT_CONFIRMED: {
                    store.dispatch(sharedApplicationsIFrameInitConfirmed());
                    break;
                }
                case IFRAME_COMMANDS.READY: {
                    store.dispatch(sharedApplicationsIFrameReady());
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

    const chatDocumentsMessageEventHandler = (store) => (event) => {
        if (
            event.data &&
            event.data.namespace === SA_NAMESPACE &&
            event.data.identifier === SA_IDENTIFIERS.CHAT_DOCUMENTS
        ) {
            log.debug(event.data);
            switch (event.data.cmd) {
                case IFRAME_COMMANDS.INIT_CONFIRMED: {
                    store.dispatch(chatDocumentsIFrameInitConfirmed());
                    break;
                }
                case IFRAME_COMMANDS.READY: {
                    store.dispatch(chatDocumentsIFrameReady());
                    break;
                }
                case IFRAME_COMMANDS.CHAT_NEW_MESSAGE: {
                    store.dispatch(chatNewMessage());
                    break;
                }
                case IFRAME_COMMANDS.DOCUMENTS_NEW_UPLOAD: {
                    store.dispatch(documentsNewUpload());
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

    const managerMessageEventHandler = (store) => (event) => {
        if (
            event.data &&
            event.data.namespace === SA_NAMESPACE &&
            event.data.identifier === SA_IDENTIFIERS.MANAGER
        ) {
            log.debug(event.data);
            switch (event.data.cmd) {
                case IFRAME_COMMANDS.READY: {
                    store.dispatch(managerIFrameReady());
                    break;
                }
                case IFRAME_COMMANDS.JOIN_MEETING: {
                    if (event.data.meetingId) {
                        store.dispatch(goToMeeting(event.data.meetingId));
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

    const docManagerMessageEventHandler = (store) => (event) => {
        if (
            event.data &&
            event.data.namespace === SA_NAMESPACE &&
            event.data.identifier === SA_IDENTIFIERS.DOC_MANAGER
        ) {
            log.debug(event.data);
            switch (event.data.cmd) {
                case IFRAME_COMMANDS.READY: {
                    store.dispatch(docManagerIFrameReady());
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

    const hasEditMode = function (state, applicationId) {
        return (
            state.meetings.clientInfo.isAdviser ||
            (state.meetings.clientInfo.editPermissions &&
                state.meetings.clientInfo.editPermissions.includes(
                    applicationId
                ))
        );
    };

    let saEventHandlerRef;
    let chatDocumentsEventHandlerRef;
    let managerEventHandlerRef;
    let docManagerEventHandlerRef;

    return (store) => (next) => (action) => {
        const state = store.getState();

        switch (action.type) {
            /* Shared Applications main iFrame */
            case SA_IFRAME_NEW_REF:
                if (action.iFrameRef && action.iFrameRef.current) {
                    saIFrame = action.iFrameRef.current;
                } else {
                    saIFrame = null;
                    window.removeEventListener('message', saEventHandlerRef);
                }
                if (saInitInterval) {
                    clearInterval(saInitInterval);
                    saInitInterval = null;
                }
                return next(action);
            case SA_IFRAME_LOAD:
                saEventHandlerRef = saMessageEventHandler(store);
                window.addEventListener('message', saEventHandlerRef);
                if (saInitInterval) {
                    clearInterval(saInitInterval);
                }
                saInitInterval = setInterval(
                    () => sendSaInitMessage(state.base.i18n.language),
                    IFRAME_INIT_INTERVAL
                );
                return next(action);
            case SA_IFRAME_INIT_CONFIRMED: {
                clearInterval(saInitInterval);
                saInitInterval = null;
                return next(action);
            }
            case SA_IFRAME_READY: {
                if (state.meetings.ownApplicationId) {
                    sendMessageToSAIFrame({
                        cmd: IFRAME_COMMANDS.SHOW,
                        application: state.meetings.ownApplicationId,
                        editMode: hasEditMode(
                            state,
                            state.meetings.ownApplicationId
                        ),
                        language: state.base.i18n.language,
                    });
                } else if (state.meetings.meetingInfo.currentApp) {
                    sendMessageToSAIFrame({
                        cmd: IFRAME_COMMANDS.SHOW,
                        application: state.meetings.meetingInfo.currentApp,
                        editMode: hasEditMode(
                            state,
                            state.meetings.meetingInfo.currentApp
                        ),
                        language: state.base.i18n.language,
                    });
                }
                return next(action);
            }
            case ALC_JOIN_MEETING_SUCCESS: {
                saAuth = action.saAuth;
                return next(action);
            }
            case ALC_MEETING_INFO_UPDATE: {
                if (
                    action.meetingInfo.currentApp &&
                    !state.meetings.ownApplicationId
                ) {
                    sendMessageToSAIFrame({
                        cmd: IFRAME_COMMANDS.SHOW,
                        application: action.meetingInfo.currentApp,
                        editMode: hasEditMode(
                            state,
                            action.meetingInfo.currentApp
                        ),
                        language: state.base.i18n.language,
                    });
                } else if (
                    !action.meetingInfo.currentApp &&
                    !state.meetings.ownApplicationId
                ) {
                    sendMessageToSAIFrame({
                        cmd: IFRAME_COMMANDS.HIDE,
                    });
                }
                return next(action);
            }
            case SA_SHOW: {
                let application = SA_APPLICATIONS.AGENDA;
                if (Config.agendaDisabled) {
                    application = SA_APPLICATIONS.SHOWBOARD;
                }
                sendMessageToSAIFrame({
                    cmd: IFRAME_COMMANDS.SHOW,
                    application,
                    editMode: hasEditMode(state, application),
                    language: state.base.i18n.language,
                });
                return next(action);
            }
            case SA_SWITCH_OWN: {
                sendMessageToSAIFrame({
                    cmd: IFRAME_COMMANDS.SHOW,
                    application: action.sharedApplicationId,
                    editMode: hasEditMode(state, action.sharedApplicationId),
                    language: state.base.i18n.language,
                });
                return next(action);
            }
            case SA_HIDE: {
                sendMessageToSAIFrame({
                    cmd: IFRAME_COMMANDS.HIDE,
                });
                return next(action);
            }

            /* Chat/Documents iFrame */
            case CHAT_DOCUMENTS_IFRAME_NEW_REF: {
                if (action.iFrameRef && action.iFrameRef.current) {
                    chatDocumentsIFrame = action.iFrameRef.current;
                } else {
                    chatDocumentsIFrame = null;
                    window.removeEventListener(
                        'message',
                        chatDocumentsEventHandlerRef
                    );
                }
                if (chatDocumentsInitInterval) {
                    clearInterval(chatDocumentsInitInterval);
                    chatDocumentsInitInterval = null;
                }
                return next(action);
            }
            case CHAT_DOCUMENTS_IFRAME_LOAD: {
                chatDocumentsEventHandlerRef = chatDocumentsMessageEventHandler(
                    store
                );
                window.addEventListener(
                    'message',
                    chatDocumentsEventHandlerRef
                );
                if (chatDocumentsInitInterval) {
                    clearInterval(chatDocumentsInitInterval);
                }
                chatDocumentsInitInterval = setInterval(
                    () => sendChatInitMessage(state.base.i18n.language),
                    IFRAME_INIT_INTERVAL
                );
                return next(action);
            }
            case CHAT_DOCUMENTS_IFRAME_INIT_CONFIRMED: {
                clearInterval(chatDocumentsInitInterval);
                chatDocumentsInitInterval = null;
                return next(action);
            }
            case CHAT_DOCUMENTS_IFRAME_READY: {
                sendMessageToChatDocumentsIFrame({
                    cmd: IFRAME_COMMANDS.SHOW,
                    application: SA_APPLICATIONS.CHAT,
                    language: state.base.i18n.language,
                });
                return next(action);
            }

            /* single frame handling */
            case CHAT_SHOW: {
                sendMessageToChatDocumentsIFrame({
                    cmd: IFRAME_COMMANDS.SHOW,
                    application: SA_APPLICATIONS.CHAT,
                    language: state.base.i18n.language,
                });
                return next(action);
            }
            case DOCUMENTS_SHOW: {
                sendMessageToChatDocumentsIFrame({
                    cmd: IFRAME_COMMANDS.SHOW,
                    application: SA_APPLICATIONS.DOCUMENTS,
                    language: state.base.i18n.language,
                });
                return next(action);
            }
            case DOCUMENTS_HIDE:
            case CHAT_HIDE: {
                sendMessageToChatDocumentsIFrame({
                    cmd: IFRAME_COMMANDS.HIDE,
                });
                return next(action);
            }

            /* Meeting Manager iFrame */
            case MANAGER_IFRAME_NEW_REF: {
                if (action.iFrameRef && action.iFrameRef.current) {
                    managerIFrame = action.iFrameRef.current;
                } else {
                    managerIFrame = null;
                    window.removeEventListener(
                        'message',
                        managerEventHandlerRef
                    );
                }
                if (managerInitInterval) {
                    clearInterval(managerInitInterval);
                    managerInitInterval = null;
                }
                return next(action);
            }
            case MANAGER_IFRAME_LOAD: {
                managerEventHandlerRef = managerMessageEventHandler(store);
                window.addEventListener('message', managerEventHandlerRef);
                if (managerInitInterval) {
                    clearInterval(managerInitInterval);
                }
                managerInitInterval = setInterval(
                    () => sendManagerInitMessage(state.base.i18n.language),
                    IFRAME_INIT_INTERVAL
                );
                return next(action);
            }
            case MANAGER_IFRAME_READY: {
                clearInterval(managerInitInterval);
                managerInitInterval = null;
                return next(action);
            }
            case MANAGER_IFRAME_SEND_CHECK_COMPLETED: {
                sendMessageToManagerIFrame({
                    cmd: IFRAME_COMMANDS.CHECK_COMPLETED,
                    meetingInfo: action.meetingInfo,
                    language: state.base.i18n.language,
                });
                return next(action);
            }
            case MANAGER_IFRAME_SEND_OPEN_EDIT: {
                sendMessageToManagerIFrame({
                    cmd: IFRAME_COMMANDS.OPEN_EDIT,
                    meetingId: action.meetingId,
                    language: state.base.i18n.language,
                });
                return next(action);
            }

            /* Documents Manager iFrame */
            case DOC_MANAGER_IFRAME_NEW_REF: {
                if (action.iFrameRef && action.iFrameRef.current) {
                    docManagerIFrame = action.iFrameRef.current;
                } else {
                    docManagerIFrame = null;
                    window.removeEventListener(
                        'message',
                        docManagerEventHandlerRef
                    );
                }
                if (docManagerInitInterval) {
                    clearInterval(docManagerInitInterval);
                    docManagerInitInterval = null;
                }
                return next(action);
            }
            case DOC_MANAGER_IFRAME_LOAD: {
                docManagerEventHandlerRef = docManagerMessageEventHandler(
                    store
                );
                window.addEventListener('message', docManagerEventHandlerRef);
                if (docManagerInitInterval) {
                    clearInterval(docManagerInitInterval);
                }
                docManagerInitInterval = setInterval(
                    () => sendDocManagerInitMessage(state.base.i18n.language),
                    IFRAME_INIT_INTERVAL
                );
                return next(action);
            }
            case DOC_MANAGER_IFRAME_READY: {
                clearInterval(docManagerInitInterval);
                docManagerInitInterval = null;
                return next(action);
            }

            /* General */
            case CHANGE_LANGUAGE: {
                // TODO: language switch during meeting disabled for now
                /*
                if (state.meetings.alcMeetingJoined) {
                    let applicationId = '';
                    if (state.meetings && state.meetings.ownApplicationId) {
                        applicationId = state.meetings.ownApplicationId;
                    } else if (state.meetings && state.meetings.meetingInfo && state.meetings.meetingInfo.currentApp) {
                        applicationId = state.meetings.meetingInfo.currentApp;
                    }
                    sendMessageToSAIFrame({
                        cmd: IFRAME_COMMANDS.SHOW,
                        application: applicationId,
                        editMode: hasEditMode(state, applicationId),
                        language: action.language
                    });
                    sendMessageToDocumentsIFrame({
                        cmd: IFRAME_COMMANDS.SHOW,
                        application: SA_APPLICATIONS.DOCUMENTS,
                        language: action.language
                    });
                    sendMessageToChatDocumentsIFrame({
                        cmd: IFRAME_COMMANDS.SHOW,
                        application: SA_APPLICATIONS.CHAT,
                        language: action.language
                    });
                } else {
                 */
                sendMessageToManagerIFrame({
                    cmd: IFRAME_COMMANDS.SHOW,
                    language: action.language,
                });
                sendMessageToDocManagerIFrame({
                    cmd: IFRAME_COMMANDS.SHOW,
                    language: action.language,
                });
                //}

                return next(action);
            }
            case LOCATION_CHANGE: {
                if (
                    action.payload.location.pathname ===
                        routeWithServiceId(
                            Routes.MEETINGS,
                            state.auth.service
                        ) &&
                    state.auth.publicServiceInfo &&
                    state.auth.publicServiceInfo.meetingsSettings
                        .meetingsManagerUrl &&
                    state.router.location.pathname ===
                        routeWithServiceId(Routes.MEETINGS, state.auth.service)
                ) {
                    sendMessageToManagerIFrame({
                        cmd: IFRAME_COMMANDS.GO_TO_OVERVIEW,
                        language: state.base.i18n.language,
                    });
                    // TODO: change to webinarManagerUrl once implemented
                } else if (
                    action.payload.location.pathname ===
                        routeWithServiceId(
                            Routes.WEBINARS,
                            state.auth.service
                        ) &&
                    state.auth.publicServiceInfo &&
                    state.auth.publicServiceInfo.meetingsSettings
                        .meetingsManagerUrl &&
                    state.router.location.pathname ===
                        routeWithServiceId(Routes.WEBINARS, state.auth.service)
                ) {
                    sendMessageToManagerIFrame({
                        cmd: IFRAME_COMMANDS.GO_TO_OVERVIEW,
                        language: state.base.i18n.language,
                    });
                } else if (
                    action.payload.location.pathname ===
                        routeWithServiceId(
                            Routes.PHONE_CONSULTING,
                            state.auth.service
                        ) &&
                    state.auth.publicServiceInfo &&
                    state.auth.publicServiceInfo.meetingsSettings
                        .meetingsManagerUrl &&
                    state.router.location.pathname ===
                        routeWithServiceId(
                            Routes.PHONE_CONSULTING,
                            state.auth.service
                        )
                ) {
                    sendMessageToManagerIFrame({
                        cmd: IFRAME_COMMANDS.GO_TO_OVERVIEW,
                        language: state.base.i18n.language,
                    });
                }
                return next(action);
            }
            default:
                return next(action);
        }
    };
};
