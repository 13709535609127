import { take, put, all, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { routeWithServiceId } from '../base/util/helpers';
import { MANAGER_IFRAME_READY, OPEN_EDIT_MEETING } from './actionTypes';
import Routes from '../../constants/routes';
import { managerIFrameSendOpenEdit } from './actions';
import {MEETING_TYPES} from "../../constants/constants";

const getServiceId = state => state.auth.service;
const getMeetingsManagerUrl = state => {
    let meetingsManagerUrl = null;
    if (
        state.auth.publicServiceInfo &&
        state.auth.publicServiceInfo.meetingsSettings
    ) {
        meetingsManagerUrl =
            state.auth.publicServiceInfo.meetingsSettings.meetingsManagerUrl;
    }
    return meetingsManagerUrl;
};

function* openEdit() {
    while (true) {
        const { meetingId, meetingType } = yield take(OPEN_EDIT_MEETING);
        const serviceId = yield select(getServiceId);
        const meetingsManagerUrl = yield select(getMeetingsManagerUrl);
        // redirect to meetings/webinar manager page
        if (meetingType === MEETING_TYPES.WEBINAR) {
            yield put(push(routeWithServiceId(Routes.WEBINARS, serviceId)));
        } else if (meetingType === MEETING_TYPES.PHONE_CONSULTING) {
            yield put(push(routeWithServiceId(Routes.PHONE_CONSULTING, serviceId)));
        } else {
            yield put(push(routeWithServiceId(Routes.MEETINGS, serviceId)));
        }
        if (meetingId && meetingsManagerUrl) {
            // if meeting manager is set, send openEdit message
            yield take(MANAGER_IFRAME_READY);
            yield put(managerIFrameSendOpenEdit(meetingId));
        }
    }
}
export function* meetingsManagerSagas() {
    yield all([openEdit()]);
}
