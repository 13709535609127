export const italian = {
    // General
    language: 'Lingua',
    de: 'Tedesco',
    en: 'Inglese',
    fr: 'Francese',
    it: 'Italiano',

    applicationName: 'adiaLive Adviser',

    save: 'Salva',
    cancel: 'Annulla',
    confirm: 'Conferma',
    close: 'Chiudi',
    join: 'Partecipa',
    edit: 'Modifica',
    refresh: 'Ricaricare',

    areYouSure: 'É sicuro?',

    username: 'Nome utente',
    password: 'Password',
    serviceId: 'Service-ID',
    captcha: 'Captcha',
    signIn: 'Login',
    signOut: 'Logout',

    dashboard: 'Dashboard',
    meetings: 'Meeting',
    phoneConsulting: 'Consulenza telefonica',
    signatureTemplates: 'Modelli con firma',

    reconnectingHeader: 'Connessione persa',
    reconnectingMessage:
        'Connessione al server persa. La connessione viene ripristinata...',

    pathDoesNotExist: 'Questa pagina non esiste!',

    // Account

    account: 'Account',
    nepatecAccount: 'Nepatec Account',
    editAccount: 'Modifica Account',
    name: 'Nome',
    lastName: 'Cognome',
    firstName: 'Nome',
    email: 'Email',
    newPassword: 'Nuova Password',
    newPasswordConfirmation: 'Conferma Password',
    status: 'Stato',
    active: 'Attivo',

    invalidEmail: 'Indirizzo email non valido',
    invalidPassword: 'La password deve contenere almeno 8 caratteri.',
    editAccountSuccess: 'Il tuo account è stato aggiornato.',
    editAccountFailure: 'Non è stato possibile aggiorare il suo profilo.',
    editPasswordSuccess: 'La tua password è stata aggiornata.',
    editPasswordFailure: 'Non è stato possibile aggiorare la sua password.',
    editPasswordCurrentWrong: 'Password esistente non valida.',
    editPasswordDoesNotMatch: 'La password di conferma non coincide.',
    userKeycloakConflict:
        'Un utente con questo nome utente e / o questa email esiste già.',
    getNepatecFailure:
        "Non è stato possibile recuperare l'utente o gli utenti.",

    // Dashboard

    adviserOnline: 'Consulnte online',
    ownOpenMeetings: 'I suoi Meeting aperti',

    // Device Settings

    deviceSettings: 'Impostazioni',
    cam: 'Videocamera',
    mic: 'Mircrofono',
    resolution: 'Risoluzione',
    comModeNone: 'Solo chat',
    comModeAudio: 'Audio',
    comModeBoth: 'Audio + video',

    // Meeting

    agenda: 'Ordine del giorno',
    showboard: 'Showboard',
    minutes: 'Rapporto',
    documents: 'Documenti',
    chat: 'Chat',
    show: 'Mostra',
    editModePermissions: 'Modifica i permessi',

    startFullscreen: 'Apri schermo intero',
    stopFullscreen: 'Chiudi schermo intero',
    startScreenShare: 'Condividi lo schermo',
    stopScreenShare: 'Annulla condivisione schermo',
    leaveMeeting: 'Abbandona il Meeting',
    muteMic: 'Disabilita il miscrofono',
    unmuteMic: 'Abilita il miscrofono',
    activateMic: 'Attiva il microfono',
    startCam: 'Abilita videocamera',
    stopCam: 'Disabilita videocamera',
    activateCam: 'Attiva videocamera',
    meetingInfo: 'Info Meeting',
    sharedApplications: 'Shared Applications',
    menu: 'Menu',

    startRecording: 'Inizia a registrare',
    stopRecording: 'Interrompere la registrazione',
    muteParticipant: 'Disabilita il miscrofono del partecipante',
    kickParticipant: 'Rimuovi partecipante',
    msgRemoteMute: 'Un consulente ha disabilitato il suo microfono.',
    msgKick: 'Un consulente vi ha rimosso dal Meeting.',
    participantActions: 'Muta / rimuovi partecipante',
    noParticipants: 'Nessun partecipante nel Meeting',
    mute: 'Muta',
    kick: 'Rimuovi',
    msgReallyLeaveMeeting: 'Vuole davver abbandonare il Meeting?',
    completeMeeting: 'Terminare il meeting',
    completeMeetingConfirmation: 'Vuole davvero teminare il meeting?',
    msgMeetingCompleted: 'Il meeting è stato terminato',

    share: 'Condividi',
    standaloneApplications: 'Standalone Applications',
    link: 'Link',

    bitrate: 'Bitrate',
    framerate: 'Framerate',
    packetLoss: 'Packet loss',

    // Meeting Manager Standard

    meetingTableDate: 'Data',
    meetingTableTitle: 'Titolo',
    meetingTableDescription: 'Descrizione',
    meetingTableParticipants: 'Partecipanti',

    // Webinars
    webinars: 'Webinars',
    participants: 'Partecipanti',
    requestedSpeakerPermission: 'Permesso per parlare richiesta',
    leaveWebinar: 'Abbandona il Webinar',

    // Recordings
    recordings: 'Registrazioni',
    download: 'Scarica',
    deleteRecording: 'Eliminare la registrazione',
    deleteRecordingConfirmation: 'Vuole davvero eliminare la registrazione?',
    unsavedRecordings:
        'Ci sono nuove registrazioni disponibile. Si prega di scaricarle ora e poi eliminarle dalla lista.',
    noRecordings: 'Nessuna registrazione trovata',
    recordingsNotice:
        'A causa dei limiti di archiviazione del browser, si prega di mantenere la lista il più breve possibile scaricando e poi eliminando le registrazioni.',
    markedForDelete: 'Verrà cancellato tra {{hours}} ore',
    markedForDelete1Hour: "Verrà cancellato tra meno di un'ora",
    needsDownload: 'Si prega di scaricare',
    confirmRecordingTitle: 'Attendere conferma di registrazione',
    confirmRecordingMessage:
        'Ai partecipanti viene chiesto il consenso per avviare la registrazione. Si prega di attendere la conferma da parte dei partecipanti.',
    confirmRecordingResultAsk: 'Richiesta di consenso',
    confirmRecordingResultRejected: 'Respinto',
    confirmRecordingResultConfirmed: 'Accettato',
    restartConfirmation: 'Richiedere nuovamente conferma',

    // adHoc
    adHoc: 'Ad hoc',
    scheduled: 'Programmato',
    meeting: 'Meeting',
    phoneConsultation: 'Consulenza telefonica',
    meetingId: 'ID meeting',
    consultationId: 'ID consulenza',
    adHocMeeting: 'Meeting Ad hoc',
    adHocPhoneConsultation: 'Consulenza telefonica Ad hoc',
    newMeeting: 'Nuovo meeting',
    newPhoneConsultation: 'Nuova consulenza telefonica',

    // Errors

    connectionError: 'La connessione al server non riuscita.',
    serverError: 'oops! Si è verificato un errore.',
    accessDenied: 'Accesso negato!',
    notAuthorized: 'Non autorizzato!',
    authInvalidIp: 'IP non valido.',
    authInvalidUser: 'Utente non valido.',
    authInvalidCaptcha: 'Captcha non valido.',
    authInvalidCredentials: 'Dati di accesso non validi.',
    serviceNotFound: 'Questo Service non esiste.',
    invalidRoleError:
        "Solo gli utenti con il ruolo 'adviser' possono utilizzare l'applicazione Adviser.",
    autoRecordingError:
        'Non è stato possibile avviare la registrazione automatica, non è autorizzato a partecipare a questo meeting senza avviare la registrazione.',

    alcErrorDefault: 'Errore alc',
    alcErrorMeetingDoesNotExist:
        'Il Meeting già stato completato o non esiste.',
    alcErrorMeetingConnectionLost:
        'Connessione al Meeting-Server persa. Vi preghiamo di riprovare',
    alcErrorMeetingFull:
        'È stato superato il limite massimo di partecipanti al Meeting.',
    alcErrorWebinarAdviserFull:
        'Numero massimo di consulenti raggiunto per questo webinar.',
    alcErrorAdviserIdMismatch:
        "L'invito che hai usato non è stato creato per il suo utente.",

    webRtcErrorDefault: 'Errore WebRTC',
    webRtcErrorNotFound:
        'Videocamera/microfono non trovati. Verificare che la videocamera o il microfono siano collegati dopodichè cliccare Ricaricare.',
    webRtcErrorNotAllowed:
        "Consentire l'accesso alla microfono / videocamera nelle impostazioni del browser. Attivi successivamente l'audio o l'audio + video.",
    webRtcErrorNotReadable:
        "La sua videocamera è bloccata da un'altra applicazione. Per favore chiuda l'applicazioneen o selezioni un'altra videocamera.",
    webRtcErrorOverconstrained:
        'La sua videocamera salvata / Il suo microfono salvato non sono stati trovati. Verificare che la videocamera o il microfono siano collegati dopodichè cliccare Ricaricare o selezioni un altro dispositivo.',
    webRtcErrorConnectionTimeout:
        'Timeout nel tentativo di connettersi al Meeting server',
    webRtcErrorRecordingStart: 'Impossibile avviare la registrazione.',
    webRtcErrorRecordingStream: 'Non è stato possibile registrare uno stream.',
    webRtcErrorGetRecordings: 'Impossibile recuperare una registrazione.',

    webRtcBrowserNotSupported:
        'Il tuo browser non è supportato. Utilizza una versione recente di Chrome/Firefox o Safari su iOS.',
    webRtcBrowserOutOfDate:
        'La vostra versione di {{browser}} è obsoleta e non è più supportata. Per favore aggiorna il tuo browser.',
    webRtcBrowserNonSafariIos:
        'Sfortunatamente {{browser}} non è supportata su iOS. Per favore utilizzi una versione recente di Safari',
    webRtcBrowserLegacyEdge:
        'State usando Edge Legacy. Questa versione non è aggiornata e non è più supportata. Vi consigliamo di utilizzare la nuova versione Edge che potete scaricare {{link}}.',
    webRtcBrowserIe:
        'Internet Explorer non è supportato. Vi consigliamo di utilizzare la nuova versione di Edge che potete scaricare {{link}}.',
};
