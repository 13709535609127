export const german = {
    // General
    language: 'Sprache',
    de: 'Deutsch',
    en: 'Englisch',
    fr: 'Französich',
    it: 'Italienisch',

    applicationName: 'adiaLive Adviser',

    save: 'Speichern',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    close: 'Schliessen',
    join: 'Teilnehmen',
    edit: 'Bearbeiten',
    refresh: 'Aktualisieren',

    areYouSure: 'Sind Sie sicher?',

    username: 'Benutzername',
    password: 'Passwort',
    serviceId: 'Service-ID',
    captcha: 'Captcha',
    signIn: 'Anmelden',
    signOut: 'Abmelden',

    dashboard: 'Dashboard',
    meetings: 'Meetings',
    phoneConsulting: 'Telefonberatung',
    signatureTemplates: 'Unterschriftsvorlagen',

    reconnectingHeader: 'Verbindung verloren',
    reconnectingMessage:
        'Verbindung zum Server verloren. Verbindung wird wiederhergestellt...',

    pathDoesNotExist: 'Diese Seite existiert nicht!',

    // Account

    account: 'Benutzerkonto',
    nepatecAccount: 'Nepatec Benutzerkonto',
    editAccount: 'Benutzerkonto bearbeiten',
    name: 'Name',
    lastName: 'Nachname',
    firstName: 'Vorname',
    email: 'E-Mail',
    newPassword: 'Neues Passwort',
    newPasswordConfirmation: 'Passwort bestätigen',
    status: 'Status',
    active: 'Aktiv',

    invalidEmail: 'Ungültige E-Mail Adresse',
    invalidPassword: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
    editAccountSuccess: 'Ihr Benutzerkonto wurde aktualisiert.',
    editAccountFailure: 'Ihr Benutzerkonto konnte nicht aktualisiert werden.',
    editPasswordSuccess: 'Ihr Passwort wurde aktualisiert.',
    editPasswordFailure: 'Ihr Password konnte nicht aktualisiert werden.',
    editPasswordCurrentWrong: 'Das aktuelle Passwort ist ungültig.',
    editPasswordDoesNotMatch: 'Die Passwortbestätigung ist nicht identisch.',
    userKeycloakConflict:
        'Ein Benutzer mit diesem Benutzernamen und/oder dieser E-Mail existiert bereits.',
    getNepatecFailure: 'Benutzer konnte nicht abgerufen werden.',

    // Dashboard

    adviserOnline: 'Berater Online',
    ownOpenMeetings: 'Ihre offenen Meetings',

    // Device Settings

    deviceSettings: 'Einstellungen',
    cam: 'Kamera',
    mic: 'Mikrofon',
    resolution: 'Auflösung',
    comModeNone: 'Nur Chat',
    comModeAudio: 'Audio',
    comModeBoth: 'Audio + Video',

    // Meeting

    agenda: 'Agenda',
    showboard: 'Showboard',
    minutes: 'Protokoll',
    documents: 'Dokumente',
    chat: 'Chat',
    show: 'Zeigen',
    editModePermissions: 'Edit Berechtigungen',

    startFullscreen: 'Vollbildmodus starten',
    stopFullscreen: 'Vollbildmodus beenden',
    startScreenShare: 'Bildschirm teilen',
    stopScreenShare: 'Bildschirm teilen beenden',
    leaveMeeting: 'Meeting verlassen',
    muteMic: 'Mikrofon stummschalten',
    unmuteMic: 'Mikrofon lautschalten',
    activateMic: 'Mikrofon aktivieren',
    startCam: 'Kamera einschalten',
    stopCam: 'Kamera ausschalten',
    activateCam: 'Kamera aktivieren',
    meetingInfo: 'Meeting Info',
    sharedApplications: 'Shared Applications',
    menu: 'Menu',

    startRecording: 'Aufnahme starten',
    stopRecording: 'Aufnahme beenden',
    muteParticipant: 'Teilnehmer stummschalten',
    kickParticipant: 'Teilnehmer entfernen',
    msgRemoteMute: 'Sie wurden durch einen Berater stummgeschaltet.',
    msgKick: 'Sie wurden durch einen Berater aus dem Meeting entfernt.',
    participantActions: 'Teilnehmer stummschalten / entfernen',
    noParticipants: 'Keine weiteren Teilnehmer im Meeting',
    mute: 'Stummschalten',
    kick: 'Entfernen',
    msgReallyLeaveMeeting: 'Wollen Sie das Meeting wirklich verlassen?',
    completeMeeting: 'Meeting abschliessen',
    completeMeetingConfirmation:
        'Wollen Sie dieses Meeting wirklich abschliessen?',
    msgMeetingCompleted: 'Das Meeting wurde abgeschlossen.',

    share: 'Teilen',
    standaloneApplications: 'Standalone Applications',
    link: 'Link',

    bitrate: 'Bitrate',
    framerate: 'Framerate',
    packetLoss: 'Packet loss',

    // Meeting Manager Standard

    meetingTableDate: 'Datum',
    meetingTableTitle: 'Titel',
    meetingTableDescription: 'Beschreibung',
    meetingTableParticipants: 'Teilnehmer',

    // Webinars
    webinars: 'Webinare',
    participants: 'Teilnehmer',
    requestedSpeakerPermission: 'Sprecherlaubnis angefragt',
    leaveWebinar: 'Webinar verlassen',

    // Recordings
    recordings: 'Aufnahmen',
    download: 'Herunterladen',
    deleteRecording: 'Aufnahme löschen',
    deleteRecordingConfirmation: 'Wollen Sie diese Aufnahme wirklich löschen?',
    unsavedRecordings:
        'Es stehen neue Aufnahmen zum Download bereit. Bitte herunterladen und aus der Liste löschen.',
    noRecordings: 'Keine Aufnahmen vorhanden',
    recordingsNotice:
        'Wegen Browser-Speicher Limitierungen sollten Sie diese Liste so klein wie möglich halten und Aufnahmen nach dem herunterladen aus der Liste löschen.',
    markedForDelete: 'Wird in {{hours}} Stunden gelöscht',
    markedForDelete1Hour: 'Wird in weniger als einer Stunde gelöscht',
    needsDownload: 'Bitte herunterladen',
    confirmRecordingTitle: 'Bestätigung für Aufzeichnung abwarten',
    confirmRecordingMessage:
        'Die Teilnehmer werden gerade gefragt, ob die Aufzeichnung gestartet werden darf. Bitte warten Sie bis die Bestätigung durch die Teilnehmer erfolgt ist.',
    confirmRecordingResultAsk: 'Teilnehmer wird gefragt',
    confirmRecordingResultRejected: 'Abgelehnt',
    confirmRecordingResultConfirmed: 'Bestätigt',
    restartConfirmation: 'Bestätigung erneut anfordern',

    // adHoc
    adHoc: 'Ad hoc',
    scheduled: 'Geplant',
    meeting: 'Meeting',
    phoneConsultation: 'Telefonberatung',
    meetingId: 'Meeting-ID',
    consultationId: 'Telefonberatungs-ID',
    adHocMeeting: 'Ad hoc Meeting',
    adHocPhoneConsultation: 'Ad hoc Telefonberatung',
    newMeeting: 'Neues Meeting',
    newPhoneConsultation: 'Neue Telefonberatung',

    // Errors

    connectionError: 'Verbindung zum Server ist fehlgeschlagen.',
    serverError: 'Hoppla! Ein Fehler ist aufgetreten.',
    accessDenied: 'Zugriff verweigert!',
    notAuthorized: 'Keine Berechtigung!',
    authInvalidIp: 'Ungültige IP.',
    authInvalidUser: 'Ungültiger Benutzer.',
    authInvalidCaptcha: 'Ungültiges Captcha.',
    authInvalidCredentials: 'Ungültige Logindaten.',
    serviceNotFound: 'Dieser Service existiert nicht.',
    invalidRoleError:
        "Nur User mit der 'Adviser' Rolle können die Adviser Applikation benutzen.",
    autoRecordingError:
        'Die automatische Aufnahme konnte nicht gestartet werden. Ohne laufende Aufnahme dürfen Sie dieses Meeting nicht betreten.',

    alcErrorDefault: 'alc Fehler',
    alcErrorMeetingDoesNotExist:
        'Das Meeting wurde bereits abgeschlossen oder existiert nicht.',
    alcErrorMeetingConnectionLost:
        'Verbindung zum Meeting-Server verloren. Bitte versuchen Sie es erneut.',
    alcErrorMeetingFull:
        'Die maximale Anzahl Teilnehmer im Meeting ist überschritten.',
    alcErrorWebinarAdviserFull:
        'Die maximale Anzahl Moderatoren im Webinar ist überschritten.',
    alcErrorAdviserIdMismatch:
        'Die verwendete Einladung wurde nicht für Ihren Benutzer ausgestellt.',

    webRtcErrorDefault: 'WebRTC Fehler',
    webRtcErrorNotFound:
        'Keine Kamera / kein Mikrofon gefunden. Bitte überprüfen Sie, dass Ihre Kamera / Ihr Mikrofon angeschlossen ist und klicken Sie Aktualisieren.',
    webRtcErrorNotAllowed:
        'Bitte erlauben Sie in den Browsereinstellungen den Zugriff auf Ihr Mikrofon / Ihre Kamera. Aktivieren Sie danach Audio / Audio + Video.',
    webRtcErrorNotReadable:
        'Ihre Kamera wird von einer anderen Applikation verwendet. Bitte schliessen Sie diese Applikation oder wählen Sie eine andere Kamera.',
    webRtcErrorOverconstrained:
        'Ihre gespeicherte Kamera / Ihr gespeichertes Mikrofon konnte nicht gefunden werden. Bitte überprüfen Sie, dass Ihre Kamera / Ihr Mikrofon angeschlossen ist und klicken Sie Aktualisieren oder wählen Sie ein anderes Gerät.',
    webRtcErrorConnectionTimeout:
        'Zeitüberschreitung beim Verbinden zum Meeting Server',
    webRtcErrorRecordingStart: 'Aufnahme konnte nicht gestartet werden.',
    webRtcErrorRecordingStream: 'Ein Stream kann nicht aufgenommen werden.',
    webRtcErrorGetRecordings: 'Es konnten keine Aufnahmen abgerufen werden.',

    webRtcBrowserNotSupported:
        'Ihr Browser wird nicht unterstützt. Bitte verwenden Sie eine aktuelle Version von Chrome oder Firefox.',
    webRtcBrowserOutOfDate:
        'Ihre Version von {{browser}} ist veraltet und wird nicht mehr unterstützt. Bitte aktualisieren Sie Ihren Browser.',
    webRtcBrowserNonSafariIos:
        '{{browser}} wird auf iOS leider nicht untersützt. Bitte verwenden Sie eine aktuelle Version von Safari.',
    webRtcBrowserLegacyEdge:
        'Sie verwenden Edge Legacy. Diese Version von Edge ist veraltet und wird nicht mehr unterstüzt. Wir empfehlen Ihnen den neuen Edge zu verwenden, welchen Sie {{link}} herunterladen können.',
    webRtcBrowserIe:
        'Internet Explorer wird nicht unterstützt. Wir empfehlen Ihnen den neuen Edge zu verwenden, welchen Sie {{link}} herunterladen können.',
};
