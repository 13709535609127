import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Panel } from 'primereact/panel';
import QRCode from 'qrcode.react';
import copy from 'copy-to-clipboard';

import TranslatedString from '../base/i18n/TranslatedString';
import { hideInfoPanel } from './actions';

export class MeetingInfoPanel extends Component {
    render() {
        let standaloneLink = window.location.href.replace(
            'meetings',
            'meetings/standalone'
        );

        let link = '';
        if (this.props.meetingInfo) {
            link = this.props.meetingInfo.link;
        }
        let shortId;
        if (this.props.meetingInfo && this.props.meetingInfo.shortId) {
            shortId = this.props.meetingInfo.shortId;
        }

        return (
            <div className="toolbox-info-panel">
                <Panel>
                    <i
                        className="pi-md-close panel-close-button close-icon clickable"
                        onClick={this.props.hideInfoPanel}
                    />
                    <h2>
                        <TranslatedString id="share" />
                    </h2>
                    <b>
                        <TranslatedString id="link" />:
                    </b>{' '}
                    {link}{' '}
                    <i
                        className="material-icons copy-icon clickable"
                        onClick={() => copy(link)}
                    >
                        content_copy
                    </i>
                    <br />
                    { shortId && (
                        <React.Fragment>
                        <h3>ID: </h3>
                        <h1 style={{letterSpacing: '8px'}}>{shortId}</h1>
                        </React.Fragment>
                    )}
                    {this.props.sharedApplicationsUrl && (
                        <React.Fragment>
                            <hr />
                            <h2>
                                <TranslatedString id="standaloneApplications" />
                            </h2>
                            <b>
                                <TranslatedString id="link" />:
                            </b>{' '}
                            {standaloneLink}{' '}
                            <i
                                className="material-icons copy-icon clickable"
                                onClick={() => copy(standaloneLink)}
                            >
                                content_copy
                            </i>
                            <br />
                            <br />
                            <QRCode value={standaloneLink} size={150} />
                        </React.Fragment>
                    )}
                </Panel>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        meetingInfo: state.meetings.meetingInfo,
        sharedApplicationsUrl:
            state.auth.publicServiceInfo.meetingsSettings.sharedApplicationsUrl
    };
};

const mapDispatchToProps = {
    hideInfoPanel
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingInfoPanel);
